import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import styled from '@emotion/styled'
import * as API from '../../api'
import ReportFilterModal from './ReportFilterModal'
import {PlusCircle, Save, Trash2, Edit2} from 'react-feather'

const FilterContainer = styled.div`
  .filter-section {
    background: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .filter-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;

    h3 {
      font-size: 1.125rem;
      font-weight: 600;
      color: #1f2937;
      margin: 0;
    }
  }

  .filter-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;

    .select-wrapper {
      flex: 1;
      min-width: 250px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #6b7280;
        pointer-events: none;
      }
    }

    select {
      width: 100%;
      padding: 0.75rem 1rem;
      border: 1.5px solid #e5e7eb;
      border-radius: 8px;
      background: #f9fafb;
      color: #374151;
      font-size: 0.9375rem;
      appearance: none;
      transition: all 0.2s;

      &:hover {
        border-color: #d1d5db;
      }

      &:focus {
        border-color: #3b82f6;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
        outline: none;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }

  .btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 1rem;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s;
    border: none;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }

    &.btn-primary {
      background: #3b82f6;
      color: white;

      &:hover {
        background: #2563eb;
      }
    }

    &.btn-danger {
      background: #ef4444;
      color: white;

      &:hover {
        background: #dc2626;
      }
    }

    &.btn-success {
      background: #10b981;
      color: white;

      &:hover {
        background: #059669;
      }
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .empty-state {
    text-align: center;

    p {
      color: #6b7280;
      margin-bottom: 1rem;
    }
  }

  .loading {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    .spinner {
      width: 16px;
      height: 16px;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      border-top-color: white;
      animation: spin 0.8s linear infinite;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

interface Props {
  name: string
  filter: any
  setFilter: any
}

const ReportFilter: FC<Props> = (props: Props) => {
  const [filters, setFilters] = useState<any[]>([])
  const [selectedFilter, setSelectedFilter] = useState<any>('none')
  const [currentFilter, setCurrentFilter] = useState<any>(null)
  const [filterModal, showFilterModal] = useState<boolean>(false)
  const [updateLoading, setUpdateLoading] = useState<boolean>(false)
  const [removeLoading, setRemoveLoading] = useState<boolean>(false)

  const getFilters = async () => {
    if (!props.name) return
    const result = await API.getReportFilters(props.name)
    result.data.map((item: any) => {
      item.data = JSON.parse(item.data)
    })
    setFilters(result.data)
  }

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(() => {
    if (!props.filter) return
    setCurrentFilter(props.filter)
  }, [props])

  const updateFilter = async () => {
    try {
      setUpdateLoading(true)
      const currentFilter = getFilterById(selectedFilter)
      await API.updateReportFilter({
        id_report_filter: currentFilter.id_report_filter,
        filters: props.filter,
      })
      await getFilters()
    } catch (e) {
      console.error(e)
    } finally {
      setUpdateLoading(false)
    }
  }

  const removeFilter = async () => {
    try {
      setRemoveLoading(true)
      const currentFilter = getFilterById(selectedFilter)
      await API.removeReportFilter({
        id_report_filter: currentFilter.id_report_filter,
      })
      await getFilters()
      setSelectedFilter('none')
    } catch (e) {
      console.error(e)
    } finally {
      setRemoveLoading(false)
    }
  }

  const getFilterById = (id: any) => {
    return filters.find((i) => i.id_report_filter == id)
  }

  return (
    <FilterContainer>
      <Modal show={filterModal} onHide={() => showFilterModal(false)} centered size='lg'>
        <ReportFilterModal
          report={props.name}
          filters={currentFilter}
          onClose={(success: boolean) => {
            showFilterModal(false)
            if (success) getFilters()
          }}
        />
      </Modal>

      <div className='filter-section'>
        {filters?.length === 0 ? (
          <div className='empty-state'>
            <p>Nenhum filtro salvo no momento</p>
            <button className='btn btn-success' onClick={() => showFilterModal(true)}>
              <PlusCircle />
              Salvar filtro atual
            </button>
          </div>
        ) : (
          <div className='filter-controls'>
            <div className='select-wrapper'>
              <select
                value={selectedFilter}
                onChange={({target: {value}}) => {
                  setSelectedFilter(value)
                  props.setFilter(getFilterById(value))
                }}
              >
                <option value='none'>Selecione um filtro salvo</option>
                {filters.map((filter, index) => (
                  <option key={filter.id_report_filter} value={filter.id_report_filter}>
                    {filter.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='action-buttons'>
              {selectedFilter !== 'none' && (
                <>
                  <button
                    className='btn btn-primary'
                    onClick={updateFilter}
                    disabled={updateLoading}
                  >
                    {updateLoading ? (
                      <span className='loading'>
                        <span className='spinner' />
                        Atualizando...
                      </span>
                    ) : (
                      <>
                        <Edit2 />
                        Atualizar
                      </>
                    )}
                  </button>

                  <button
                    className='btn btn-danger'
                    onClick={removeFilter}
                    disabled={removeLoading}
                  >
                    {removeLoading ? (
                      <span className='loading'>
                        <span className='spinner' />
                        Removendo...
                      </span>
                    ) : (
                      <>
                        <Trash2 />
                        Remover
                      </>
                    )}
                  </button>
                </>
              )}

              <button className='btn btn-success' onClick={() => showFilterModal(true)}>
                <PlusCircle />
                Novo Filtro
              </button>
            </div>
          </div>
        )}
      </div>
    </FilterContainer>
  )
}

export default ReportFilter
