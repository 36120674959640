import React from 'react'
import styled from '@emotion/styled'

const StyledButton = styled.div`
  &:hover {
    i,
    .svg-icon {
      opacity: 0.3;
    }
  }
`

interface Props {
  onClick?: Function
}

const BtnClose: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton>
      <div onClick={() => props.onClick && props.onClick()}>
        <div className='btn btn-sm btn-icon btn-active-color-primary'>
          <span className='svg-icon svg-icon-1'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='mh-50px'
            >
              <rect
                opacity='0.5'
                x='6'
                y='17.3137'
                width='16'
                height='2'
                rx='1'
                transform='rotate(-45 6 17.3137)'
                fill='currentColor'
              ></rect>
              <rect
                x='7.41422'
                y='6'
                width='16'
                height='2'
                rx='1'
                transform='rotate(45 7.41422 6)'
                fill='currentColor'
              ></rect>
            </svg>
          </span>
        </div>
      </div>
    </StyledButton>
  )
}

export default BtnClose
