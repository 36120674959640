import '../../../../node_modules/react-grid-layout/css/styles.css'
import '../../../../node_modules/react-resizable/css/styles.css'

import React, {FC, useEffect, useState} from 'react'
import styled from '@emotion/styled'
import * as API from '../../api'
import GridLayout from 'react-grid-layout'
import {Modal} from 'react-bootstrap'
import ModalConfirm from './ModalConfirm'
import ManualModal from './ManualModal'
import ViewerModal from '../utils/ViewerModal'
import Chart from './Chart'
import PowerBI from './PowerBI'
import BtnBookmark from '../../components/BtnBookmark'
import BtnHelp from '../../components/BtnHelp'
import BtnFullscreen from '../../components/BtnFullscreen'
import BtnExitFullscreen from '../../components/BtnExitFullscreen'
import BtnBookmarked from '../../components/BtnBookmarked'
import BtnClose from '../../components/BtnClose'

import Loading from '../../components/Loading'
import {isSuperAdmin} from '../../modules/auth'

interface Props {
  edit: boolean
  width: number
  data: any[]
  home: boolean
  onLayoutChange?: (layout: GridLayout.Layout[]) => void
  onDeleteChart?: (guid: string) => void
  onDoubleClickChart?: (guid: string) => void
  requestUpdate?: () => void
  action: any
  setAction: any
  selectedFilter: any
  dashboardId: any
}

const StyledGridLayout = styled(GridLayout)`
  background-color: white;

  .custom-buttons {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .closebutton {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .addbutton {
    position: absolute;
    top: 5px;
    right: 0px;
  }

  .fullscreen-button {
    position: absolute;
    right: 35px;
  }

  .help-button {
    position: absolute;
    right: 70px;
  }
  .missing {
    svg {
      color: #f35050;
    }
  }
`

const DashboardGrid: FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<string[]>()
  const [bookmarkLoading, setBookmarkLoading] = useState<boolean>(false)
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [confirmModal, showConfirmModal] = useState<boolean>(false)
  const [manualModal, showManualModal] = useState<boolean>(false)
  const [viewerModal, showViewerModal] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [currentBookmark, setCurrentBookmark] = useState<any>(null)
  const [currentItem, setCurrentItem] = useState<any>(null)

  function handleFullscreenChange() {
    setIsFullscreen(!!document.fullscreenElement)
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  function onClickDelete(data: any) {
    if (document.fullscreenElement) document.exitFullscreen()
    if (props.home) {
      confirmRemoveBookmark(data)
    } else {
      setChartLoading(data.guid)
      if (props.onDeleteChart) props.onDeleteChart(data.guid)
    }
  }

  function setChartLoading(guid: string) {
    let arr = [guid]
    if (loading) arr.push(...loading)
    setLoading(arr)
  }

  async function removeBookmark(data: any) {
    if (bookmarkLoading) return
    setBookmarkLoading(true)
    if (data.accessToken) {
      await API.removePowerBIFromHome(data.guid, props.dashboardId)
    } else {
      await API.removeChartFromHome(data.guid, props.dashboardId)
    }
    if (props.requestUpdate) props.requestUpdate()
    setBookmarkLoading(false)
  }

  function confirmAddBookmark(data: any) {
    setCurrentBookmark({data, action: 'add'})
    setModalMessage('Deseja adicionar essa visualização nos seus favoritos?')
    showConfirmModal(true)
  }

  function confirmRemoveBookmark(data: any) {
    setCurrentBookmark({data, action: 'remove'})
    setModalMessage('Deseja remover essa visualização nos seus favoritos?')
    showConfirmModal(true)
  }
  async function addBookmark(data: any) {
    if (bookmarkLoading) return
    setBookmarkLoading(true)
    if (data.accessToken) {
      await API.addPowerBIToHome(data.guid, props.dashboardId)
    } else {
      await API.addChartToHome(data.guid, props.dashboardId)
    }
    if (props.requestUpdate) props.requestUpdate()
    setBookmarkLoading(false)
  }

  function toggleFullscreen(element: HTMLElement) {
    if (!document.fullscreenElement) {
      element.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`)
      })
    } else {
      document.exitFullscreen()
    }
  }

  function handleFullscreenClick(guid: string) {
    API.dashboardFullscreenClick({dashboard_guid: props.dashboardId})
    const element = document.querySelector(`[data-guid="${guid}"]`)
    if (element) {
      toggleFullscreen(element as HTMLElement)
    }
  }

  function handleHelpClick(item: any) {
    API.dashboardHelpClick({dashboard_guid: props.dashboardId})
    setCurrentItem(item)
    if (isSuperAdmin()) {
      showManualModal(true)
    } else {
      showViewerModal(true)
    }
  }

  function closeManualModal() {
    showManualModal(false)
  }

  function closeViewerModal() {
    showViewerModal(false)
  }

  function closeModal(response: boolean) {
    if (response && currentBookmark) {
      if (currentBookmark.action === 'add') {
        addBookmark(currentBookmark.data)
      } else {
        removeBookmark(currentBookmark.data)
      }
    }
    setCurrentBookmark(null)
    showConfirmModal(false)
  }

  return (
    <div>
      <Modal
        id='modal_message'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-400px'
        show={confirmModal}
      >
        <ModalConfirm onClose={closeModal} message={modalMessage} />
      </Modal>
      <Modal
        id='manual_modal'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-800px'
        show={manualModal}
      >
        <ManualModal onClose={closeManualModal} item={currentItem} />
      </Modal>
      <Modal
        id='viewer_modal'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
        show={viewerModal}
      >
        {currentItem ? <ViewerModal onClose={closeViewerModal} path={currentItem.manual} /> : <></>}
      </Modal>
      <StyledGridLayout
        className='layout'
        layout={props.data}
        cols={12}
        rowHeight={props.width / 30}
        width={props.width}
        isDraggable={props.edit}
        isResizable={props.edit}
        onLayoutChange={props.onLayoutChange}
      >
        {props.data &&
          props.data.map((el, elIndex) => (
            <div
              data-guid={el.guid}
              key={el.guid}
              onDoubleClick={() => props.onDoubleClickChart && props.onDoubleClickChart(el.guid)}
            >
              {el.accessToken && (
                <PowerBI
                  action={props.action}
                  setAction={props.setAction}
                  selectedFilter={props.selectedFilter}
                  edit={props.edit}
                  home={props.home}
                  data={el}
                />
              )}
              {el.chart && <Chart edit={props.edit} home={props.home} data={el} />}
              {props.home ? (
                isFullscreen ? (
                  <div className='custom-buttons'>
                    <BtnExitFullscreen onClick={() => handleFullscreenClick(el.guid)} />
                  </div>
                ) : (
                  <div className='custom-buttons'>
                    {isSuperAdmin() || el.manual ? (
                      <div className={`help-button ${el.manual ? '' : 'missing'}`}>
                        <BtnHelp onClick={() => handleHelpClick(el)} />
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className='fullscreen-button'>
                      <BtnFullscreen onClick={() => handleFullscreenClick(el.guid)} />
                    </div>
                    <BtnClose onClick={() => onClickDelete(el)} />
                  </div>
                )
              ) : props.edit ? (
                <div className='closebutton'>
                  <BtnClose onClick={() => onClickDelete(el)} />
                </div>
              ) : isFullscreen ? (
                <div className='custom-buttons'>
                  <BtnExitFullscreen onClick={() => handleFullscreenClick(el.guid)} />
                </div>
              ) : (
                <div className='custom-buttons'>
                  {isSuperAdmin() || el.manual ? (
                    <div className={`help-button ${el.manual ? '' : 'missing'}`}>
                      <BtnHelp onClick={() => handleHelpClick(el)} />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className='fullscreen-button'>
                    <BtnFullscreen onClick={() => handleFullscreenClick(el.guid)} />
                  </div>
                  {el.bookmark ? (
                    <BtnBookmarked onClick={() => confirmRemoveBookmark(el)} />
                  ) : (
                    <BtnBookmark onClick={() => confirmAddBookmark(el)} />
                  )}
                </div>
              )}

              {loading && loading.indexOf(el.guid) >= 0 && <Loading backgroundColor='#00000022' />}
            </div>
          ))}
      </StyledGridLayout>
    </div>
  )
}

export default DashboardGrid
