import React from 'react'
import {Check} from 'lucide-react'

interface StepProps {
  title: string
  index: number
  description: string
  isActive: boolean
  isCompleted: boolean
  isLast?: boolean
}

export const Step = ({index, title, description, isActive, isCompleted, isLast}: StepProps) => {
  return (
    <>
      <div className='flex flex-col items-center'>
        <div
          className={`flex h-10 w-10 items-center justify-center rounded-full border-2 ${
            isActive
              ? 'border-blue-600 bg-blue-600 text-white'
              : isCompleted
              ? 'border-green-600 bg-green-600 text-white'
              : 'border-gray-300 bg-white'
          }`}
        >
          {isCompleted ? (
            <Check className='h-6 w-6' />
          ) : (
            <span className={isActive ? 'text-white' : 'text-gray-500'}>{index + 1}</span>
          )}
        </div>
        <div
          className='mt-2 text-center'
          style={{maxWidth: '120px', height: '60px', maxHeight: '54px'}}
        >
          <div className='text-sm font-medium text-gray-900'>{title}</div>
          <div style={{width: '120px', maxWidth: '120px'}}>
            {(isActive || isCompleted) && (
              <div className='text-xs text-gray-500'>{description}</div>
            )}
          </div>
        </div>
      </div>
      {!isLast && (
        <div
          className={`h-0.5 ${isCompleted ? 'bg-green-600' : 'bg-gray-300'}`}
          style={{flex: 1}}
        />
      )}
    </>
  )
}
