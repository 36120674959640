import axios from "axios";
import { ApiResponse } from "../types/ApiResponse";
// import { StoreEntity, StoreSellerCopilotEntity } from "../types/StoreEntity";
import { FeatureFlagManager } from "../data-layer/featureflags";
import { RawStoreInfo } from "../types/RawStoreInfo";
import { RawStoreCopilot } from "../types/RawStoreCopilot";
import { RawStoreSearchResult } from "../types/RawStoreSearchResult";
import { RawVendaAiRecommendation } from "../types/RawVendaAiRecommendation";
// import { mockGetFavorites, mockGetHistory, mockGetPotentialStores, mockGetStore, mockSetFavorite } from "../mocks/mock-copiloto-do-vendedor";
// import { rawStoreInfoToStoreEntity } from "../utils/lojista";

export async function getHistory():Promise<ApiResponse<RawStoreInfo[]>> {
  const res = await axios.get(`/copiloto-vendedor/historico`);
  return res.data;
}

export async function getFavorites():Promise<ApiResponse<RawStoreInfo[]>> {
  const res = await axios.get(`/copiloto-vendedor/favoritos`);
  return res.data;
}

export async function getAvailableStores():Promise<ApiResponse<RawStoreInfo[]>> {
  const res = await axios.get(`/copiloto-vendedor/lojistas`);
  return res.data;
}

export async function getStore(cnpj:string):Promise<ApiResponse<RawStoreCopilot>> {
  const res = await axios.get(`/copiloto-vendedor/loja/${cnpj}`);
  return res.data;
}

export async function setFavorite(storeId:number, favorite:boolean):Promise<boolean> {
  const endpoint = favorite ? '/copiloto-vendedor/adicionar-favorito' : '/copiloto-vendedor/remover-favorito';
  const res = await axios.post(endpoint, { storeId });
  return !!res.data?.success;
}

export async function searchStore(query:string): Promise<ApiResponse<RawStoreSearchResult[]>> {
  // if (isMockingData()) return mockSearchStore(query);
  const endpoint = `/copiloto-vendedor/buscar?q=${query}`;
  const res = await axios.get(endpoint);
  return res.data;
}

export async function getVendaAi(): Promise<ApiResponse<RawVendaAiRecommendation[]>> {
  const endpoint = `/copiloto-vendedor/vendaai`;
  const res = await axios.get(endpoint);
  return res.data;
}