const data: Record<string, boolean> = {}
fetch('/data/featureflags.json')
  .then((response) => {
    if (!response.ok) {
      throw new Error('/data/featureflags.json not found')
    }
    return response.json()
  })
  .catch((e) => {})
  .then((json) => {
    if (typeof json === 'object') {
      for (const key in json) {
        data[key] = !!json[key]
      }
    }
  })

function isEnabled(key: string) {
  return data[key] ?? false
}

export const FeatureFlagManager = {
  isEnabled,
}
