import React from 'react'
import {Step} from './Step'

export type StepData<T = string> = {
  key: T
  title: string
  description: string
}

interface StepperProps {
  currentStep: number
  steps: StepData[]
}

export const Stepper = ({currentStep, steps}: StepperProps) => {
  return (
    <div className='w-full max-w-4xl mx-auto px-4'>
      <div className='flex items-center justify-between'>
        {steps.map((step, index) => (
          <Step
            index={index}
            key={step.key}
            title={step.title}
            description={step.description}
            isActive={currentStep === index}
            isCompleted={currentStep > index}
            isLast={index === steps.length - 1}
          />
        ))}
      </div>
    </div>
  )
}
