import { PageTitle } from "../../../_metronic/layout/core"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RoutePath } from "../../routing/routes";
import { useCallback, useMemo, useState } from "react";
import { InputSearch } from "../../components/InputSearch";
import { getFavorites, getHistory, searchStore, getAvailableStores } from "../../api/copiloto-do-vendedor";
import { Card } from "../../ui/Card";
import { Heading } from "../../ui/Heading";
import PreloaderIcon from "../../components/PreloaderIcon";
import { Text } from "../../ui/Text";
import { Center } from "../../ui/Center";
import { Stack } from "../../ui/Stack";
import { formatCNPJ } from "../../utils/format";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { StoreCard } from "../../components/StoreCard/StoreCard";
import { RawStoreInfo } from "../../types/RawStoreInfo";
import { RawStoreSearchResult } from "../../types/RawStoreSearchResult";
import { Search, ArrowRight, Store as StoreIcon, MapPin, Phone, Mail, Building2, History, Star } from 'lucide-react';

function EmptyState({ icon: Icon, title, description }: { icon: React.ElementType; title: string; description: string }) {
    return (
      <div className="py-6 flex flex-col items-center text-center">
        <div className="p-3 bg-gray-100 rounded-full mb-3">
          <Icon className="h-5 w-5 text-gray-400" />
        </div>
        <h4 className="text-sm font-medium text-gray-900 mb-1">{title}</h4>
        <p className="text-xs text-gray-500">{description}</p>
      </div>
    );
}

export const CopilotoDoVendedorPesquisa = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [results, setResults] = useState<RawStoreSearchResult[]>([]);
    const [stores, setStores] = useState<RawStoreSearchResult[]>([]);
    const [history, setHistory] = useState<RawStoreInfo[]>([]);
    const [favorites, setFavorites] = useState<RawStoreInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [selectedStore, setSelectedStore] = useState<string | null>(null);

    const cnpj = useMemo(() => {
        return searchParams.get('cnpj');
    }, [searchParams]);

    const title = useMemo(() => {
        let _title = `Copiloto do Vendedor > Pesquisa`;
        if (cnpj) {
            _title += ` > ${cnpj}`;
        }
        return _title;
    }, [cnpj]);

    const handleSearch = useCallback((query: string) => {
      if (query.length < 1) {
        setResults([]);
        return;
      }
      const normalizedQuery = query.toLowerCase().trim();
      const filteredStores: RawStoreSearchResult[] = [];
      for (let i = 0; i < stores.length; i++) {
        const store = stores[i];
        if (
          store.NomeFantasia.toLowerCase().includes(normalizedQuery) ||
          store.CNPJLojista.includes(query)
        ) {
          filteredStores.push(store);
          if (filteredStores.length >= 50) {
            break;
          }
        }
      }
      setResults(filteredStores);
    }, [stores]);

    const handleSelect = useCallback((value:RawStoreSearchResult) => {
        const path:RoutePath = '/copiloto-do-vendedor/lojista';
        navigate(`${path}/${value.CNPJLojista}`);
    }, [navigate]);

    const loadData = useCallback(async () => {
        const [historyRes, favoritesRes, availableStores] = await Promise.all([getHistory(), getFavorites(), getAvailableStores()]);
        setFavorites(favoritesRes.data || []);
        setHistory(historyRes.data || []);
        setStores(availableStores.data || []);
        setIsLoading(false);
    }, []);

    useEffectOnce(() => {
        loadData();
    });

    const handleRenderSearchItem = useCallback((item: RawStoreSearchResult) => {
      return (
        <Stack>
          <div className="text-sm font-medium text-gray-900">{item.NomeFantasia}</div>
          <div className="text-xs text-gray-500">{formatCNPJ(item.CNPJLojista)}</div>
        </Stack>
      );
    }, []);

    return <>
        <PageTitle>{title}</PageTitle>
        <Stack>
            <div className="bg-white p-5 rounded-lg shadow-sm border border-gray-100 mb-6">
                <h2 className="text-lg font-semibold text-gray-900 mb-3">Pesquise pelo nome do lojista ou CNPJ</h2>
                <div className="relative">
                    <InputSearch 
                        placeholder="Nome Fantasia ou CNPJ" 
                        onSearch={handleSearch} 
                        results={results} 
                        onSelect={handleSelect} 
                        onRenderResultItem={handleRenderSearchItem}  
                        />
                </div>
            </div>

            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-4">
                <div className="flex items-center space-x-2 mb-3">
                    <ArrowRight className="w-4 h-4 text-blue-500" />
                    <h3 className="text-sm font-medium text-gray-900">Dicas rápidas</h3>
                </div>
                <ul className="space-y-1.5 text-xs text-gray-600">
                    <li className="flex items-center space-x-2">
                        <div className="w-1 h-1 bg-blue-500 rounded-full"></div>
                        <span>Pesquise por nome fantasia ou CNPJ</span>
                    </li>
                        <li className="flex items-center space-x-2">
                        <div className="w-1 h-1 bg-blue-500 rounded-full"></div>
                        <span>Favorite as lojas mais acessadas para encontrá-las rapidamente</span>
                    </li>
                    <li className="flex items-center space-x-2">
                        <div className="w-1 h-1 bg-blue-500 rounded-full"></div>
                        <span>Use o histórico para acessar suas últimas pesquisas</span>
                    </li>
                </ul>
            </div>

            <div className="mb-6">
          <h2 className="text-sm font-medium text-gray-900 mb-3">Pesquisas recentes</h2>
          <div className="bg-white rounded-lg border border-gray-200">
            {history.length > 0 ? (
              <div className="divide-y divide-gray-100">
                {history.map((store) => (
                  <StoreCard
                    key={store.CNPJLojista}
                    store={store}
                    onClick={() => handleSelect(store)}
                  />
                ))}
              </div>
            ) : (
              <EmptyState
                icon={History}
                title="Nenhuma pesquisa recente"
                description="As lojas que você pesquisar aparecerão aqui"
              />
            )}
          </div>
        </div>

        {/* Favorites */}
        <div className="mb-6">
          <h2 className="text-sm font-medium text-gray-900 mb-3">Favoritos</h2>
          <div className="bg-white rounded-lg border border-gray-200">
            {favorites.length > 0 ? (
              <div className="divide-y divide-gray-100">
                {favorites.map((store) => (
                  <StoreCard
                    key={store.CNPJLojista}
                    store={store}
                    isSelected={selectedStore === store.CNPJLojista}
                    onClick={() => handleSelect(store)}
                  />
                ))}
              </div>
            ) : (
              <EmptyState
                icon={Star}
                title="Nenhum favorito"
                description="Favorite as lojas para acessá-las rapidamente"
              />
            )}
          </div>
        </div>

        
            
        </Stack>
    </>
}