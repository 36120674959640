import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {Link, useLocation} from 'react-router-dom'
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {getUserData} from './../../../../app/modules/auth'
import {HeaderUserMenu, HeaderNotificationMenu} from '../../../partials'
import * as API from '../../../../app/api'
import {NotificationType} from '../../../../app/pages/utils/Types'
import GuidelineViewerModal from '../../../../app/pages/utils/GuidelineViewerModal'
import MFAActiveModal from '../../../../app/pages/utils/MFAActiveModal'
import MessageModal from '../../../../app/pages/utils/MessageModal'
import {CompanyFeature} from '../../../../app/types/CompanyFeature'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const [mfaModal, setMFAModal] = useState<boolean>(false)
  const [messageModal, showMessageModal] = useState<boolean>(false)

  const [hasNewNotification, setHasNewNotification] = useState<boolean>(false)
  const [notificationsList, setNotificationsList] = useState<Array<NotificationType>>([])
  const [modalGuidelines, showModalGuidelines] = useState<boolean>(false)
  const [currentGuideline, setCurrentGuideline] = useState<any>(null)
  const [features, setFeatures] = useState<CompanyFeature[]>([])
  const [hasSupport, setHasSupport] = useState(false)

  const location = useLocation()

  const checkForNewNotifications = async () => {
    const response = await API.checkForNewNotifications()

    setHasNewNotification(response.data.find((item: any) => item.action === 'unread') !== undefined)
    setNotificationsList(response.data)
  }

  const getGuidelines = async () => {
    const response = await API.getGuidelines()
    if (!response.data) return
    const userData = getUserData()
    if (!userData.guidelines) {
      setCurrentGuideline(response.data)
      showModalGuidelines(true)
    }
  }

  const getFeatures = async () => {
    const result = await API.getCompanyFeatures()
    const features = result.data || []
    const userData = getUserData()
    setFeatures(features)
    setHasSupport(features.some((feature: any) => feature.name === 'support'))
    setMFAModal(features.some((feature: any) => feature.name === 'mfa') && !userData.mfa_actived)
  }

  const onNotificationClick = async (item: any) => {
    API.markAllNotificationsAsRead()
    setHasNewNotification(false)
  }

  useEffect(() => {
    checkForNewNotifications()
    getGuidelines()
    getFeatures()
  }, [])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <Modal
        id='modal_message'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
        show={modalGuidelines}
      >
        <GuidelineViewerModal
          path={currentGuideline?.src}
          onClose={() => {
            console.log('OK')
          }}
        ></GuidelineViewerModal>
      </Modal>
      <Modal
        id='mfa_modal'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-800px'
        show={mfaModal}
      >
        <MFAActiveModal
          onClose={(value: any) => {
            if (value) showMessageModal(true)
          }}
        />
      </Modal>
      <Modal
        id='modal_message'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={messageModal}
      >
        <MessageModal
          title='MFA Ativado'
          description='Autenticação de dois fatores cadastrado e validado com sucesso!'
          onClose={() => {
            showMessageModal(false)
          }}
        />
      </Modal>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {notificationsList.length ? (
          <>
            <div
              className={clsx(
                'btn btn-icon btn-active-light-primary btn-custom position-relative',
                toolbarButtonHeightClass
              )}
              id='kt_drawer_chat_toggle'
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com012.svg'
                className={toolbarButtonIconSizeClass}
              />
              {hasNewNotification ? (
                <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
              ) : (
                <></>
              )}
            </div>
            <HeaderNotificationMenu
              notificationsList={notificationsList}
              onNotificationClicked={(item: any) => onNotificationClick(item)}
            />
          </>
        ) : (
          <Link
            to={hasSupport ? '/support' : '#'}
            className='menu-link pe-2'
            onClick={(item: any) => {
              onNotificationClick(item)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />
          </Link>
        )}
      </div>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            src={
              getUserData().src
                ? getUserData().src
                : toAbsoluteUrl('/media/icons/duotune/general/gen049.svg')
            }
            className='rounded'
            alt='Imagem de Perfil'
          />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Topbar}
