import {PowerBIEmbed} from 'powerbi-client-react'
import {models, Report, Embed, service, Page} from 'powerbi-client'
import React, {FC} from 'react'
import styled from '@emotion/styled'

const id = '5c1d28ab-bd49-4e41-a346-96e20307b26f'
const embedUrl =
  'https://app.powerbi.com/reportEmbed?reportId=5c1d28ab-bd49-4e41-a346-96e20307b26f&groupId=76d351dd-c7dc-41cc-8223-423c549ee91b&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19'

const accessToken =
  'H4sIAAAAAAAEACWTt66EVgAF_-W1WIJliZZcsGQuOUNHXHLOlv_dT3Y_xdGMzt8_ZnJ3Y5L__PmT17KTIJsrMtRcS55WCRheK2iMB9vSObmphEbWmp0xtckEosrQvGCl983ECPUzLdn4MHhorYoyRrFT31cceAGs4N5jV2qWnx43qC5NCmveOgymcqcq2bmvYFRMe5dQ3ArLAxyeeWBeJBkejT6O2_LKsE5FFpn0oIB5XTjbnbRFdaOcPzv2wBgWaVRt2HVN68TdHWSMrpqHZjPKyYC-v3W2Fm7y8ElXar0zc6SRrV3shvX6tt9OzKjPR2pONL3I3eZH7V7OqB0G5KlgB-WfHltLxRsnYXgxpBPrhA9UwO0FQyA2wHpqbSi169WBdrpwk7iuoDxWL1BhDMvNE8TDvXDsFC4-EJZgqWLrin1Rqq4TOGMgkndA329gEz4uJwuYtj650ZjVZ71LNze231Ju1ImQRbRw7C5sSaoHT59Ezs8w3L581ONrzrLMzPZe54-HTvTJl7fueJ_0IJeySvReX2tewuEJ-WxhPiSCpGfDGb8hHYEegHRNOZTfNI2VfJFVro4jF6q_xdqtSFq0qgDet1bIQv310nJ-yjn16JahJ0ea_8bMunW2oIyB18s9Aech7CiTsEdA7dFbkDHufAVATGX1ey08Mc9b4XxMg7-BwZD6Ls3kGpl--l3k5H06xW715YCW4t5AyxPdW2oHq5XKrNuDWinIVp_2jFDJb-sQ5QN2KO-nVuNM-jI3IndCjzMuSwgiFCCkxZpqg4BETFUfZ53AsD5CxQoIjaZr62DzXkXKu8cmcHSHclVA_ip8ZWQT9_abaR_p7WTMMCSrG_-qJsbXEO7Bppk6kfrGiUT_FK7JdKtz9GL9tNrAOHO83AIjodfej_Oslk8c-KaMkW3FkjikJLzmGpQKXQ6MPjx8VBlRmiCQ__r544dd7mkbQXH_Xi_c6PKjYlHZqOqj3QOVkq1T-RQCiCtvpw-5gOfk0cfuPvsXqht6KBhkI7sUp-GSDkWmoQI0nMXyNGZR39B47Al10sRaBRtEaowLbJZiH9_xExvIMaQ3pQ3XpILEehug2ILhtusCFCyXo33V9tG7p0kPWDG2Um3JsYNjhjZhHsOX9jLjpdmCD65Qvyu9nDZ9eDasnJUkkaPe1WhM091UoxuAJ-9qMEoBqKHTECYX5sHzMUtT-fjKRBGL27-gKplJ7xPme7_SEoQlzvUM9BO02KWUA39h3fV59wYaRzZCvrYq80I8ozfUHI0sfxodPdaZ71aPs4LseGcPjm8lTXCR6UIW89d_mu-pKhbZ_7UckNnqu7RCnSt-cZYluDgH_qec-jsk274Uv1gWvvYiS3JENZrtoNVtf46QeA-kbsO2lZYzIpZv7-u0-YSbRejRFHGesKeHjTqt-cRcNTlisD1Il-Un3XEMxeAKZlaUIc9l3co2vvzAIL-kXiEKEvsmdxLGqcJlEJR1n-mFExX50u7CSHXkSlQPDV3V1OGEIz1dgqNY5lM7niE7IHQHiTTXnWIa9CiMwMdNxtmKwD1mWduFiRuFpfapDSvVK-GGpLqIJjhF6ZCyzaYd6lIu4evNNJLIVPfKh4WpwJJF89jeS1eGX4nGmBmJtg0yaq2HdyLkd5iVmQhGnI0JBGeKPnqgN7uX7b2iyF2aHqtiQKLYJLFzXQ-iX2zzqnkCOn81__MvpPkO624GAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJleHAiOjE2NzE2NTY4OTIsImFsbG93QWNjZXNzT3ZlclB1YmxpY0ludGVybmV0Ijp0cnVlfQ=='

const StyledPowerBITest = styled.div`
  height: 100%;
  overflow: hidden;
`

const PowerBITest: FC = () => {
  return (
    <StyledPowerBITest>
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          id,
          embedUrl,
          accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
              pageNavigation: {
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              function () {
                console.log('Report loaded')
              },
            ],
            [
              'rendered',
              function () {
                console.log('Report rendered')
              },
            ],
            [
              'error',
              function (event) {
                console.log(event?.detail)
              },
            ],
          ])
        }
        cssClassName={'report-style-class'}
        getEmbeddedComponent={(embeddedReport) => {
          if (this === undefined) return
          //this.report = embeddedReport as Report;
        }}
      />
    </StyledPowerBITest>
  )
}

export default PowerBITest
