import { Box } from "./Box"
import { BoxProps } from "./types"

export const Flex = ({children, className, gap = '8px', ...props}:BoxProps) => {
    return <>
        <Box 
            className={['d-flex', className].filter(x => x).join(' ')} 
            gap={gap} 
            {...props}
        >
            {children}
        </Box>
    </>
}