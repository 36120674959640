import React, { useState } from "react";
import { Text } from "../../ui/Text";
import { Flex } from "../../ui/Flex";
import { PlusIcon } from "lucide-react";
import {
  convertToBrazilianDate,
  formatMoney,
  formatCNPJ,
} from "../../utils/format";

type Object = Record<string, any>;

export type TableRenderItemOptions<T extends Object = Object> = {
  item: T;
  key: keyof T;
  index: number;
};

export type TableSchema<T extends Object = Object> = {
  key: keyof T;
  label: string;
  align?: "start" | "center" | "end";
};

export type TableFormat<T extends Object = Object> = {
  key: keyof T;
  type: "money" | "date" | "datetime" | "cnpj" | "string" | "number"; // Adicione outros tipos de formato conforme necessário
};

export type RowStyle<T extends Object = Object> = {
  key: keyof T;
  value: any;
  rowClassName: string; // Classe CSS a ser aplicada à linha
};

type TableProps<T extends Object = Object> = {
  limit?: number;
  schema: TableSchema<T>[];
  data: any[] | undefined;
  align?: string;
  useRenderItem?: boolean;
  onRenderItem?: (options: TableRenderItemOptions<T>) => React.ReactNode;
  formats?: TableFormat<T>[]; // Propriedade formats adicionada
  rowStyles?: RowStyle<T>[]; // Propriedade rowStyles adicionada
};

function isIsoDateString(value: any) {
  const isoDateRegex =
    /^\d{4}-\d{2}-\d{2}(?:[ T]\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?)?$/;
  return isoDateRegex.test(value);
}

export function Table<T extends Object = Object>({
  schema,
  data,
  onRenderItem,
  limit = 10, // Definimos o limite padrão para 10
  align = "left",
  useRenderItem = true,
  formats,
  rowStyles,
}: TableProps<T>) {
  const [itemLimit, setItemLimit] = useState<number>(limit);

  // Função auxiliar para formatar os valores das células com base na prop formats
  function formatCellValue(key: keyof T, value: any): any {
    if (value) {
      let formatType = formats?.find((f) => f.key === key)?.type;
      if (formatType) {
        switch (formatType) {
          case "date":
            return convertToBrazilianDate(value, false);
          case "datetime":
                return convertToBrazilianDate(value, true);
          case "money":
            return formatMoney(value);
          case "cnpj":
            return formatCNPJ(value);
          default:
            return value;
        }
      } else if (isIsoDateString(value)) {
        return convertToBrazilianDate(value);
      } else {
        return value;
      }
    } else {
      return "-";
    }
  }

  // Função auxiliar para obter a classe da linha com base na prop rowStyles
  function getRowClassName(item: T): string {
    if (rowStyles) {
      for (const rowStyle of rowStyles) {
        if (item[rowStyle.key] === rowStyle.value) {
          return rowStyle.rowClassName;
        }
      }
    }
    return "";
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle">
          <thead>
            <tr className="fw-bold text-muted">
              {schema.map(({ key, label }) => (
                <th
                  key={`head-${key as string}`}
                  className={`min-w-100px text-${align}`}
                >
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.slice(0, itemLimit).map((item, line) => {
              const rowClassName = getRowClassName(item);
              return (
                <tr
                  key={`line-${line}`}
                  className={`${rowClassName} text-${align}`}
                >
                  {schema.map(({ key }) => {
                    return (
                      <th
                        key={`line-${line}-item-${key as string}`}
                        className={`min-w-100px text-${align}`}
                      >
                        {useRenderItem ? (
                          onRenderItem ? (
                            onRenderItem({ item, key, index: line })
                          ) : (
                            (item[key] as any).toString()
                          )
                        ) : (
                          formatCellValue(key, item[key])
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {data && data.length > itemLimit && (
          <Flex
            className="text-gray-400 cursor-pointer mt-2"
            onClick={() => setItemLimit(data.length)}
          >
            <PlusIcon size={16} />
            <Text>Mostrar mais</Text>
          </Flex>
        )}
      </div>
    </>
  );
}