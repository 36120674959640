// https://react-dnd.github.io/react-dnd/examples/dustbin/single-target

import styled from '@emotion/styled'
import axios from 'axios'
import React, {useEffect, useRef, useState} from 'react'
import ReactGridLayout from 'react-grid-layout'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'

import DashboardGrid from './DashboardGrid'
import PowerBIFilter from './PowerBIFilter'
import IChart from './IChart'
import CreateChart from './CreateChart'

import {Modal} from 'react-bootstrap'

import * as API from '../../api'
import {getUserData} from '../../modules/auth/core/Auth'
import {isAdmin} from '../../modules/auth/core/Auth'
import {isSuperAdmin} from '../../modules/auth/core/Auth'

interface Props {
  home?: boolean
}

const StyledDashboard = styled.div`
  .space {
    flex-grow: 1;
  }
`

const Dashboard: React.FC<Props> = (props: Props) => {
  const {dashboardId} = useParams()
  const [modal, showModal] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [data, setData] = useState<IChart[]>([])
  const [edit, setEdit] = useState<boolean>(false)

  const [editElement, setEditElement] = useState<IChart | undefined>(undefined)

  const refContainer = useRef<HTMLDivElement>(null)
  const filterContainer = useRef<any>(null)
  const [width, setWidth] = useState<number>(0)
  const [filterAction, setFilterAction] = useState<any>(null)
  const [selectedFilter, setSelectedFilter] = useState<any>(null)

  function getPositionData(positionList: any, id: string): any {
    if (positionList) {
      for (let i = 0; i < positionList.length; i++) {
        if (positionList[i].i === id) {
          return positionList[i]
        }
      }
    }

    return {x: 0, y: Infinity, w: 12, h: 14, i: id, static: false, moved: false}
  }

  async function updateDashboardLayout(layout: any) {
    const response = props.home
      ? await API.updateHomeLayout(layout)
      : await API.updateDashboardLayout(dashboardId as string, layout)
  }

  async function loadDashboard() {
    const res: any = props.home
      ? await API.loadHome()
      : await API.loadDashboard(dashboardId as string)
    if (!res.success) return

    const positionList = JSON.parse(res.data.data)
    const charts = res.data.charts.concat(res.data.powerbi ? res.data.powerbi : [])
    let elements: IChart[] = []

    for (let i = 0; i < charts.length; i++) {
      const chart = charts[i]
      const pos = getPositionData(positionList, chart.guid)
      let obj = {...chart, ...pos}
      elements.push(obj)
    }

    setName(res.data.name)
    setData(elements)
  }

  function onLayoutChange(layout: ReactGridLayout.Layout[]) {
    updateDashboardLayout(layout)
  }

  function onResize() {
    if (refContainer.current) {
      setWidth(refContainer.current.getBoundingClientRect().width)
    }
  }

  async function onDeleteChart(guid: string) {
    const item: any = data.find((i) => {
      return i.guid === guid
    })
    const res: any = item.chart ? await API.removeChart(guid) : await API.removePowerBI(guid)
    if (res.success) {
      loadDashboard()
    }
  }

  useEffect(() => {
    loadDashboard()
  }, [dashboardId])

  useEffect(() => {
    if (!props.home) return
    loadDashboard()
  }, [])

  useEffect(() => {
    onResize()
    setSelectedFilter('none')
  }, [data])

  useEffect(() => {
    onResize()
  }, [])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [refContainer])

  async function onChartCreated() {
    await loadDashboard()
    showModal(false)
  }

  function getEditButtonClassName() {
    if (edit) return `btn btn-sm btn-secondary`
    return `btn btn-sm btn-light btn-light-active-primary`
  }

  async function onChartUpdated() {
    await loadDashboard()
    showModal(false)
  }

  async function onRequestUpdate() {
    await loadDashboard()
    showModal(false)
  }

  function openEditModal(guid: string) {
    if (!edit) return
    const el = data.find((x) => x.guid === guid)
    if (!el) return
    setEditElement(el)
    showModal(true)
  }

  function openNewModal() {
    setEditElement(undefined)
    showModal(true)
  }

  useEffect(() => {}, [filterContainer])
  
  return (
    <StyledDashboard ref={refContainer}>
      <PageTitle breadcrumbs={[]}>{name}</PageTitle>
      {isSuperAdmin() || (props.home && data.length) ? (
        <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-6'>
          <span className='svg-icon svg-icon-2tx svg-icon-primary me-4'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='mh-50px'
            >
              <path
                opacity='0.3'
                d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                fill='currentColor'
              ></path>
              <path
                d='M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z'
                fill='currentColor'
              ></path>
            </svg>
          </span>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <label className='form-check-label' htmlFor='flexSwitchDefault'>
              Modo edição&nbsp;&nbsp;
            </label>
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={edit}
              onChange={(el) => setEdit(el.target.checked)}
              id='flexSwitchDefault'
            />
          </div>
          <div className='space'></div>
          {isSuperAdmin() && !props.home ? (
            <button
              type='button'
              className='btn btn-lg btn-primary'
              disabled={!edit}
              data-kt-stepper-action='previous'
              onClick={openNewModal}
            >
              Adicionar Gráfico
            </button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {props.home ? (
        <></>
      ) : (
        <PowerBIFilter
          action={filterAction}
          setAction={setFilterAction}
          filter={selectedFilter}
          setFilter={setSelectedFilter}
        ></PowerBIFilter>
      )}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={modal}
      >
        {edit && (
          <CreateChart
            chartData={editElement}
            onChartUpdated={onChartUpdated}
            onChartCreated={onChartCreated}
            onClose={() => showModal(false)}
          />
        )}
      </Modal>
      {data.length ? (
        <DashboardGrid
          action={filterAction}
          dashboardId={dashboardId}
          setAction={setFilterAction}
          selectedFilter={selectedFilter}
          edit={edit}
          width={width}
          data={data}
          home={props.home === true}
          requestUpdate={onRequestUpdate}
          onLayoutChange={onLayoutChange}
          onDeleteChart={onDeleteChart}
          onDoubleClickChart={openEditModal}
        />
      ) : props.home ? (
        <div className='card text-center d-flex m-4'>
          <div className='card-body'>
            <div>
              <label className='w-75 m-5 p-5'>
                Opa! Você ainda não tem nenhuma tela personalizada
              </label>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </StyledDashboard>
  )
}

export default Dashboard
