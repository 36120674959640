import { Star } from "lucide-react";
import { useState } from "react";

type FavoriteToggleProps = {
    isFavorite?: boolean;
    onClick?: (value:boolean) => void;
}

export const FavoriteToggle = ({isFavorite = false, onClick, ...props}:FavoriteToggleProps) => {
    
    const [value, setValue] = useState<boolean>(isFavorite);

    const handleClick = () => {
        setValue(!value);
        onClick?.(!value);
    }

    return  <button
        onClick={handleClick}
        className={`p-2 rounded-full transition-colors ${
            value 
            ? 'text-yellow-500 hover:bg-yellow-50' 
            : 'text-gray-400 hover:bg-gray-100'
        }`}
        aria-label={value ? 'Remover dos favoritos' : 'Adicionar aos favoritos'}
    >
        <Star
        className={`h-6 w-6 ${value ? 'fill-current' : ''}`}
        />
  </button>
}