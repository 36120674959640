import React, {FC, useEffect, useRef, useState} from 'react'
import styled from '@emotion/styled'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import BtnClose from './../../components/BtnClose'
import * as API from '../../api'
import {sha256} from 'crypto-hash'
import {getUserData} from './../../modules/auth'

const StyledForm = styled.div`
  .form-item {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
    p {
      width: 150px;
      margin: auto;
      text-align: right;
    }
  }
`

const StyledModal = styled.div`
  background-color: white;

  .card-header {
    align-items: center;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .remove-user-title {
    margin: 20px !important;
  }

  .status-item {
    margin-right: 30px;

    label {
      margin-right: 10px;
    }
  }
`

interface Props {
  companyData?: any
  onCompanyCreated: Function
  onCompanyUpdated: Function
  onClose: Function
}

const UserModal: FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>(props.companyData?.name || '')
  const [maxUsers, setMaxUsers] = useState<number>(props.companyData?.max_users || 10)
  const [externalIds, setExternalIds] = useState<string>(getExternalIds())
  const [companyType, setCompanyType] = useState<string>(props.companyData?.id_type_company || null)
  const [companyTypesData, setCompanyTypesData] = useState<any[]>([])
  const [isActived, setIsActived] = useState<number>(
    props.companyData ? props.companyData.is_actived : 1
  )

  async function onSubmit() {
    setLoading(true)
    try {
      const data = {
        name: name,
        is_actived: isActived,
        id_type_company: companyType,
        id_external_bi: JSON.stringify({id_external: externalIds.replace(/ /gi, '').split(',')}),
        max_users: maxUsers,
        company_guid: props.companyData ? props.companyData.guid : undefined,
      }
      if (props.companyData) {
        const response = await API.updateCompany(data)
        console.log(response)
        setLoading(false)
        props.onCompanyUpdated()
      } else {
        const response = await API.createCompany(data)
        console.log(response)
        setLoading(false)
        props.onCompanyCreated()
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  function getExternalIds() {
    try {
      if (props.companyData.id_external_bi) {
        const json = JSON.parse(props.companyData.id_external_bi)
        return json.id_external.toString()
      }
    } catch (e) {}
    return ''
  }

  function validationStatus() {
    if (name.length < 2) {
      return 'Nome da empresa deve conter no mínimo 2 caracteres'
    } else if (maxUsers < 1) {
      return 'Limite de usuários não pode ser inferior a 1'
    }
    return ''
  }

  async function getCompanyTypes() {
    const response = await API.getCompanyTypes()
    if (!companyType) setCompanyType(response.data[0].id_type_company)
    setCompanyTypesData(response.data)
  }

  useEffect(() => {
    getCompanyTypes()
  }, [])

  return (
    <StyledModal className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3>{props.companyData ? 'Editar Empresa' : 'Adicionar Empresa'}</h3>
        </div>

        <BtnClose onClick={() => props.onClose()} />
      </div>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'></div>

            <div className='flex-grow-1'>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <StyledForm>
                    <div className='form-item'>
                      <p>Nome: </p>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='name'
                        placeholder='Nome'
                        defaultValue={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                      />
                    </div>
                    <div className='form-item'>
                      <p>Limite de Usuários: </p>
                      <input
                        type='number'
                        className='form-control form-control-lg form-control-solid'
                        name='max_users'
                        min={1}
                        placeholder='Número máximo de usuários'
                        defaultValue={maxUsers.toString()}
                        onChange={(e) => setMaxUsers(parseFloat(e.currentTarget.value))}
                      />
                    </div>

                    <div className='form-item'>
                      <p>Status: </p>
                      <div className='form-control d-flex'>
                        <div className='status-item'>
                          <label>Ativo</label>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='status'
                            value='Ativo'
                            checked={isActived == 1}
                            onChange={({target: {value}}) => {
                              console.log(value)
                              setIsActived(value == 'Ativo' ? 1 : 0)
                            }}
                          />
                        </div>
                        <div className='status-item'>
                          <label>Inativo</label>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='status'
                            value='Inativo'
                            checked={isActived == 0}
                            onChange={({target: {value}}) => {
                              console.log(value)
                              setIsActived(value == 'Ativo' ? 1 : 0)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-item'>
                      <p>Tipo: </p>
                      {companyTypesData && companyTypesData.length ? (
                        <select
                          className='form-select form-select-lg form-select-solid'
                          defaultValue={companyType}
                          onChange={({target: {value}}) => {
                            setCompanyType(value)
                          }}
                        >
                          {companyTypesData.map((type: any) => {
                            return (
                              <option key={type.id_type_company} value={type.id_type_company}>
                                {type.name}
                              </option>
                            )
                          })}
                        </select>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className='form-item'>
                      <p>IDs Externos: </p>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='name'
                        placeholder='Separe os IDs por virgula (Opcional)'
                        defaultValue={externalIds}
                        onChange={(e) => setExternalIds(e.currentTarget.value)}
                      />
                    </div>
                  </StyledForm>
                </div>
              </div>
              <div className='text-center'>
                <button
                  id=''
                  className='btn btn-lg btn-primary w-50 mb-5 mt-5'
                  disabled={validationStatus() != ''}
                  onClick={() => {
                    onSubmit()
                  }}
                >
                  {!loading && (
                    <span className='indicator-label'>
                      {props.companyData ? 'Atualizar' : 'Criar empresa'}
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Aguarde por favor...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  )
}

export default UserModal
