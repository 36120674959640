import { useCallback, useMemo, useState } from "react"
import { PageTitle } from "../../../_metronic/layout/core"
import PreloaderIcon from "../../components/PreloaderIcon"
import { Card } from "../../ui/Card"
import { Text } from "../../ui/Text"
import { Heading } from "../../ui/Heading"
import { Stack } from "../../ui/Stack"
import { useEffectOnce } from "../../hooks/useEffectOnce"
import { getVendaAi } from "../../api/copiloto-do-vendedor"
import { RawVendaAiRecommendation } from "../../types/RawVendaAiRecommendation"
import { formatMoney, formatCNPJ, convertToBrazilianDate } from "../../utils/format"
import { Table, TableRenderItemOptions } from "../../components/Table"
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routing/routes";
import {  DollarSign, Store, Eye } from 'lucide-react';

export const CopilotoDoVendedorVendaAI = () => {
    
    const [stores, setStores] = useState<RawVendaAiRecommendation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    const total = useMemo(() => {
        return stores.reduce((total, store) => total + (Number(store.PredicaoSellOut) || 0), 0);
    }, [stores]);

    const loadData = useCallback(async () => {
        const res = await getVendaAi();
        setStores(res.data || []);
        setIsLoading(false);
    }, []);

    const navigateIntoStore = (cnpj:string) => {
        const path:RoutePath = '/copiloto-do-vendedor/lojista';
        navigate(`${path}/${cnpj}`);
    }

    useEffectOnce(() => {
        loadData();
    });

    const handleRenderItem = useCallback(({item, key, index}:TableRenderItemOptions<RawVendaAiRecommendation>) => {
        if (key === 'PredicaoSellOut') {
            return <Text >{formatMoney(item.PredicaoSellOut)}</Text>
        }
        else if (key === 'DataReferencia') {
            return <Text >{convertToBrazilianDate(item.DataReferencia, false)}</Text>
        }
        else if (key === 'IDLojista') {
            return <Text >{item.IDLojista}</Text>
        }
        else if (key === 'CNPJLojista') {
            return <Text >{formatCNPJ(item.CNPJLojista)}</Text>
        }
        else if (key === 'RazaoSocial') {
            return <Text >{item.RazaoSocial}</Text>
        }
        else if (key === 'NomeFantasia') {
            return <div className="flex items-center">
                <Text>{item.NomeFantasia}</Text>
            </div>
        }
        
        else if (key === 'View') {
            return (
                <button onClick={() => { navigateIntoStore(item.CNPJLojista)}} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <Eye className="h-4 w-4 mr-1" />
                  Visualizar
                </button>
              );
        }
        
        return null;
    }, []);
    
    return <>
        <PageTitle>{`Copiloto do vendedor > Venda AI`}</PageTitle>

        <Stack>
            { isLoading ? <>
                <Card>
                    <Stack alignItems="center">
                        <Heading>Procurando por oportunidades</Heading>
                        <PreloaderIcon />
                    </Stack>
                </Card>
            </> : <>
                <Card>
                    <Stack>
                        <div className="bg-white rounded-lg shadow p-6 mb-4">
                            <div className="flex items-center space-x-3">
                                <div className="flex-shrink-0">
                                    <div className="bg-green-100 rounded-full p-3">
                                        <DollarSign className="h-6 w-6 text-green-600" />
                                    </div>
                                </div>
                                <div>
                                    <p className="text-gray-600">
                                        Você está deixando de vender{' '}
                                        <span className="font-semibold text-gray-900">
                                        {formatMoney(total)}
                                        </span>{' '}
                                        no mês.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Table<RawVendaAiRecommendation> schema={[
                            { key: 'CNPJLojista', label: 'CNPJ'},
                            { key: 'RazaoSocial', label: 'Razão Social'},
                            { key: 'NomeFantasia', label: 'Nome Fantasia'},
                            
                            
                            { key: 'PredicaoSellOut', label: 'Potencial de Vendas'},
                            { key: 'DataReferencia', label: 'Data referência'},
                            { key: 'View', label: 'Visualizar'},
                        ]} 
                        data={stores}
                        onRenderItem={handleRenderItem}
                        formats={[
                            {key:'CNPJLojista', type:'cnpj'},
                            {key:'PredicaoSellOut', type:'money'},
                            {key:'DataReferencia', type:'date'},
                        ]}
                        />
                    </Stack>
                </Card>
            </> }
        </Stack>
    </>
}
