import {PageTitle} from '../../../_metronic/layout/core'
import React, {FC, useEffect, useState} from 'react'
import styled from '@emotion/styled'
import * as API from '../../api'
import PermissionsModal from '../generalmenu/PermissionsModal'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'

const StyledData = styled.div`
  .remove-user-title {
    margin: 20px !important;
  }

  .table {
    margin: 20px;
  }

  .card:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }

  .table-row-dashed tbody tr {
    transition: background-color 0.15s ease-out;
  }

  .table-row-dashed tbody tr:hover {
    background-color: #f6f6f6;
  }

  .filter-radio {
    margin-right: 10px;
  }

  .first-col {
    padding-left: 15px !important;
  }

  .filter-group {
    display: flex;
    align-items: center;
  }

  .filter-dropdown-option {
    padding: 5px 10px;
    cursor: pointer;
  }

  .filter-dropdown-option:hover {
    background-color: #f6f6f6;
  }

  .filter-dropdown:hover .filter-dropdown-options {
    display: block;
  }

  .filter-dropdown-selected {
    color: #333;
  }

  .table-container {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 70vh;
    margin: 20px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000; /* Muito alto para garantir prioridade */
  }

  .first-col,
  .first-col-header {
    position: sticky;
    left: 0;
    background-color: white;
  }

  .table-row-dashed tbody tr {
    transition: background-color 0.15s ease-out;
  }

  .table-row-dashed tbody tr:hover {
    background-color: #f6f6f6;
  }

  .filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 1001;
  }

  .filter-radio {
    margin-right: 10px;
  }

  .filter-dropdown {
    position: relative;
  }

  .filter-dropdown-input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
    margin-right: 5px;
  }

  .filter-dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    display: none;
  }

  .filter-dropdown-option {
    padding: 5px 10px;
    cursor: pointer;
  }

  .filter-dropdown-option:hover {
    background-color: #f6f6f6;
  }

  .filter-dropdown:hover .filter-dropdown-options {
    display: block;
  }

  .filter-dropdown-selected {
    color: #333;
  }
`

type Props = {
  categoryId: string
}

const CompanyPermissions: FC<Props> = ({categoryId}) => {
  const [data, setData] = useState<any>(null)
  const [products, setProducts] = useState<any[]>([])
  const [companys, setCompanys] = useState<any[]>([])
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const [selectedFilter, setSelectedFilter] = useState('Todos')
  const [selectedCompanys, setSelectedCompanys] = useState<any[]>([])
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([])

  const [selectCompanys, setSelectCompanys] = useState<any[]>([])
  const [companysData, setCompanysData] = useState<any[]>([])

  useEffect(() => {
    update()
  }, [])

  async function update() {
    const response = await API.getCategoryPermissions(categoryId)
    setData(response.data)
    setCompanys(response.data.company)

    const data: any[] = []
    response.data.companys.map((item: any) => {
      const obj: {[key: string]: any; value: any; label: any} = {
        value: item.guid,
        label: item.name,
      }
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          obj[key] = item[key]
        }
      }
      data.push(obj)
    })
    setSelectCompanys(data)
  }

  async function productClick(id_product: number, id_company: number, checked: boolean) {
    if (checked) {
      await API.addProductCompanyPermission({id_product, id_company})
    } else {
      await API.removeProductCompanyPermission({id_product, id_company})
    }
  }

  async function subProductClick(id_subproduct: number, id_company: number, checked: boolean) {
    if (checked) {
      await API.addSubProductCompanyPermission({id_subproduct, id_company})
    } else {
      await API.removeSubProductCompanyPermission({id_subproduct, id_company})
    }
  }

  async function onPermissionModalRequest(selectAll: boolean) {
    if (selectedItem.id_subproduct) {
      selectAll
        ? await API.selectAllCompanysIntoSubproduct(selectedItem.id_subproduct)
        : await API.unselectAllCompanysIntoSubproduct(selectedItem.id_subproduct)
    } else {
      selectAll
        ? await API.selectAllCompanysIntoProduct(selectedItem.id_product)
        : await API.unselectAllCompanysIntoProduct(selectedItem.id_product)
    }
    await update()
    setShowPermissionsModal(false)
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(event.target.value)
  }

  useEffect(() => {
    const filteredData =
      data?.companys?.filter((company: any) => {
        const filterMatch = selectedFilter === 'Todos' || company.id_type_company == selectedFilter
        const dropdownMatch =
          selectedCompanys.length === 0 || selectedCompanys.find((c) => c.guid == company.guid)
        return filterMatch && dropdownMatch
      }) || []
    setFilteredCompanies(filteredData)

    if (filteredData.length || selectCompanys.length) {
      setCompanysData(filteredData)
    } else {
      if (data) setCompanysData(data.companys)
    }
  }, [selectedFilter, selectedCompanys, data])

  return (
    <StyledData>
      <PageTitle>Permissões</PageTitle>
      <Modal
        id='modal_message'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-400px'
        show={showPermissionsModal}
      >
        <PermissionsModal
          item={selectedItem}
          onChange={(value: boolean) => {
            onPermissionModalRequest(value)
          }}
          onClose={() => {
            setShowPermissionsModal(false)
          }}
        />
      </Modal>
      <div className='table-responsive'>
        <div className='ms-1 w-600px'>
          <div className='filter-group d-flex justify-content-between m-5'>
            <label>Tipo de Empresa:</label>
            <label htmlFor='filter-radio-todos' className='me-5'>
              <input
                type='radio'
                id='filter-radio-todos'
                name='filter-radio'
                value='Todos'
                checked={selectedFilter === 'Todos'}
                onChange={handleFilterChange}
                className='filter-radio'
              />
              Todos
            </label>
            <label htmlFor='filter-radio-industrias' className='me-5'>
              <input
                type='radio'
                id='filter-radio-industrias'
                name='filter-radio'
                value='1'
                checked={selectedFilter === '1'}
                onChange={handleFilterChange}
                className='filter-radio'
              />
              Indústrias
            </label>
            <label htmlFor='filter-radio-distribuidores' className='me-5'>
              <input
                type='radio'
                id='filter-radio-distribuidores'
                name='filter-radio'
                value='2'
                checked={selectedFilter === '2'}
                onChange={handleFilterChange}
                className='filter-radio'
              />
              Distribuidores
            </label>
          </div>
        </div>
        <div className='filter-group  m-5'>
          <div className='form-group mb-4 w-100'>
            <Select
              isMulti
              options={selectCompanys}
              value={selectedCompanys}
              closeMenuOnSelect={false}
              placeholder='Selecionar empresas'
              onChange={(selected: any) => setSelectedCompanys(selected)}
              className='multi-select'
            />
          </div>
        </div>
        <div className='table-wrapper'>
          <div className='table-container'>
            <table className='table table-row-dashed table-row-gray-300 align-middle justify-content-center'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-200px first-col first-col-header'>
                    <span className='btn'>Empresa</span>
                  </th>
                  {data?.menu.products.map((product: any) => {
                    return (
                      <React.Fragment key={`c_p_${product.id_product}`}>
                        <th
                          key={`title_product_${product.id_product}`}
                          className='justify-content-center text-center'
                        >
                          <button
                            className='btn btn-active-light-primary'
                            onClick={() => {
                              setSelectedItem(product)
                              setShowPermissionsModal(true)
                            }}
                          >
                            {product.admin_name ? product.admin_name : product.name}
                          </button>
                        </th>
                        {product.subproducts.map((subproduct: any) => {
                          return (
                            <th
                              key={`title_product_${subproduct.id_subproduct}`}
                              className='justify-content-center text-center'
                            >
                              <button
                                className='btn btn-active-light-primary'
                                onClick={() => {
                                  setSelectedItem(subproduct)
                                  setShowPermissionsModal(true)
                                }}
                              >
                                {subproduct.admin_name ? subproduct.admin_name : subproduct.name}
                              </button>
                            </th>
                          )
                        })}
                      </React.Fragment>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                <>
                  {companysData.map((company: any) => {
                    return (
                      <tr key={`c_${company.guid}`} className='justify-content-center'>
                        <td className='first-col w-200px'>
                          <span className='w-200px text-muted fw-semibold text-muted d-block fs-7 first-col'>
                            {company.name}
                          </span>
                        </td>
                        {data?.menu.products.map((product: any) => {
                          return (
                            <React.Fragment key={`cc_p_${product.id_product}`}>
                              <td
                                key={`pc_${company.guid}_${product.id_product}`}
                                className='justify-content-center align-middle'
                              >
                                <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                                  <input
                                    className='form-check-input widget-9-check '
                                    type='checkbox'
                                    defaultChecked={data.products.find((p: any) => {
                                      return (
                                        product.id_product == p.id_product &&
                                        p.id_company == company.id_company
                                      )
                                    })}
                                    onChange={(evt) => {
                                      productClick(
                                        product.id_product,
                                        company.id_company,
                                        evt.target.checked
                                      )
                                    }}
                                  />
                                </div>
                              </td>
                              {product.subproducts.map((subproduct: any) => {
                                return (
                                  <td
                                    key={`spc_${company.guid}_${subproduct.id_subproduct}`}
                                    className='justify-content-center align-middle'
                                  >
                                    <div className='form-check form-check-sm form-check-custom d-flex justify-content-center text-center'>
                                      <input
                                        className='d-flex form-check-input widget-9-check'
                                        type='checkbox'
                                        defaultChecked={data.subProducts.find((sp: any) => {
                                          return (
                                            subproduct.id_subproduct == sp.id_subproduct &&
                                            sp.id_company == company.id_company
                                          )
                                        })}
                                        onChange={(evt) => {
                                          subProductClick(
                                            subproduct.id_subproduct,
                                            company.id_company,
                                            evt.target.checked
                                          )
                                        }}
                                      />
                                    </div>
                                  </td>
                                )
                              })}
                            </React.Fragment>
                          )
                        })}
                      </tr>
                    )
                  })}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </StyledData>
  )
}

export default CompanyPermissions
