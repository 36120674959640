import { ColorType } from "../types/ColorType";

type BadgeProps = {
    color?: ColorType;
    children: React.ReactNode;
}

export const Badge = ({children, color = 'gray'}:BadgeProps) => {
    return <span className={`px-2 py-1 text-xs font-medium bg-${color}-100 text-${color}-700 rounded-full`}>
    {children}
  </span>
}