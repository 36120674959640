import {useCallback, useEffect, useState} from 'react'
import {MenuItem} from '../types/MenuItem'
import {getCompanyFeatures, getDashboardMenu } from '../api/index'
import {isSuperAdmin} from '../modules/auth'
import {getSellerMenu, getToolsMenu} from '../data-layer/MenuData'

export const useUsermenuData = () => {
  const [menuData, setMenuData] = useState<MenuItem[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const fetchDashboardMenu = useCallback(async () => {
    const response = await getDashboardMenu()
    const menuItems = (response.data || []).filter((x:any) => x.children && x.children.length > 0)
    return menuItems
  }, [])

  const fetchToolsMenu = useCallback(async () => {
    const result = await getCompanyFeatures()
    const features = result.data || [];
    return getToolsMenu(features, isSuperAdmin())
  }, [])

  const fetchSalesMenu = useCallback(async () => {
    return getSellerMenu()
  }, [])

  const fetchData = useCallback(async () => {
    setIsFetching(true)

    const dashboardMenu = await fetchDashboardMenu()
    const toolsMenu = await fetchToolsMenu()
    const salesMenu = await fetchSalesMenu()

    const items = [...dashboardMenu, ...toolsMenu, ...salesMenu]

    setMenuData(items)
    setIsFetching(false)
  }, [fetchDashboardMenu, fetchSalesMenu, fetchToolsMenu])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    menuData,
    isFetching,
  }
}
