/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {sha256} from 'crypto-hash'
import {useNavigate, useParams} from 'react-router-dom'
import {resetPassword} from '../../../api'

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Mínimo de 6 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('O Preenchimento da senha é necessário'),
  confirmPassword: Yup.string()
    .min(6, 'Mínimo de 6 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('O Preenchimento da senha é necessário'),
})

const initialValues = {
  password: '',
  confirmPassword: '',
}

interface Props {}

export const ResetPassword: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const {token, userGuid, email} = useParams()

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const password = await sha256(values.password)
        const confirmPassword = await sha256(values.confirmPassword)
        if (password !== confirmPassword) {
          throw Error('A confirmação de senha deve ser a mesma do campo superior!')
        }

        if (!token) {
          throw Error('É necessária a autorização para realizar essa operação!')
        }

        if (!userGuid) {
          throw Error('É necessário o usuário para realizar essa operação!')
        }

        if (!email) {
          throw Error('É necessário o email para realizar essa operação!')
        }

        setLoading(true)
        resetPassword(token, userGuid, email, password)
          .then((data: any) => {
            setHasErrors(false)
            setTimeout(() => navigate('/auth/login'), 1000)
          })
          .catch((error) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus(error)
          })
      } catch (error: any) {
        setStatus(error.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Alterar senha</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder='Senha'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirmar Senha</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder='Confirmar Senha'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Alterar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde por favor...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Desculpe, ocorreram alguns erros! Por favor tente novamente.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sua senha foi alterada!.</div>
        </div>
      )}
      {/* end::Action */}
    </form>
  )
}
