import React, {FC, useEffect, useRef, useState} from 'react'
import styled from '@emotion/styled'
import BtnClose from './../../components/BtnClose'

const StyledModal = styled.div`
  background-color: white;

  .card-header {
    align-items: center;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`

interface Props {
  title?: string
  description?: string
  onClose: Function
}

const MessageModal: FC<Props> = (props: Props) => {
  return (
    <StyledModal className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3>{props.title}</h3>
        </div>
        <BtnClose onClick={() => props.onClose()} />
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <p>{props.description}</p>
        </div>
      </div>
    </StyledModal>
  )
}

export default MessageModal
