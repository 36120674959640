import { JSObject } from '../../types/JSObject'

interface SearchResultsProps<T> {
  results: T[]
  onSelect: (item: T) => void
  isLoading: boolean
  onRender?: (item: T) => JSX.Element
}

export const SearchResults = <T extends JSObject = JSObject>({
  onRender,
  results,
  onSelect,
  isLoading,
}: SearchResultsProps<T>) => {
  if (results.length === 0 && !isLoading) {
    return (
      <div className='absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
        <div className='px-4 py-2 text-sm text-gray-500'>Nenhum produto encontrado</div>
      </div>
    )
  }

  return (
    <div className='absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
      style={{maxHeight: '260px', overflowY: 'auto'}}
    >
      {results.map((item: T, index: number) => (
        <div key={index}>
          <button key={item.id} className='w-full hover:bg-gray-100' onClick={() => onSelect(item)}>
            <div className='w-full text-left px-4 py-2 flex items-center gap-2'>
              {/* <Package className="h-4 w-4 text-gray-400" />
                  <div>
                  <div className="text-sm font-medium text-gray-900">{item.name}</div>
                  <div className="text-xs text-gray-500">Another description goes here</div>
                  </div> */}
              {onRender ? onRender(item) : item.label || item.name}
            </div>
          </button>
        </div>
      ))}
    </div>
  )
}
