import {useState} from 'react'
import {StepData, Stepper} from '../../../components/Stepper'
import {Produtos} from './Produtos'
import {Estrategia} from './Estrategia'
import {Restultado} from './Resultado'
import {Valores} from './Valores'
import styled from '@emotion/styled'
import {clamp} from '../../../utils/Mathf'
import {ProductEntity} from '../../../types/ProductEntity'

type StepType = 'produtos' | 'estrategia' | 'valores' | 'resultado'

const steps: StepData<StepType>[] = [
  {key: 'produtos', title: 'Produtos', description: 'Selecione os produtos para a campanha'},
  {key: 'estrategia', title: 'Estratégia', description: 'Selecione o tipo de estratégia a aplicar'},
  {key: 'valores', title: 'Valores', description: 'Defina os valores a aplicar'},
  {key: 'resultado', title: 'Resultado', description: 'Veja a sugestão apresentada'},
]

const StyledPage = styled.div`
  min-height: calc(100% - 16px);
`

export const SimuladorCampanha = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [selectedProducts, setSelectedProducts] = useState<ProductEntity[]>([])

  const moveStep = (direction: number) => {
    setCurrentStep((prev) => {
      return clamp(prev + direction, 0, steps.length - 1)
    })
  }

  return (
    <StyledPage className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Simulador de Campanhas</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <Stepper currentStep={currentStep} steps={steps} />
        <div className='mt-6'>
          {steps[currentStep].key === 'produtos' && (
            <Produtos
              onSelectProduct={(p) => setSelectedProducts((prev) => [...prev, p])}
              onRemoveProduct={(p) =>
                setSelectedProducts((prev) => prev.filter((item) => item.id !== p.id))
              }
              selectedProducts={selectedProducts}
            />
          )}
          {steps[currentStep].key === 'estrategia' && <Estrategia />}
          {steps[currentStep].key === 'resultado' && <Restultado />}
          {steps[currentStep].key === 'valores' && <Valores />}
        </div>
      </div>
      <div className='card-footer border-0 d-flex justify-content-end gap-2'>
        <button className='btn' onClick={() => moveStep(-1)}>
          Voltar
        </button>
        <button className='btn btn-primary' onClick={() => moveStep(+1)}>
          Avançar
        </button>
      </div>
    </StyledPage>
  )
}
