import React, {FC, useEffect, useRef, useState} from 'react'
import BtnClose from './../../components/BtnClose'
import * as API from '../../api'

type Props = {
  onChange: Function
  onClose: Function
  item: any
}

const PermissionsModal: FC<Props> = (props) => {
  const [markLoading, setMarkLoading] = useState(false)
  const [unmarkLoading, setUnmarkLoading] = useState(false)

  useEffect(() => {
    setMarkLoading(false)
    setUnmarkLoading(false)
  }, [])

  return (
    <div className=''>
      <div className='px-7 py-5 d-flex justify-content-between'>
        <div className='pt-2 fs-3 text-dark fw-bolder'>{props.item.name}</div>
        <BtnClose onClick={() => props.onClose()} />
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-4'>
          <div className='d-flex justify-content-between'>
            <button
              onClick={() => {
                setMarkLoading(true)
                props.onChange(true)
              }}
              className='btn btn-primary'
            >
              {!markLoading && 'Selecionar Todos'}{' '}
              {markLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              onClick={() => {
                setUnmarkLoading(true)
                props.onChange(false)
              }}
              className='btn btn-danger'
            >
              {!unmarkLoading && 'Desmarcar Todos'}{' '}
              {unmarkLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PermissionsModal
