import { Store as StoreIcon, MapPin, Phone, Mail, Building2 } from 'lucide-react';
import { RawStoreInfo } from '../../types/RawStoreInfo';

interface StoreCardProps {
  store: RawStoreInfo;
  isSelected?: boolean;
  onClick?: () => void;
}

function formatCNPJ(cnpj:string) {
  if (!cnpj) return '';
  cnpj = cnpj.replace(/\D/g, '');
  if (cnpj.length !== 14) {
      return cnpj;
  }
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
}


export function StoreCard({ store, isSelected, onClick }: { store: RawStoreInfo; isSelected?: boolean; onClick?: () => void }) {
  return (
    <div
      onClick={onClick}
      className={`group p-4 rounded-lg border transition-all duration-200 cursor-pointer ${
        isSelected
          ? 'border-blue-500 bg-blue-50 shadow-sm'
          : 'border-gray-200 hover:border-blue-200 hover:bg-white hover:shadow'
      }`}
    >
      <div className="flex items-center gap-3">
        <div className={`p-2.5 rounded-lg transition-colors duration-200 ${
          isSelected 
            ? 'bg-blue-100 text-blue-600'
            : 'bg-gray-100 text-gray-600 group-hover:bg-blue-50 group-hover:text-blue-500'
        }`}>
          <Building2 className="h-5 w-5" />
        </div>
        <div className="flex-1 min-w-0">
          <h3 className="text-base font-semibold text-gray-900 truncate group-hover:text-blue-600 transition-colors duration-200">
            {store.NomeFantasia}
          </h3>
          <div className="mt-1 flex flex-wrap gap-x-4 gap-y-1">
            <p className="text-xs text-gray-600 font-medium flex items-center">
              <StoreIcon className="h-3.5 w-3.5 mr-1 text-gray-400" />
              {formatCNPJ(store.CNPJLojista)}
            </p>
            <div className="flex items-center text-xs text-gray-500">
              <MapPin className="h-3.5 w-3.5 mr-1 text-gray-400" />
              <span className="truncate">{store.Cidade} / {store.Estado}</span>
            </div>
            {store.Email && (
              <div className="flex items-center text-xs text-gray-500">
                <Mail className="h-3.5 w-3.5 mr-1 text-gray-400" />
                <span className="truncate">{store.Email}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}