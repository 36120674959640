import {ProductEntity} from '../../types/ProductEntity'

export const Product = ({name, id, image, category}: ProductEntity) => {
  return (
    <div className='flex gap-6'>
      <div className='w-40 h-40 flex-shrink-0 bg-gray-50 rounded-lg overflow-hidden'>
        <img src={image} alt={name} className='w-full h-full object-cover' />
      </div>
      <div className='flex-1 space-y-3'>
        <div className='inline-flex px-2.5 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700'>
          {id}
        </div>
        <h5 className='text-xl font-medium text-gray-900'>{name}</h5>
        <p className='text-sm text-gray-600'>
          Categoria: <span className='font-medium'>{category}</span>
        </p>
      </div>
    </div>
  )
}
