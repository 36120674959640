import React from 'react'
import styled from '@emotion/styled'

const StyledButton = styled.div``

interface Props {
  onClick?: Function
}

const IconAttachment: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton>
      <div>
        <div className=''>
          <span className='svg-icon svg-icon-1'>
            <svg
              width='800px'
              height='800px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.81826 7.23019C9.81826 7.23019 9.81817 13.5601 9.81826 15.6701C9.81832 16.9891 10.6361 18.3075 12 18.3075C13.3639 18.3075 14.1818 16.9891 14.1818 15.6701C14.1818 13.5601 14.1818 8.28518 14.1818 6.1752C14.1818 4.06522 11.9438 2.87618 9.81826 3.01024C7.85859 3.13384 6.00018 4.06522 6.00018 6.1752C6.00018 8.28518 5.99977 12.5051 6.00018 15.6701C6.00066 19.3378 8.20735 22 11.9998 22C15.7923 22 17.9995 18.835 17.9999 15.6701C18.0002 12.5051 17.9999 7.23019 17.9999 7.23019'
                stroke='#000000'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>
        </div>
      </div>
    </StyledButton>
  )
}

export default IconAttachment
