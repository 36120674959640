/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React, {FC, useEffect, useRef, useState} from 'react'
import styled from '@emotion/styled'
import clsx from 'clsx'
import * as API from '../../api'
import {getUserData} from '../../modules/auth'
import BtnFile from './../../components/BtnFile'
import IconAttachment from './../../components/IconAttachment'

type Props = {
  isDrawer?: boolean
  ticket?: any
}

const CustomStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  overflow-y: scroll;
  height: 100% div.is-disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    flex: 1 1 1px;
    oveflow-y: scroll;
  }
`

const SupportChat: FC<Props> = ({ticket}) => {
  const isDrawer = false
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any[]>([])
  const messagesEndRef: any = useRef(null)
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [messagesCount, setMessagesCount] = useState(0)
  const [files, setFiles] = useState<File[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadId, setUploadId] = useState(null)
  const [counter, setCounter] = useState<number>(0)

  const sendMessage = async () => {
    if (!message.length) return
    await API.addSupportComment({
      ticket_guid: ticket.guid,
      description: message.replace(/\"/gi, '\\"').replace(/\'/gi, "\\'"),
      id_upload: uploadId,
    })
    await getTicketDetails()
    setMessage('')
    setUploadId(null)
    setFiles([])
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files))
      if (e.target.files && e.target.files.length) {
        setIsUploading(true)
        API.uploadFile(e.target.files[0]).then((response) => {
          setUploadId(response.data.id_upload)
          setIsUploading(false)
        })
      }
    }
  }

  const getTicketDetails = async () => {
    if (!ticket) return
    const response = await API.getTicketDetails(ticket.guid)
    if (response.success) {
      setIsOpen(response.data[response.data.length - 1].status.toLowerCase() === 'aberto')
      setMessages(response.data)
      setMessagesCount(response.data.length)
      if (messagesCount !== response.data.length) scrollToBottom()
    }
    setIsLoading(false)
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})
  }

  const getExtension = (path: string) => {
    const parts = path.split('.')
    return parts[parts.length - 1]
  }

  const isImage = (path: string) => {
    const extension = getExtension(path)
    return extension === 'jpg' || extension === 'jpeg' || extension === 'png'
  }

  const isPDF = (path: string) => {
    const extension = getExtension(path)
    return extension === 'pdf'
  }

  const isDoc = (path: string) => {
    const extension = getExtension(path)
    return extension === 'doc' || extension === 'docx'
  }

  useEffect(() => {
    if (messagesCount !== messages.length) {
      setMessagesCount(messages.length)
      scrollToBottom()
    }
  }, [messages])

  useEffect(() => {
    getTicketDetails()
  }, [counter])

  useEffect(() => {
    getTicketDetails()
    const intervalId = setInterval(() => {
      setCounter(Math.random())
    }, 2000)
    const removeInterval = () => {
      clearInterval(intervalId)
    }

    return removeInterval
  }, [ticket])

  return (
    <CustomStyle className='card-body'>
      {isLoading ? (
        <></>
      ) : (
        messages.map((message: any, index) => {
          const direction = message.userId === getUserData().guid ? 'out' : 'in'
          const state = direction === 'in' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${direction}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            direction === 'in' ? 'start' : 'end'
          } mb-10`
          return messages.length ? (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                ref={messagesEndRef}
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${direction === 'in' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {direction === 'in' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        {message.userImg ? (
                          <img alt='Pic' src={message.userImg} />
                        ) : (
                          <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                            {message.userName.charAt(0).toUpperCase()}
                          </span>
                        )}
                      </div>
                      <div className='ms-3'>
                        <label className='fs-5 fw-bolder text-gray-900 me-4'>
                          {message.userName}
                        </label>
                        <span className='text-muted fs-7 mb-1'>{message.chatDate}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>{message.chatDate}</span>
                        <label className='fs-5 fw-bolder text-gray-900 ms-4'>
                          {message.userName}
                        </label>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                        {message.userImg ? (
                          <img alt='Pic' src={message.userImg} />
                        ) : (
                          <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                            {message.userName.charAt(0).toUpperCase()}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {message.filePath ? (
                  <div>
                    <div
                      className={clsx(
                        'p-5 rounded',
                        `bg-light-${state}`,
                        'text-dark fw-bold mw-lg-600px',
                        `text-${direction === 'in' ? 'start' : 'end'}`
                      )}
                      data-kt-element='message-text'
                    >
                      {isImage(message.filePath) ? (
                        <img alt='Imagem' className='mh-500px mw-500px' src={message.filePath} />
                      ) : isPDF(message.filePath) ? (
                        <a href={message.filePath}>
                          <BtnFile />
                        </a>
                      ) : isDoc(message.filePath) ? (
                        <BtnFile onClick={() => window.open(message.filePath)} />
                      ) : (
                        <></>
                      )}
                      <div
                        className='pt-4'
                        data-kt-element='message-text'
                        dangerouslySetInnerHTML={{__html: message.chatMessage}}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={clsx(
                        'p-5 rounded',
                        `bg-light-${state}`,
                        'text-dark fw-bold mw-lg-600px',
                        `text-${direction === 'in' ? 'start' : 'end'}`
                      )}
                      data-kt-element='message-text'
                      dangerouslySetInnerHTML={{__html: message.chatMessage}}
                    ></div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <></>
          )
        })
      )}

      {isLoading ? (
        <></>
      ) : (
        <div className='' id={'kt_chat_messenger_footer'}>
          {isOpen ? (
            <>
              <textarea
                className='form-control form-control-flush pr-4'
                rows={3}
                data-kt-element='input'
                placeholder='Escreva uma mensagem'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={onEnterPress}
              ></textarea>
              <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center me-2'>
                  <label className='btn btn-sm btn-icon btn-active-light-primary me-1'>
                    <IconAttachment />
                    {files.length > 0 && <span className='badge bg-primary'>{files.length}</span>}
                    <input
                      type='file'
                      accept='.doc,.docx,.pdf,.png,.jpg,.jpeg'
                      hidden
                      onChange={handleFileSelect}
                    />
                  </label>
                </div>

                <button
                  className='btn btn-primary'
                  type='button'
                  data-kt-element='send'
                  disabled={isUploading || !message.length}
                  onClick={sendMessage}
                >
                  Enviar
                </button>
              </div>
            </>
          ) : (
            <>
              <label className='text-center w-100 pt-4'>Chamado encerrado</label>
            </>
          )}
        </div>
      )}
    </CustomStyle>
  )
}

export default SupportChat
