import { FC, useState } from 'react';
import styled from '@emotion/styled';
import Companys from './Companys';
import PermissionMatrix from './PermissionMatrix';
import { CompanyFeatureMatrix } from './FeatureMatrix/';
import CompanyConfig from './settings/CompanyConfig';
import { isSuperAdmin } from '../../modules/auth/core/Auth';

const Styled = styled.div`
  .nav-item {
    cursor: pointer;
  }
`

const CompanyTabs: FC = () => {
  const [activeTab, setActiveTab] = useState('companys')

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

    return (
        <Styled>
            <div className="card card-custom gutter-b">
                <div className="card-header card-header-tabs-line">
                    <div className="card-toolbar">
                        <ul className="nav nav-tabs nav-bold nav-tabs-line">
                            <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'companys' ? 'active' : ''}`} onClick={() => handleTabChange('companys')}>
                                    <span className="nav-icon">
                                        <i className="flaticon2-chat-1"></i>
                                    </span>
                                    <span className="nav-text">Empresas</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'menu-permissions' ? 'active' : ''}`} onClick={() => handleTabChange('menu-permissions')}>
                                    <span className="nav-icon">
                                        <i className="flaticon2-drop"></i>
                                    </span>
                                    <span className="nav-text">Permissões</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'features' ? 'active' : ''}`} onClick={() => handleTabChange('features')}>
                                    <span className="nav-icon">
                                        <i className="flaticon2-drop"></i>
                                    </span>
                                    <span className="nav-text">Funcionalidades</span>
                                </a>
                            </li>
                            { isSuperAdmin() ?
                            <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'config' ? 'active' : ''}`} onClick={() => handleTabChange('config')}>
                                    <span className="nav-icon">
                                        <i className="flaticon2-drop"></i>
                                    </span>
                                    <span className="nav-text">Configurações</span>
                                </a>
                            </li> : null }
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <div className="tab-content">
                        <div className={`tab-pane fade show ${activeTab === 'companys' ? 'active' : ''}`} id="companys">
                            {activeTab === 'companys' ? <Companys /> : <></>}
                        </div>
                        <div className={`tab-pane fade show ${activeTab === 'menu-permissions' ? 'active' : ''}`} id="menu-permissions">
                            {activeTab === 'menu-permissions' ? <PermissionMatrix /> : <></>}
                        </div>
                        <div className={`tab-pane fade show ${activeTab === 'features' ? 'active' : ''}`} id="features">
                            {activeTab === 'features' ? <CompanyFeatureMatrix /> : <></>}
                        </div>
                        { isSuperAdmin() ?
                        <div className={`tab-pane fade show ${activeTab === 'config' ? 'active' : ''}`} id="config">
                            {activeTab === 'config' ? <CompanyConfig /> : <></>}
                        </div> : null }
                    </div>
                </div>
            </div>
    </Styled>
  )
}

export default CompanyTabs
