/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from '@emotion/styled'
import {useEffect, useState} from 'react'
import {AddMenuItemProps, AddMenuItemType} from './AddMenuItemModels'

const StyledActionButtons = styled.div`
  display: flex;
  gap: 8px;
`

const StyledRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

const AddMenuItem = ({
  data,
  type,
  typeData,
  onSelectedItem,
  onAddingItem,
  onAddingMode,
  onDeletingItem,
}: AddMenuItemProps) => {
  const [adding, setAdding] = useState(
    typeData?.inputName == 'category' && !data?.length ? true : false
  )
  const [deleting, setDeleting] = useState(false)
  const [newItem, setNewItem] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<any | undefined>(undefined)

  useEffect(() => {
    if (!adding) setNewItem('')
  }, [adding])

  useEffect(() => {
    setDeleting(selectedItem !== undefined)
  }, [selectedItem])

  const showEditingButtons = () => {
    if (adding) {
      return (
        <StyledActionButtons>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              setAdding(false)
              onAddingMode(type, false)
            }}
          >
            X
          </button>

          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={() => {
              if (newItem === undefined || !newItem.length || newItem === '') {
                //TODO exibir erro do input text
                //console.log('Por favor, preencha o campo');
                return
              }

              onAddingItem(type, newItem)
            }}
          >
            Salvar
          </button>
        </StyledActionButtons>
      )
    }

    return (
      <StyledActionButtons>
        <button
          type='button'
          className='btn btn-sm btn-secondary'
          onClick={() => {
            setAdding(true)
            onAddingMode(type, true)
          }}
        >
          +
        </button>
        {!deleting ? (
          <></>
        ) : (
          <button
            type='button'
            className='btn btn-sm btn-danger'
            onClick={() => {
              if (selectedItem !== undefined) {
                onDeletingItem(type, selectedItem)
              }
            }}
          >
            Remover
          </button>
        )}
      </StyledActionButtons>
    )
  }

  const showInputText = () => {
    if (!adding) return <></>

    return (
      <>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name={typeData?.inputName}
          placeholder={typeData?.inputPlaceholder}
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
        />
      </>
    )
  }

  /***
   * Workaround to handle with different properties names
   */
  const getOptionLabel = (element: any) => {
    switch (type) {
      case AddMenuItemType.CATEGORY: {
        return element.section
      }

      default: {
        return element.label
      }
    }
  }

  const showSelector = () => {
    if (adding) return <></>

    if (data !== undefined && data.length) {
      return (
        <>
          <select
            className='form-select form-select-solid form-select-lg'
            onChange={(e: any) => {
              const item = data?.find((value: any) => value.id === e.target.value)
              setSelectedItem(item)
              onSelectedItem(type, item)
            }}
          >
            <option value={undefined}>{typeData?.option}</option>
            {data?.map((element: any) => (
              <option key={element.id} value={element.id}>
                {getOptionLabel(element)}
              </option>
            ))}
          </select>
        </>
      )
    }

    return <></>
  }

  const showAddItemView = () => {
    return (
      <StyledRow>
        <label className='col-lg-2 col-form-label required fw-bold fs-6'>{typeData?.label}</label>
        <div className='col-lg-8 fv-row'>
          {showSelector()}
          {showInputText()}
        </div>
        <div className='col-lg-4 fv-row'>{showEditingButtons()}</div>
      </StyledRow>
    )
  }

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='fv-row mb-10'>{showAddItemView()}</div>
      </div>
    </div>
  )
}

export {AddMenuItem}
