import React, {useEffect, useState, useRef} from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import * as API from '../../api'

interface CropperComponentProps {
  imageFile: File | null
  setCroppedImage: (url: string) => void
  setShowCropper: (value: boolean) => void
  setUploadResponse: (value: any) => void
}

const ImageCropper: React.FC<CropperComponentProps> = ({
  imageFile,
  setCroppedImage,
  setShowCropper,
  setUploadResponse,
}) => {
  const cropperRef = useRef<any>(null)

  function cropImage() {
    if (cropperRef.current) {
      const cropper = cropperRef.current['cropper']
      setImage(
        cropper.getCroppedCanvas({maxWidth: 300, maxHeight: 300}).toDataURL('image/jpeg', 0.8)
      )
      setShowCropper(false)
    }
  }

  async function setImage(base64Image: string) {
    const response = await API.uploadImage({base64Image})
    console.log(response)
    setUploadResponse(response.data)
    setCroppedImage(base64Image)
  }

  return (
    <div>
      <Cropper
        src={URL.createObjectURL(imageFile as any)}
        style={{height: 400, width: '100%'}}
        initialAspectRatio={1}
        aspectRatio={1}
        preview='.img-preview'
        guides={false}
        ref={cropperRef}
      />
      <button onClick={cropImage} className='btn btn-primary mt-3'>
        Confirmar
      </button>
      <button onClick={() => setShowCropper(false)} className='btn btn-danger mt-3'>
        Cancelar
      </button>
    </div>
  )
}

export default ImageCropper
