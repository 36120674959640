import { Container } from "./types"

export const Heading = ({children}:Container) => {
    return <div className="d-flex gap-2">
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{children}</span>
        </h3>
    </div>
}

