import React, {useEffect, useState} from 'react'
import {Plus, Trash2, MoveUp, MoveDown, Edit2, Save, ArrowLeft} from 'lucide-react'
import {Modal} from 'react-bootstrap'
import QuestionTypeSelect from './components/QuestionTypeSelect'
import SurveyQuestion from './components/SurveyQuestion'
import * as API from '../../api'
import {useNavigate, useParams, Link} from 'react-router-dom'

interface NPSCreatorProps {
  npsId: string | null
  onBack: () => void
}

interface Question {
  id_survey_questions: string
  title: string
  type: string
  value: any
  options?: string[]
}

export default function NPSCreator({npsId, onBack}: NPSCreatorProps) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [questions, setQuestions] = useState<Question[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingQuestion, setEditingQuestion] = useState<string | null>(null)
  const [survey, setSurvey] = useState<any>(null)
  const [newQuestion, setNewQuestion] = useState({
    title: '',
    type: 'text',
    options: '',
  })

  const navigate = useNavigate()
  const {id} = useParams()

  useEffect(() => {
    if (!id) return
    getSurvey()
  }, [])

  const getSurvey = async () => {
    const response = await API.getSurvey(id as string)
    const questions = JSON.parse(response.data.questions)
    setSurvey(response.data)
    setTitle(response.data.title)
    setDescription(response.data.description)
    setQuestions(questions.sort((a: any, b: any) => a.order_position - b.order_position))
  }

  const handleAddQuestion = () => {
    console.log('ADD')

    const question: Question = {
      id_survey_questions: editingQuestion ? editingQuestion : Date.now().toString(), // Mantém o ID existente se estiver editando
      title: newQuestion.title,
      type: newQuestion.type,
      value: '',
      options:
        newQuestion.type === 'choice'
          ? newQuestion.options.split(',').map((o) => o.trim())
          : undefined,
    }

    if (editingQuestion) {
      setQuestions(questions.map((q) => (q.id_survey_questions === editingQuestion ? question : q)))
    } else {
      setQuestions([...questions, question])
    }

    setNewQuestion({title: '', type: 'text', options: ''})
    setEditingQuestion(null)
    setIsModalOpen(false)
  }

  const handleEditQuestion = (questionId: string) => {
    const question = questions.find((q) => q.id_survey_questions === questionId)
    if (question) {
      setNewQuestion({
        title: question.title,
        type: question.type,
        options: question.options?.join(', ') || '',
      })
      setEditingQuestion(questionId)
      setIsModalOpen(true)
    }
  }

  const handleDeleteQuestion = (questionId: string) => {
    setQuestions(questions.filter((q) => q.id_survey_questions !== questionId))
  }

  const handleMoveQuestion = (questionId: string, direction: 'up' | 'down') => {
    const index = questions.findIndex((q) => q.id_survey_questions === questionId)
    if (
      (direction === 'up' && index > 0) ||
      (direction === 'down' && index < questions.length - 1)
    ) {
      const newQuestions = [...questions]
      const newIndex = direction === 'up' ? index - 1 : index + 1
      ;[newQuestions[index], newQuestions[newIndex]] = [newQuestions[newIndex], newQuestions[index]]
      setQuestions(newQuestions)
    }
  }

  const handleSave = async () => {
    if (id) {
      await API.updateSurveyData(id, title, description, questions)
    } else {
      await API.createNewSurvey(title, description, questions)
    }

    navigate(`/nps`)
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4'>
      <div className='max-w-6xl mx-auto'>
        <div className='flex items-center justify-between mb-8'>
          <div className='flex items-center gap-4'>
            <Link to={'/nps'}>
              <button
                onClick={onBack}
                className='p-2 hover:bg-white rounded-lg transition-colors'
                title='Voltar'
              >
                <ArrowLeft className='w-6 h-6 text-gray-600' />
              </button>
            </Link>
            <h1 className='text-3xl font-bold text-gray-900'>
              {npsId ? 'Editar Pesquisa NPS' : 'Criar Nova Pesquisa NPS'}
            </h1>
          </div>
          <button
            onClick={handleSave}
            className='flex items-center gap-2 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors shadow-sm'
          >
            <Save className='w-5 h-5' />
            Salvar Pesquisa
          </button>
        </div>
        <div className='bg-white rounded-xl shadow-sm p-6 mb-6'>
          <label className='block text-sm font-medium text-gray-700 mb-2'>Título da Pesquisa</label>
          <input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder='Digite o título da sua pesquisa'
            className='w-full p-3 border border-gray-200 rounded-lg focus:ring-2 
                                focus:ring-blue-500 focus:border-transparent transition-all'
          />
          <label className='block text-sm font-medium text-gray-700 mb-2 mt-5'>
            Descrição da Pesquisa
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Digite a descrição da sua pesquisa'
            className='w-full p-3 border border-gray-200 rounded-lg min-h-80px focus:ring-2 
                                    focus:ring-blue-500 focus:border-transparent transition-all'
            rows={3}
          />
        </div>

        <div className='space-y-6'>
          {questions.map((question, index) => (
            <div key={question.id_survey_questions} className='relative group'>
              <SurveyQuestion
                title={question.title}
                type={question.type as any}
                value={question.value}
                onChange={(value) => {
                  const newQuestions = [...questions]
                  newQuestions[index] = {...question, value}
                  setQuestions(newQuestions)
                }}
                options={question.options}
              />
              <div className='absolute top-4 right-4 flex gap-2 group-hover:opacity-100 transition-opacity'>
                <button
                  onClick={() => handleMoveQuestion(question.id_survey_questions, 'up')}
                  className='p-2 hover:bg-gray-100 rounded-lg'
                  disabled={index === 0}
                >
                  <MoveUp className='w-5 h-5 text-gray-600' />
                </button>
                <button
                  onClick={() => handleMoveQuestion(question.id_survey_questions, 'down')}
                  className='p-2 hover:bg-gray-100 rounded-lg'
                  disabled={index === questions.length - 1}
                >
                  <MoveDown className='w-5 h-5 text-gray-600' />
                </button>
                <button
                  onClick={() => handleEditQuestion(question.id_survey_questions)}
                  className='p-2 hover:bg-blue-100 rounded-lg'
                >
                  <Edit2 className='w-5 h-5 text-blue-600' />
                </button>
                <button
                  onClick={() => handleDeleteQuestion(question.id_survey_questions)}
                  className='p-2 hover:bg-red-100 rounded-lg'
                >
                  <Trash2 className='w-5 h-5 text-red-600' />
                </button>
              </div>
            </div>
          ))}

          <button
            onClick={() => {
              setEditingQuestion(null)
              setNewQuestion({title: '', type: 'text', options: ''})
              setIsModalOpen(true)
            }}
            className='w-full p-6 border-2 border-dashed border-gray-300 rounded-xl
                                    hover:border-blue-300 hover:bg-blue-50 transition-colors
                                    flex items-center justify-center gap-2 text-gray-600 hover:text-blue-600'
          >
            <Plus className='w-5 h-5' />
            Adicionar Pergunta
          </button>
        </div>
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>
              {editingQuestion ? 'Editar Pergunta' : 'Adicionar Nova Pergunta'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-6'>
            <div className='space-y-6'>
              <div>
                <label className='block text-sm font-medium text-gray-700 mb-2'>
                  Título da Pergunta
                </label>
                <input
                  type='text'
                  value={newQuestion.title}
                  onChange={(e) => setNewQuestion({...newQuestion, title: e.target.value})}
                  placeholder='Digite a pergunta'
                  className='w-full p-3 border border-gray-200 rounded-lg focus:ring-2 
                                            focus:ring-blue-500 focus:border-transparent transition-all'
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700 mb-4'>
                  Tipo de Resposta
                </label>
                <QuestionTypeSelect
                  value={newQuestion.type}
                  onChange={(type) => setNewQuestion({...newQuestion, type})}
                />
              </div>

              {newQuestion.type === 'choice' && (
                <div>
                  <label className='block text-sm font-medium text-gray-700 mb-2'>
                    Opções (separadas por vírgula)
                  </label>
                  <input
                    type='text'
                    value={newQuestion.options}
                    onChange={(e) => setNewQuestion({...newQuestion, options: e.target.value})}
                    placeholder='Opção 1, Opção 2, Opção 3'
                    className='w-full p-3 border border-gray-200 rounded-lg focus:ring-2 
                                                focus:ring-blue-500 focus:border-transparent transition-all'
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setIsModalOpen(false)}
              className='px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors'
            >
              Cancelar
            </button>
            <button
              onClick={handleAddQuestion}
              disabled={!newQuestion.title.trim()}
              className='px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700
                                    transition-colors disabled:opacity-50 disabled:cursor-not-allowed'
            >
              {editingQuestion ? 'Salvar Alterações' : 'Adicionar Pergunta'}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}
