import { useCallback, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { FavoriteToggle } from "../../components/FavoriteToggle";
import { Map } from "../../components/Map";
import { Card } from "../../ui/Card";
import { Flex } from "../../ui/Flex";
import { Grid } from "../../ui/Grid";
import { Heading } from "../../ui/Heading";
import { Stack } from "../../ui/Stack";
import { Text } from "../../ui/Text";
import { AlertTriangle, Building, Mail, MapPin, Phone, Tag, TagIcon, TrendingUp, FileText, Briefcase, Target, Layers, DollarSign, Globe } from "lucide-react";
import { getStore, setFavorite } from "../../api/copiloto-do-vendedor";
import { useNavigate, useParams } from "react-router-dom";
import { MapPoint } from "../../types/MapPoint";
import { RoutePath } from "../../routing/routes";
import { RawStoreCopilot } from "../../types/RawStoreCopilot";
import { RawStoreInfo } from "../../types/RawStoreInfo";
import { Table, TableRenderItemOptions } from "../../components/Table";
import { Badge } from "../../ui/Badge";
import { format } from "path";
import { formatMoney, formatCNPJ, convertToBrazilianDate } from "../../utils/format";

// import { Map } from './Map';


function rawStoreInfoToMapPoint(store:RawStoreInfo): MapPoint {
    return {
        guid: store.CNPJLojista.toString(),
        latitude: store.Latitude,
        longitude: store.Longitude,
        name: store.NomeFantasia,
        cnpj: formatCNPJ(store.CNPJLojista)
    }
}



export const CopilotoDoVendedorLojista = () => {

    const [store, setStore] = useState<RawStoreCopilot | undefined>(undefined);
    const navigate = useNavigate();

    const { cnpj } = useParams();

    const storeMapPoint = useMemo((): MapPoint | undefined => {
        if (!store?.info) return undefined;
        return rawStoreInfoToMapPoint(store?.info);
    }, [store?.info]);

    const nerbyMapPoints = useMemo((): MapPoint[] => {
        const res:MapPoint[] = [];
        store?.nearby?.forEach(s => {
            res.push(rawStoreInfoToMapPoint(s));
        });
        return res;
    }, [store]);

    const loadData = useCallback(async (cnpj:string) => {
        setStore(undefined);

        const res = await getStore(cnpj);
        // TODO: show error message
        if (!res.success) {
            return;
        }

        const storeData:any = res.data;
        storeData.churns.forEach((churn:any) => {
            churn.FlagChurnIndustria = churn.FlagChurnIndustria == 'S' ? 'SIM' : 'NÃO';
        })
        setStore(storeData);
    }, [setStore]);

    // On storeId from URL change
    useEffect(() => {
        if (cnpj) loadData(cnpj);
    }, [loadData, cnpj]);

    const handleOnClickFavorite = useCallback((val:boolean) => {
        if (store) setFavorite(store.info.IDLojista, val);
    }, [store]);

    const handleClickMapPopup = useCallback((point:MapPoint) => {
        const path:RoutePath = '/copiloto-do-vendedor/lojista';
        navigate(`${path}/${point.guid}`);
    }, [navigate]);

    // const handleRenderRecomendationsTable = useCallback(({item, key}: TableRenderItemOptions<StoreSellerCopilotEntity['ciclo_de_compra']['produtos'][0]>): React.ReactNode | undefined => {
    //     if (key === 'name') {
    //         return <Stack gap='0'>
    //             <Text>{item.name}</Text>
    //             <Text color='gray' shade='600' size='10px'>{item.codigo}</Text>
    //         </Stack>
    //     }
    //     else if (key === 'receita_potencial') {
    //         return <Text>{new Intl.NumberFormat('pt-BR', {
    //             style: 'currency',
    //             currency: 'BRL'
    //         }).format(item[key])}</Text>
    //     }
    //     else if (key === 'quantidade_media') {
    //         return <Flex>
    //             <ShoppingCart size='16px' />
    //             <Stack gap={'0'}>
    //                 <Text>{item.quantidade_media} un/periodo</Text>
    //                 <Text shade="600">{item.tipo_unidade}</Text>
    //             </Stack>
    //         </Flex>
    //     }
    //     return undefined;
    // }, []);

    const handleRenderItemPromotionTable = useCallback(({item, key}: TableRenderItemOptions<RawStoreCopilot['promos'][0]>): React.ReactNode | undefined => {
        if (key === 'Nome') {
            return <Flex>
                <TagIcon color='blue' size='16px' />
                <Text color='blue'>{item.Nome}</Text>
            </Flex>
        }
        else if (key === 'BeneficioTipo') {
            return <Badge color='blue'>{item.BeneficioTipo}</Badge>
        }
        else if (key === 'BeneficioValor') {
            return <Flex>
                { item.BeneficioTipo == 'PER' ? <TagIcon color='blue' size='16px' /> : <DollarSign color='blue' size='16px' /> }
                <Text color='blue'>{item.BeneficioTipo == 'PER' ? item.BeneficioValor + ' %' : formatMoney(item.BeneficioValor)}</Text>
            </Flex>
        }
        return undefined;
    }, []);


    const handleRenderItemChurnTable = useCallback(({item, key}: TableRenderItemOptions<RawStoreCopilot['churns'][0]>): React.ReactNode | undefined => {
        if (key === 'NomeIndustria') {
            return <Text>{item.NomeIndustria}</Text>
        }
        else if (key === 'FlagChurnIndustria') {
            return <Text>{item.FlagChurnIndustria}</Text>
        }
        else if (key === 'DataUltimaCompraIndustria') {
            return <Text>{item.DataUltimaCompraIndustria}</Text>
        }
        else if (key === 'CicloCompraDiasPorIndustria') {
            return <Text>{item.CicloCompraDiasPorIndustria}</Text>
        }
        return undefined;
    }, []);

    const handleRenderItemFavoriteProductsTable = useCallback(({item, key}: TableRenderItemOptions<RawStoreCopilot['favoriteProducts'][0]>): React.ReactNode | undefined => {
        
        return undefined;
    }, []);

    const handleRenderItemLastPurchaseTable = useCallback(({item, key}: TableRenderItemOptions<RawStoreCopilot['lastPurchase'][0]>): React.ReactNode | undefined => {
        
        return undefined;
    }, []);

    const handleRenderItemVendaAITable = useCallback(({item, key}: TableRenderItemOptions<RawStoreCopilot['vendaai'][0]>): React.ReactNode | undefined => {
        
        return undefined;
    }, []);

    return <>
        <PageTitle>{"Copiloto do Vendedor > Lojista"}</PageTitle>
        <Stack gap="16px" margin="0 0 16px 0">
            { store && (
                <>
                    <Card>
                        <Stack>
                            <Flex justifyContent="space-between">
                                <Heading>{store.info.NomeFantasia}</Heading>
                                <FavoriteToggle isFavorite={!!store.info.is_favorite} onClick={handleOnClickFavorite} />
                            </Flex>
                            <Grid columns="1fr 1fr">
                                <Flex>
                                    <FileText size='16px' />
                                    <Text>Nome Fantasia: {store.info.NomeFantasia}</Text>
                                </Flex>
                                
                                <Flex>
                                    <Briefcase size='16px' />
                                    <Text>Razão Social: {store.info.RazaoSocial}</Text>
                                </Flex>
                                <Flex>
                                    <Building size='16px' />
                                    <Text>CNPJ: {formatCNPJ(store.info.CNPJLojista)}</Text>
                                </Flex>
                                <Flex>
                                    <Mail size='16px' />
                                    <Text>{store.info.Email}</Text>
                                </Flex>

                                <Flex>
                                    <Globe size='16px' />
                                    <Text>Região: {store.info.Regiao}</Text>
                                </Flex>
                                <Flex>
                                    <Layers size='16px' />
                                    <Text>Segmento: {store.info.Segmento}</Text>
                                </Flex>

                                
                                
                                <Flex>
                                    <DollarSign size='16px' />
                                    <Text>Pagamento Predominante: {store.info.FormaPagamentoPredominante}</Text>
                                </Flex>
                                <Flex>
                                    <MapPin size='16px' />
                                    <Text>{store.info.Cidade} / {store.info.Estado}</Text>
                                </Flex>
                                
                            </Grid>
                        </Stack>
                    </Card>
                </>
            )}

            { store && store.vendaai.length ? <Card>
                <Stack>
                    <Flex>
                        <Target size='16px' />
                        <Heading>Recomendação - Venda AI</Heading>
                    </Flex>
                    <Table
                        schema={[
                            { key: 'DataReferencia', label: 'Data da Predição' },
                            { key: 'PredicaoSellOut', label: 'Potencial de Compra desta loja' },
                        ]} 
                        useRenderItem={false}
                        data={store.vendaai}
                        formats={[
                            {key:'DataReferencia', type:'date'},
                            {key:'PredicaoSellOut', type:'money'},
                        ]}
                        onRenderItem={handleRenderItemVendaAITable}
                    />
                </Stack>
            </Card> : null }


            { store && <Card>
                <Stack>
                    <Flex>
                        <TrendingUp size='16px' />
                        <Heading>Oportunidades de Venda</Heading>
                    </Flex>
                    <Table 
                        schema={[
                            {key: 'NomeProduto', label: 'Produto' },
                            {key: 'TotalQuantidade', label: 'Quantidade Comprada' }
                        ]} 
                        data={store.favoriteProducts}
                        onRenderItem={handleRenderItemFavoriteProductsTable}
                        align="center"
                        useRenderItem={false}
                        
                    />
                </Stack>
            </Card> }
            
            { store && <Card>
                <Stack>
                    <Flex>
                        <Tag size='16px' />
                        <Heading>Recomendações de Cupons</Heading>
                    </Flex>
                    { store.promos.length ? 
                    <Table
                        schema={[
                            { key: 'Nome', label: 'Cupom' },
                            { key: 'BeneficioTipo', label: 'Tipo' },
                            { key: 'BeneficioValor', label: 'Valor' },
                        ]} 
                        
                        data={store.promos}
                        onRenderItem={handleRenderItemPromotionTable}
                    /> : 
                    <p>Nenhuma promoção encontrada para esse lojista.</p> }
                </Stack>
            </Card>}

            
            

            { store && store.churns.length ? <Card>
                <Stack>
                    <Flex>
                        <AlertTriangle size='16px' />
                        <Heading>Churn por Indústrias</Heading>
                    </Flex>
                    <Table 
                        schema={[
                            {key: 'NomeIndustria', label: 'Indústria' },
                            {key: 'FlagChurnIndustria', label: 'Churn' },
                            //{key: 'CicloCompraDiasPorIndustria', label: 'Ciclo de compras' },
                            {key: 'QtdePedidoPorIndustria', label: 'Quantidade de Pedidos' },
                            {key: 'TicketMedioPedidoPorIndustria', label: 'Ticket Médio por Pedido' },
                            {key: 'DataUltimaCompraIndustria', label: 'Data Última compra' },
                        ]} 
                        data={store.churns}
                        onRenderItem={handleRenderItemChurnTable}
                        align="center"
                        useRenderItem={false}
                        formats={[
                            {key:'TicketMedioPedidoPorIndustria', type:'money'},
                            {key:'DataUltimaCompraIndustria', type:'datetime'},
                        ]}
                        rowStyles={[
                            {key:'FlagChurnIndustria', value:'NÃO', rowClassName:'bg-green-100'},
                        ]}
                    />
                </Stack>
            </Card> : null }


            { store && <Card>
                <Stack>
                    <Flex>
                        <TrendingUp size='16px' />
                        <Heading>Último pedido realizado - {convertToBrazilianDate(store.lastPurchase[0].DataPedido)}</Heading>
                    </Flex>
                    <Table 
                        schema={[
                            {key: 'NomeProduto', label: 'Produto' },
                            {key: 'Quantidade', label: 'Quantidade' },
                            {key: 'PrecoUnitarioSemDesconto', label: 'Preço Unitário' },
                            {key: 'ValorDescontoUnitario', label: 'Desconto' },
                            {key: 'NomeIndustria', label: 'Indústria' }
                        ]}
                        formats={[
                            {key:'PrecoUnitarioSemDesconto', type:'money'},
                            {key:'ValorDescontoUnitario', type:'money'},
                        ]} 
                        data={store.lastPurchase}
                        onRenderItem={handleRenderItemLastPurchaseTable}
                        align="center"
                        useRenderItem={false}
                        
                    />
                </Stack>
            </Card> }

            <Card>
                <Stack>
                    <Flex>
                        <MapPin size='16px' />
                        <Heading>Lojas Próximas</Heading>
                    </Flex>
                    <Map 
                        width="100%" 
                        height="400px" 
                        main={storeMapPoint} 
                        points={nerbyMapPoints} 
                        onClickPopup={handleClickMapPopup} 
                    />
                </Stack>
            </Card>
        </Stack>
    </>
}
