import { CompanyFeature } from '../types/CompanyFeature'
import { FeatureType } from '../types/Feature'
import { MenuItem } from '../types/MenuItem'
import { FeatureFlagManager } from './featureflags'

type MenuDefinition = Partial<Record<FeatureType, MenuItem>>

const Seller: MenuDefinition = {
  'seller-copilot': {
    label: 'Copiloto do Vendedor',
    isSection: true,
    children: [
      {
        label: 'Pesquisa por Loja',
        to: '/copiloto-do-vendedor/pesquisa',
      },
      {
        label: 'Venda AI',
        to: '/copiloto-do-vendedor/venda-ai',
      },
    ],
  },
}

const Campaigns: MenuDefinition = {
  'campaign-simulator': {
    label: 'Simulador de Campanha',
    to: '/sales/simulador-campanha',
  },
}

const AlertMonitor: MenuDefinition = {
  'alert-monitor': {
    label: 'Monitor de Alertas',
    children: [
      {
        label: 'Lista de alertas',
        to: '/alerts/list',
      },
      {
        label: 'Gerar novo alerta',
        to: '/alerts/new',
      },
    ],
  },
}

const Reports: MenuDefinition = {
  'pedidos-report': {
    label: 'Pedidos',
    to: '/reports/pedidos',
  },
  'promocoes-report': {
    label: 'Promoções',
    to: '/reports/promos',
  },
  'notafiscal-report': {
    label: 'Nota Fiscal',
    to: '/reports/notafiscal',
  },
  'cadastrolojistas-report': {
    label: 'Cadastro Lojistas',
    to: '/reports/cadastro_lojistas',
  },
  'vendedordistribuidor-report': {
    label: 'Cadastro Vendedores',
    to: '/reports/cadastro_vendedores',
  },
  'apuracao-report': {
    label: 'Apuração',
    to: '/reports/apuracao',
  },
  'sellout-report': {
    label: 'Sellout',
    to: '/reports/sellout',
  },
  'historico-preco-estoque-report': {
    label: 'Histórico Preço Estoque',
    to: '/reports/historico_preco_estoque',
  },
  'distribuidor-prioritario-report': {
    label: 'Distribuidor Prioritário',
    to: '/reports/distribuidor_prioritario',
  },
  'ultimo-preco-estoque-report': {
    label: 'Último Preço Estoque',
    to: '/reports/ultimo_preco_estoque',
  },
  'lojistas-report': {
    label: 'Cashback Lojistas',
    to: '/reports/lojistas',
  },
  'cupons-report': {
    label: 'Cashback Cupons',
    to: '/reports/cupons',
  },
  'politica-preco-report': {
    label: 'Política de Preço',
    to: '/reports/politica_preco',
  },
}

/**
 * Retorna itens de menu filtrados pelas features disponíveis.
 * - Se features === '*', retorna tudo sem filtrar;
 * - Se uma feature (chave) não existir em features, é ignorada;
 * - Se o item tiver children, filtra ou não a depender da sua necessidade.
 */
const getItems = (
  features: CompanyFeature[] | '*',
  menuData: MenuDefinition,
): MenuItem[] => {
  // Se for super admin (“todas features”), retorna tudo
  if (features === '*') return Object.values(menuData).filter(Boolean)

  // Cria um Set para buscar rapidamente se o feature existe
  const featureSet:any = new Set(features.map((f) => f.name))

  // Percorre as chaves do menuData para criar a lista final
  return Object.entries(menuData).reduce<MenuItem[]>((acc, [featureName, item]) => {
    if (!item) return acc

    // Se o nome dessa feature não está no Set, ignora
    if (!featureSet.has(featureName)) return acc

    // Se existir .children, você pode filtrar ou não cada sub-item (depende da sua regra).
    // Exemplo: se não há um "sub-feature" específico, podemos simplesmente manter todos os filhos.
    // Mas se você só quiser manter filhos com links válidos, filtra assim:
    if (item.children?.length) {
      const filteredChildren = item.children.filter((child) => !!child.to)
      if (!filteredChildren.length) {
        // Se, depois de filtrar filhos, não sobra nenhum, não insere esse item
        return acc
      }
      // Senão, insere com os filhos filtrados
      acc.push({
        ...item,
        children: filteredChildren,
      })
    } else {
      // Item sem children
      acc.push(item)
    }

    return acc
  }, [])
}

export const getCampaignsMenu = (companyFeatures: CompanyFeature[]): MenuItem[] => {
  const items = getItems(companyFeatures, Campaigns).sort((a, b) =>
    a.label.localeCompare(b.label),
  )
  if (!items.length) return []
  return [
    {
      label: 'Produtos de dados',
      isSection: true,
      hasBullet: false,
      children: items,
    },
  ]
}

export const getSellerMenu = (): MenuItem[] => {
  // Exemplo de como pegar itens; se não há features pra Seller, aqui retorna vazio
  // Depende de como você vai controlar esse menu (você não está passando features).
  const items = getItems([], Seller)
  return items
}

export const getToolsMenu = (
  companyFeatures: CompanyFeature[],
  isSuperAdmin: boolean
): MenuItem[] => {
  const featuresOrAll = isSuperAdmin ? '*' : companyFeatures

  const alertMonitor = getItems(featuresOrAll, AlertMonitor)
  const reports = getItems(featuresOrAll, Reports).sort((a, b) =>
    a.label.localeCompare(b.label)
  )
  const copilot = getItems(featuresOrAll, Seller)

  const superAdminItems: MenuItem[] = isSuperAdmin
    ? [
        {
          label: 'SUPER ADMIN',
          isSection: true,
          hasBullet: false,
          children: [
            {
              label: 'Sistema de Logs',
              to: '/logs',
            },
          ],
        },
      ]
    : []

  // Monta o array principal, com Copiloto junto
  const items: MenuItem[] = [
    ...alertMonitor,
    {
      label: 'Relatórios',
      hasBullet: false,
      children: reports,
    },
    ...copilot,
    ...superAdminItems,
  ].filter((menuItem) => {
    // remove itens com children vazio
    if (menuItem.children && !menuItem.children.length) {
      return false
    }
    return true
  })

  // Se o único item que sobrou for o Copiloto, você decide se quer agrupar ou não.
  // Exemplo: se o array “items” tiver tamanho 1 E esse item for “Copiloto do Vendedor”,
  // não retorna “Ferramentas”. Em vez disso, retorna só o item sozinho.
  if (items.length === 1 && items[0].label === 'Copiloto do Vendedor') {
    return items
  }

  if (!items.length) {
    return []
  }

  return [
    {
      label: 'Ferramentas',
      isSection: true,
      hasBullet: false,
      children: items,
    },
  ]
}
