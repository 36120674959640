import { BoxProps } from "./types"

export const Box = ({
    gap,
    className,
    children,
    width,
    height,
    backgroundColor,
    padding,
    margin,
    borderRadius,
    display,
    flexDirection,
    justifyContent,
    alignItems,
    columns,
    onClick,
    style,
    ...props
}:BoxProps) => {
    return <div className={className} onClick={onClick} style={{
        ...style,
        gridTemplateColumns: columns,
        gap,
        width,
        height,
        backgroundColor,
        padding,
        margin,
        borderRadius,
        display,
        flexDirection,
        justifyContent,
        alignItems,
        ...props
    }}>
        {children}
    </div>
}