import {useState} from 'react'
import {InputSearch} from '../../../components/InputSearch'
import {ProductEntity} from '../../../types/ProductEntity'
import {Product} from '../../../components/Product'

const mockProducts: ProductEntity[] = [
  {
    id: 1,
    guid: '362e5e70-7c4e-4287-a8e1-7cd26f915e87',
    name: 'Smartphone XYZ',
    category: 'Tecnologia',
    image:
      'https://i.guim.co.uk/img/media/6ead18d1b23b6cdaa33f6731c0c417a8f0576552/539_452_3726_2236/master/3726.jpg?width=445&dpr=1&s=none&crop=none',
  },
  {
    id: 2,
    guid: 'a61147c0-8df0-4ddf-899c-74982b9a76fb',
    name: 'Smartwatch ABC',
    category: 'Tecnologia',
    image: 'https://down-br.img.susercontent.com/file/sg-11134201-7rdxf-lxjy55t7yczwc9',
  },
  {
    id: 3,
    guid: 'a5f7e225-710c-485c-9c23-fd9c91e6538b',
    name: 'Tablet 123',
    category: 'Tecnologia',
    image: 'https://tm.ibxk.com.br/2023/09/28/tablet-para-crianca-28161250856152.jpg',
  },
]

type ProdutosProps = {
  selectedProducts: ProductEntity[]
  onSelectProduct: (product: ProductEntity) => void
  onRemoveProduct: (product: ProductEntity) => void
}

export const Produtos = ({selectedProducts, onSelectProduct, onRemoveProduct}: ProdutosProps) => {
  const [searchResults, setSearchResults] = useState<ProductEntity[]>([])

  const handleSearch = async (query: string): Promise<void> => {
    if (query.length < 2) {
      setSearchResults([])
      return
    }

    await new Promise((resolve) => setTimeout(resolve, 100))
    const items = mockProducts.filter((product) =>
      product.name.toLowerCase().includes(query.toLowerCase())
    )

    setSearchResults(items)
  }

  return (
    <div>
      <InputSearch<ProductEntity>
        placeholder='Busque por produtos'
        onSearch={handleSearch}
        results={searchResults}
        onSelect={onSelectProduct}
      />
      <div style={{marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        {selectedProducts.map((product) => (
          <div key={product.id}>
            <Product {...product} />
          </div>
        ))}
      </div>
    </div>
  )
}
