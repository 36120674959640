import styled from '@emotion/styled'
import React, {FC} from 'react'

interface Props {
  backgroundColor?: string
}

const StyledLoading = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Loading: FC<Props> = (props: Props) => {
  return (
    <StyledLoading {...props}>
      <div className='spinner-border text-secondary' role='status'></div>
    </StyledLoading>
  )
}

export default Loading
