import React, {FC, useEffect, useRef, useState} from 'react'
import styled from '@emotion/styled'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import BtnClose from './../../components/BtnClose'
import * as API from '../../api'
import {sha256} from 'crypto-hash'
import {messages} from './../utils/ErrorsText'
import {isSuperAdmin} from '../../modules/auth/core/Auth'
import {getUserData} from '../../modules/auth/core/Auth'
import IconAttachment from './../../components/IconAttachment'

const StyledForm = styled.div`
  .form-item {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
    p {
      width: 150px;
      margin: auto;
      text-align: right;
    }
  }
`

const StyledModal = styled.div`
  background-color: white;

  .card-header {
    align-items: center;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .remove-user-title {
    margin: 20px !important;
  }

  .status-item {
    margin-right: 30px;

    label {
      margin-right: 10px;
    }
  }

  .mailling-item {
    margin-right: 30px;

    label {
      margin-right: 10px;
    }
  }

  .viewer-item {
    margin-right: 30px;

    label {
      margin-right: 10px;
    }
  }

  .cropper {
    padding-top: 10px;
    max-width: 300px;
  }
`

interface Props {
  userData?: any
  onClose: Function
}

const ModalAddTicket: FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [subject, setSubject] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const [status, setStatus] = useState<string>('')
  const [subjects, setSubjects] = useState<any[]>([])

  const [files, setFiles] = useState<File[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadId, setUploadId] = useState(null)

  async function onSubmit() {
    const statusResponse = validationStatus()
    setStatus(statusResponse)
    if (statusResponse !== '') return
    setLoading(true)
    try {
      let companyGuid = isSuperAdmin()
        ? localStorage.getItem('current_company')
        : getUserData().company_guid

      const response = await API.addSupportTicket({
        subject_guid: subject,
        description: description,
        company_guid: companyGuid,
        id_upload: uploadId,
      })
      if (!response.success) {
        setStatus(messages[response.error])
        setLoading(false)
        return
      }
      props.onClose()
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  function validationStatus() {
    console.log(subject, description)
    if (!subject.length) return 'É necessário escolher um Assunto'
    if (description.length < 10) return 'Descrição do chamado deve conter no mínimo 10 caracteres'
    return ''
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files))
      if (e.target.files && e.target.files.length) {
        setIsUploading(true)
        API.uploadFile(e.target.files[0]).then((response) => {
          setUploadId(response.data.id_upload)
          setIsUploading(false)
        })
      }
    }
  }

  useEffect(() => {
    API.listSupportSubject().then((response: any) => {
      setSubjects(response.data)
      setSubject(response.data[0].guid)
    })
  }, [])

  return (
    <StyledModal className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3>Abertura de Chamado</h3>
        </div>

        <BtnClose onClick={() => props.onClose()} />
      </div>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative '></div>

            <div className='flex-grow-1'>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <StyledForm>
                    <div className='form-item'>
                      <p>Assunto: </p>
                      <select
                        className='form-select form-select-lg form-select-solid'
                        onChange={({target: {value}}) => {
                          setSubject(value)
                        }}
                      >
                        {subjects.map((subject: any) => {
                          return (
                            <option key={subject.id_ticket_subject} value={subject.guid}>
                              {subject.description_ticket_subject}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className='form-item'>
                      <p>Descrição: </p>
                      <textarea
                        className='form-control form-control-lg form-control-solid  pe-10 resize-none min-h-25px'
                        rows={8}
                        onChange={(e) => {
                          setDescription(e.currentTarget.value)
                        }}
                      ></textarea>
                    </div>
                    <div className='d-flex flex-stack'>
                      <p></p>
                      <div className='d-flex'>
                        <label className='btn btn-sm btn-icon btn-active-light-primary me-1'>
                          <IconAttachment />
                          {files.length > 0 && (
                            <span className='badge bg-primary'>{files.length}</span>
                          )}
                          <input
                            type='file'
                            accept='.doc,.docx,.pdf,.png,.jpg,.jpeg'
                            hidden
                            onChange={handleFileSelect}
                          />
                        </label>
                      </div>
                    </div>
                  </StyledForm>
                </div>
              </div>
              {status != '' && (
                <div className='text-center'>
                  <div className='alert alert-danger w-100 text-center'>
                    <div className='alert-text font-weight-bold'>{status}</div>
                  </div>
                </div>
              )}

              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary w-50 mb-5 mt-5'
                  disabled={isUploading || !description.length}
                  onClick={() => {
                    onSubmit()
                  }}
                >
                  {!loading && <span className='indicator-label'>Abrir Chamado</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Aguarde por favor...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  )
}

export default ModalAddTicket
