import React from 'react';
import { MapPin, Minus, Plus } from 'lucide-react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useMap } from 'react-leaflet';
import { BoxProps } from '../../ui/types';
import { Box } from '../../ui/Box';
import { MapPoint } from '../../types/MapPoint';
import { Stack } from '../../ui/Stack';

interface MapProps {
  main?: MapPoint;
  points?: MapPoint[];
  onClickPopup?: (point:MapPoint) => void
}

const customIcon = new Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function ZoomControl() {
    const map = useMap();
  
    const handleZoomIn = () => {
      map.zoomIn();
    };
  
    const handleZoomOut = () => {
      map.zoomOut();
    };
  
    return (
      <div className="absolute right-4 top-4 z-[1000] flex flex-col gap-1">
        <button
          onClick={handleZoomIn}
          className="p-2 bg-white rounded-lg shadow-md hover:bg-gray-50 transition-colors"
          aria-label="Zoom in"
        >
          <Plus className="h-5 w-5 text-gray-600" />
        </button>
        <button
          onClick={handleZoomOut}
          className="p-2 bg-white rounded-lg shadow-md hover:bg-gray-50 transition-colors"
          aria-label="Zoom out"
        >
          <Minus className="h-5 w-5 text-gray-600" />
        </button>
      </div>
    );
  }


function getDistance(p1: MapPoint, p2?: MapPoint): number {
    if (!p2 || (p1.latitude === p2.latitude && p1.longitude === p2.longitude)) return 0;

    const lat1 = p1.latitude;
    const lon1 = p1.longitude;
    const lat2 = p2.latitude;
    const lon2 = p2.longitude;

    const R = 6371; // Raio médio da Terra em quilômetros

    // Converter graus para radianos
    const rad = (graus: number) => (graus * Math.PI) / 180;

    const dLat = rad(lat2 - lat1);
    const dLon = rad(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat1)) * Math.cos(rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distância em quilômetros

    // Arredonda para 1 casa decimal
    return parseFloat(distance.toFixed(1));
}


type PointPopupProps = {
  point: MapPoint;
  reference?: MapPoint;
  onClick?: () => void;
}

const PointPopup = ({point, reference, onClick}: PointPopupProps) => {

  const distance = getDistance(point, reference);

  return <Stack gap="0" >
  <p className="font-medium m-1 p-0">{point.name}</p>
  <p className="text-sm text-gray-600 m-0 p-0">{point.cnpj}</p>
  {!!distance && (
    <p className="text-sm text-gray-500 m-0 p-0">
      {distance}km de distância
    </p>
  )}
  { distance ? 
  <div className="flex justify-center items-center w-full">
    <button
      onClick={onClick}
      className="p-2 bg-primary rounded-lg shadow-md text-white hover:bg-blue-200 w-100 mt-2 transition-colors"
      aria-label="Zoom out"
    >
      Visualizar
    </button>
  </div> : null }
</Stack>
}

export function Map({ main, points = [], onClickPopup, ...boxProps }: MapProps & BoxProps) {
  return (
    <Box {...boxProps}>
      { !main ? <Box className='bg-gray-200 animate-pulse' width="100%" height="100%" /> : (
          <MapContainer
            center={[main.latitude, main.longitude]}
            zoom={18}
            className="h-full w-full"
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
              className="map-tiles"
            />
            
            {/* Custom Zoom Control */}
            <ZoomControl />
            
            {/* Current store marker */}
            <Marker 
              position={[main.latitude, main.longitude]} 
              icon={customIcon}
            >
              <Popup className="custom-popup">
                <PointPopup point={main} />
              </Popup>
            </Marker>

            {/* Nearby stores markers */}
            {points.map((point, index) => (
              <Marker
                key={index}
                position={[point.latitude, point.longitude]}
                icon={customIcon}
              >
                <Popup className="custom-popup">
                <PointPopup point={point} reference={main} onClick={() => onClickPopup?.(point)} />
                </Popup>
              </Marker>
            ))}
          </MapContainer> 
      )}
    </Box>
  )
}