export function formatMoney(value:number): string {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value)
}

export function formatCNPJ(cnpj:string) {
    if (!cnpj) return '';
    cnpj = cnpj.replace(/\D/g, '');
    if (cnpj.length !== 14) {
        return cnpj;
    }
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
}

export function convertToBrazilianDate(isoDate: any, time = true) {
    if (typeof isoDate !== 'string') {
      return null
    }
    const [datePart, timePartWithExtras] =
      isoDate.split('T').length > 1 ? isoDate.split('T') : isoDate.split(' ')
    const [year, month, day] = datePart.split('-')
    const timePart = timePartWithExtras
      ? timePartWithExtras.split('.')[0].split('Z')[0]
      : '00:00:00'
    const [hour, minute, second] = timePart.split(':')
    const dateStr = `${day}/${month}/${year}`;
    const timeStr = `${hour}:${minute}`;
    return time ? `${dateStr} ${timeStr}` : dateStr;
  }