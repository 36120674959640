import {FC, useState, useEffect} from 'react'
import BtnClose from '../../components/BtnClose'
import styled from '@emotion/styled'
import * as API from '../../api'
import Select from 'react-select'
import ReportFilter from './ReportFilter'
import {useDistributors, useIndustries} from '../../modules/auth'

const Style = styled.div`
  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select {
    width: 100%;
    margin-top: 1rem;
  }

  .mailling-item {
    margin-right: 30px;

    label {
      margin-right: 10px;
    }
  }
`

type Props = {
  onChange: Function
  onClose: Function
  data: any
  type: string
}

const LojistasFilterModal: FC<Props> = (props) => {
  const distributors = useDistributors()
  const industries = useIndustries()

  const [loading, setLoading] = useState(false)

  const [selectedDistributors, setSelectedDistributors] = useState<any[]>([])
  const [selectedIndustries, setSelectedIndustries] = useState<any[]>([])

  const [status, setStatus] = useState<string>('')
  const [cnpj, setCNPJ] = useState<string>('')
  const [politicaPreco, setPoliticaPreco] = useState<string>('')

  const [isBlockList, setIsBlockList] = useState<string>('')

  const [filter, setFilter] = useState<any>(null)

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    if (filter || props.data) {
      const data = filter?.data || props.data
      if (data.status) setStatus(data.status)
      if (data.distributors) setSelectedDistributors(data.distributors)
      if (data.industries) setSelectedIndustries(data.industries)
      if (data.cnpj) setCNPJ(data.cnpj)
      if (data.politicaPreco) setPoliticaPreco(data.politicaPreco)
      if (data.isBlockList) setIsBlockList(data.isBlockList)
    }
  }, [props.data, filter])

  function getData() {
    return {
      status,
      cnpj,
      distributors: selectedDistributors,
      industries: selectedIndustries,
      politicaPreco,
      isBlockList,
    }
  }

  return (
    <Style>
      <div className='modal-container'>
        <div className='px-7 py-5 d-flex justify-content-between'>
          <div className='pt-2 fs-3 text-dark fw-bolder'>Filtros para o relatório</div>
          <BtnClose onClick={() => props.onClose()} />
        </div>
        <ReportFilter name={props.type} filter={getData()} setFilter={setFilter}></ReportFilter>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-4 d-flex flex-wrap'>
            <div className=' justify-content-between modal-container'>
              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Status:</label>
                <select
                  disabled={loading}
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder=''
                  data-allow-clear='true'
                  value={status}
                  onChange={(evt: any) => setStatus(evt.target.value)}
                >
                  <option value=''>Todos</option>
                  <option value='PREAPV'>Pré-Aprovado</option>
                  <option value='APV'>Aprovado</option>
                  <option value='RPV'>Reprovado</option>
                </select>
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>CNPJ:</label>
                <input
                  className='form-input form-control'
                  type='text'
                  disabled={loading}
                  defaultValue={cnpj}
                  onChange={(e) => {
                    setCNPJ(e.target.value)
                  }}
                ></input>
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Distribuidor:</label>
                <Select
                  isMulti
                  isDisabled={loading}
                  options={distributors}
                  value={selectedDistributors}
                  placeholder={'Filtrar por Distribuidor'}
                  closeMenuOnSelect={false}
                  onChange={(selected: any) => setSelectedDistributors(selected)}
                  className='multi-select'
                />
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Indústria:</label>
                <Select
                  isMulti
                  isDisabled={loading}
                  options={industries}
                  value={selectedIndustries}
                  placeholder={'Filtrar por Indústria'}
                  closeMenuOnSelect={false}
                  onChange={(selected: any) => setSelectedIndustries(selected)}
                  className='multi-select'
                />
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Politica Preço:</label>
                <input
                  className='form-input form-control'
                  type='text'
                  disabled={loading}
                  defaultValue={politicaPreco}
                  onChange={(e) => {
                    setPoliticaPreco(e.target.value)
                  }}
                ></input>
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Blocklist:</label>
                <div className='form-control d-flex'>
                  <div className='mailling-item'>
                    <label>Todos</label>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='blocklist'
                      value=''
                      checked={isBlockList === ''}
                      onChange={({target: {value}}) => {
                        setIsBlockList(value)
                      }}
                    />
                  </div>
                  <div className='mailling-item'>
                    <label>Sim</label>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='blocklist'
                      value='SIM'
                      checked={isBlockList === 'SIM'}
                      onChange={({target: {value}}) => {
                        setIsBlockList(value)
                      }}
                    />
                  </div>
                  <div className='mailling-item'>
                    <label>Não</label>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='blocklist'
                      value='NAO'
                      checked={isBlockList === 'NAO'}
                      onChange={({target: {value}}) => {
                        setIsBlockList(value)
                      }}
                    />
                  </div>
                </div>
              </div>

              <button
                onClick={() => {
                  setLoading(true)
                  props.onChange(getData())
                }}
                className='btn btn-primary filter-btn'
              >
                {!loading && 'Filtrar'}{' '}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Style>
  )
}

export default LojistasFilterModal
