import {useCallback, useEffect, useRef, useState} from 'react'
import {useDebouncedCallback} from 'use-debounce'
import {Entity} from '../../types/Entity'
import {Loader2, Search} from 'lucide-react'
import {SearchResults} from './SearchResults'
import { JSObject } from '../../types/JSObject'

type InputSearchProps<T> = {
  placeholder: string
  onChange?: (query: string) => void
  onSearch?: (query: string) => void
  onSelect?: (value: T) => void
  onRenderResultItem?: (value: T) => JSX.Element
  results?: T[]
}

export const InputSearch = <T extends JSObject = JSObject>({
  placeholder,
  onChange,
  onSearch,
  onSelect,
  onRenderResultItem,
  results = [],
}: InputSearchProps<T>) => {
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleSearch = useCallback(
    async (query: string) => {
      setIsLoading(true)
      await onSearch?.(query)
      setIsLoading(false)
    },
    [onSearch]
  )

  const debouncedSearch = useDebouncedCallback((query: string) => {
    handleSearch(query)
  }, 250)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setQuery(value)
    setIsOpen(true)
    onChange?.(value)
    debouncedSearch(value)
  }

  const handleSelectItem = (item: T) => {
    onSelect?.(item)
    setQuery(item.name)
    setIsOpen(false)
  }

  return (
    <div ref={containerRef} className='relative'>
      <div className='relative'>
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
          <Search className='h-5 w-5 text-gray-400' />
        </div>
        <input
          type='text'
          value={query}
          onChange={handleInputChange}
          className='block w-full pl-10 pr-12 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
          placeholder={placeholder}
        />
        {isLoading && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center'>
            <Loader2 className='h-5 w-5 text-gray-400 animate-spin' />
          </div>
        )}
      </div>

      {isOpen && results.length > 0 && (
        <SearchResults 
          results={results} 
          onSelect={handleSelectItem} 
          isLoading={isLoading} 
          onRender={onRenderResultItem}
        />
      )}
    </div>
  )
}
