/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import * as API from '../../api'

type Props = {
  onChange: Function
}

const SupportStatus: FC<Props> = (props) => {
  const [data, setData] = useState<any[]>([])
  const [currentStatus, setCurrentStatus] = useState<any>()

  const loadStatusList = async () => {
    const response = await API.listSupportStatus()
    setData(response.data)
  }

  const changeStatus = () => {
    props.onChange(currentStatus)
  }

  useEffect(() => {
    loadStatusList()
  }, [])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Alterar Status</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>
          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={currentStatus?.guid}
              onChange={({target: {value}}) => {
                setCurrentStatus(value)
              }}
            >
              {data.map((status: any) => {
                return (
                  <option key={status.id_ticket_status} value={status.guid}>
                    {status.status}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Fechar
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={() => changeStatus()}
          >
            Alterar
          </button>
        </div>
      </div>
    </div>
  )
}

export default SupportStatus
