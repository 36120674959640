import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import styled from '@emotion/styled'
import {MenuItem} from '../../../../app/types/MenuItem'
import {useUsermenuData} from '../../../../app/hooks/useUserMenuData'

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  height: 100%;

  .btn-add-container {
    padding: 16px;
  }

  .text-center {
    color: #d8d8d8;
    text-align: center;
  }

  .menu-item-border {
    border-bottom: 1px solid #ccc !important;
  }

  p {
    color: #d8d8d8;
    text-align: center;
  }
`

export function AsideMenuMain() {
  const {menuData} = useUsermenuData()
  const renderMenuItem = (item: MenuItem) => {
    if (item.isSection) {
      return (
        <div className='menu-item' key={item.label}>
          <div className='menu-content pt-8 pb-2 text-center'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{item.label}</span>
          </div>

          <div>{item.children?.map((child) => renderMenuItem(child))}</div>
        </div>
      )
    }

    const MenuElement = item.children && item.children.length ? AsideMenuItemWithSub : AsideMenuItem
    return (
      <MenuElement
        key={item.label}
        to={item.to || ''}
        title={item.label}
        hasBullet={!!item.hasBullet}
      >
        {item.children?.map((child) => renderMenuItem(child))}
      </MenuElement>
    )
  }

  return (
    <StyledMenu>
      <div className='pt-4'>
        <AsideMenuItem
          to='/home'
          icon='/media/icons/duotune/general/gen001.svg'
          title={'Página Inicial'}
          fontIcon='bi-app-indicator'
        />
      </div>

      {menuData.map((item) => renderMenuItem(item))}
    </StyledMenu>
  )
}
