import React from 'react'
import styled from '@emotion/styled'

const StyledButton = styled.div`
  &:hover {
    i,
    .svg-icon {
      opacity: 0.3;
    }
  }
`

interface Props {
  onClick?: Function
}

const BtnFullscreen: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton>
      <div onClick={() => props.onClick && props.onClick()}>
        <div className='btn btn-sm btn-icon btn-active-color-primary'>
          <span className='svg-icon svg-icon-1'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z'
              />
            </svg>
          </span>
        </div>
      </div>
    </StyledButton>
  )
}

export default BtnFullscreen
