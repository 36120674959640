import {FC, useState} from 'react'
import styled from '@emotion/styled'
import BtnClose from './../../components/BtnClose'
import * as API from '../../api'
import {messages} from './../utils/ErrorsText'

const StyledForm = styled.div`
  .form-item {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
    p {
      width: 150px;
      margin: auto;
      text-align: right;
    }
  }
`

const StyledModal = styled.div`
  background-color: white;

  .card-header {
    align-items: center;
  }
`

interface Props {
  filters?: any
  report: any
  onClose: Function
}

const ReportFilterModal: FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>('')

  const [status, setStatus] = useState<string>('')

  async function onSubmit() {
    const statusResponse = validationStatus()
    setStatus(statusResponse)
    if (statusResponse !== '') return
    setLoading(true)
    try {
      const data = {name, filters: props.filters}
      console.log(data)
      setLoading(false)
      const response = await API.addReportFilter(props.report, data)
      if (!response.success) {
        setStatus(messages[response.error])
        setLoading(false)
        return
      }
      props.onClose(true)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  function validationStatus() {
    if (name.length < 3) return 'Nome do filtro deve conter no mínimo 3 caracteres'
    return ''
  }

  return (
    <StyledModal className='card '>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3>Salvar novo filtro</h3>
        </div>
        <BtnClose onClick={() => props.onClose(false)} />
      </div>
      <div className='card mb-5'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative '></div>
            <div className='flex-grow-1'>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <StyledForm>
                    <div className='form-item'>
                      <p>Nome do filtro: </p>
                      <input
                        className='form-control form-control-lg form-control-solid  pe-10 resize-none min-h-25px'
                        onChange={(e) => {
                          setName(e.currentTarget.value)
                        }}
                      ></input>
                    </div>
                  </StyledForm>
                </div>
              </div>
              {status !== '' && (
                <div className='text-center'>
                  <div className='alert alert-danger w-100 text-center'>
                    <div className='alert-text font-weight-bold'>{status}</div>
                  </div>
                </div>
              )}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-primary w-25 mb-5 mt-5'
                  disabled={!name.length}
                  onClick={() => {
                    onSubmit()
                  }}
                >
                  {!loading && <span className='indicator-label'>Salvar</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  )
}

export default ReportFilterModal
