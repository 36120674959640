import styled from '@emotion/styled'
import {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'

const ContentStyle = styled.div`
  width: 100%;
  height: 100%;

  .container {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  z-index: 1;
`

const Content: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <ContentStyle>
      <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
        {children}
      </div>
    </ContentStyle>
  )
}

export {Content}
