import {FC, useState, useEffect} from 'react'
import BtnClose from '../../components/BtnClose'
import Select from 'react-select'
import styled from '@emotion/styled'
import * as API from '../../api'
import ReportFilter from './ReportFilter'
import {useDistributors} from '../../../app/modules/auth'

const Style = styled.div`
  .modal-container {
    width: 100%;
  }

  .form-group {
    margin-bottom: 1rem;
    align-items: baseline;
    display: flex;
  }

  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .form-input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
  }

  .multi-select {
    width: 100%;
    margin-top: 1rem;
  }

  .mailling-item {
    margin-right: 30px;

    label {
      margin-right: 10px;
    }
  }
`

type Props = {
  onChange: Function
  onClose: Function
  data: any
  type: string
}

const VendedoresFilterModal: FC<Props> = (props) => {
  const distributors = useDistributors()

  const [loading, setLoading] = useState(false)
  const [selectedDistributors, setSelectedDistributors] = useState<any[]>([])

  const [status, setStatus] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [emailVendedor, setEmailVendedor] = useState<string>('')
  const [emailAprovador, setEmailAprovador] = useState<string>('')
  const [filter, setFilter] = useState<any>(null)

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    clear()
    if (filter || props.data) {
      const data = filter?.data || props.data
      if (data.status) setStatus(data.status)
      if (data.distributors) setSelectedDistributors(data.distributors)
      if (data.name) setName(data.name)
      if (data.emailVendedor) setEmailVendedor(data.emailVendedor)
      if (data.emailAprovador) setEmailAprovador(data.emailAprovador)
    }
  }, [props.data, filter])

  function clear() {
    setStatus('')
    setSelectedDistributors([])
    setName('')
    setEmailAprovador('')
    setEmailVendedor('')
  }

  function getData() {
    return {
      status,
      distributors: selectedDistributors,
      name,
      emailVendedor,
      emailAprovador,
    }
  }

  return (
    <Style>
      <div className='modal-container'>
        <div className='px-7 py-5 d-flex justify-content-between'>
          <div className='pt-2 fs-3 text-dark fw-bolder'>Filtros para o relatório</div>
          <BtnClose onClick={() => props.onClose()} />
        </div>
        <ReportFilter name={props.type} filter={getData()} setFilter={setFilter}></ReportFilter>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-4 d-flex flex-wrap'>
            <div className=' justify-content-between modal-container'>
              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Status:</label>
                <select
                  disabled={loading}
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder=''
                  data-allow-clear='true'
                  value={status}
                  onChange={(evt: any) => setStatus(evt.target.value)}
                >
                  <option value=''>Todos</option>
                  <option value='Ativo'>Ativo</option>
                  <option value='Inativo'>Inativo</option>
                  <option value='Inativo Temporariamente'>Inativo Temporariamente</option>
                </select>
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Distribuidor:</label>
                <Select
                  isMulti
                  isDisabled={loading}
                  options={distributors}
                  value={selectedDistributors}
                  closeMenuOnSelect={false}
                  onChange={(selected: any) => setSelectedDistributors(selected)}
                  placeholder={'Filtrar por Distribuidor'}
                  className='multi-select'
                />
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>Nome do Vendedor:</label>
                <input
                  className='form-input form-control'
                  type='text'
                  disabled={loading}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                ></input>
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>E-mail do Vendedor:</label>
                <input
                  className='form-input form-control'
                  type='text'
                  disabled={loading}
                  value={emailVendedor}
                  onChange={(e) => {
                    setEmailVendedor(e.target.value)
                  }}
                ></input>
              </div>

              <div className='form-group'>
                <label className='form-label fw-bold me-2 w-200px'>E-mail do Aprovador:</label>
                <input
                  className='form-input form-control'
                  type='text'
                  disabled={loading}
                  value={emailAprovador}
                  onChange={(e) => {
                    setEmailAprovador(e.target.value)
                  }}
                ></input>
              </div>

              <button
                onClick={() => {
                  setLoading(true)
                  props.onChange(getData())
                }}
                className='btn btn-primary filter-btn'
              >
                {!loading && 'Filtrar'}{' '}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Style>
  )
}

export default VendedoresFilterModal
