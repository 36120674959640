import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import * as API from '../../../api'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  distributors: any[]
  industries: any[]
  regions: any[]
}

const initAuthContextPropsState: AuthContextProps = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  distributors: [],
  industries: [],
  regions: [],
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [distributors, setDistributors] = useState<any[]>([])
  const [industries, setIndustries] = useState<any[]>([])
  const [regions, setRegions] = useState<any[]>([])

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem('current_company')
  }

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        const response: any = await getUserByToken(apiToken)
        if (response.data.success) {
          localStorage.setItem('auth', JSON.stringify(response.data.data))
          setCurrentUser(response.data)
        }
      } catch (error) {
        logout()
      }
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
    }

    getDistributors()
    getIndustries()
    getRegions()
  }, [auth])

  const getDistributors = async () => {
    const result = await API.getDistributors()
    if (result.success) {
      const data: any[] = result.data.map((item: any) => ({
        value: item[0],
        label: item[1],
      }))
      setDistributors(data)
    }
  }

  const getIndustries = async () => {
    const result = await API.getIndustries()
    if (result.success) {
      const data: any[] = result.data.map((item: any) => ({
        value: item[0],
        label: item[1],
      }))
      setIndustries(data)
    }
  }

  const getRegions = async () => {
    const result = await API.getRegions()
    if (result.success) {
      const data: any[] = result.data.map((item: any) => ({
        value: item[0],
        label: item[0],
      }))
      setRegions(data)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        distributors,
        industries,
        regions,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const init = async () => {
      if (auth && auth.api_token) {
        try {
          const response: any = await getUserByToken(auth.api_token)
          if (response.data.success) {
            localStorage.setItem('auth', JSON.stringify(response.data.data))
            setCurrentUser(response.data)
          } else {
            logout()
          }
        } catch (error) {
          logout()
        }
      } else {
        logout()
      }
      setShowSplashScreen(false)
    }

    if (!didRequest.current) {
      init()
    }

    return () => {
      didRequest.current = true
    }
  }, [auth, logout, setCurrentUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

const getUserData = () => {
  const authData: string = localStorage.getItem('auth') as string
  try {
    return JSON.parse(authData)
  } catch (e) {
    console.log(e)
  }
  return {}
}

const isAdmin = () => {
  const data = getUserData()
  return !!(data?.is_admin || data?.is_superadmin)
}

const isSuperAdmin = (): boolean => {
  const data = getUserData()
  return !!data?.is_superadmin
}

// Custom hooks to get distributors, industries, and regions from context
const useDistributors = () => {
  const {distributors} = useContext(AuthContext)
  return distributors
}

const useIndustries = () => {
  const {industries} = useContext(AuthContext)
  return industries
}

const useRegions = () => {
  const {regions} = useContext(AuthContext)
  return regions
}

export {
  AuthProvider,
  AuthInit,
  useAuth,
  getUserData,
  isAdmin,
  isSuperAdmin,
  useDistributors,
  useIndustries,
  useRegions,
}
