import React, {FC, useEffect, useRef, useState} from 'react'
import styled from '@emotion/styled'
import {useParams} from 'react-router'
import BtnClose from '../../components/BtnClose'

import * as API from '../../api'
import IChart from './IChart'
import IPowerBI from './IPowerBI'

interface Props {
  chartData: any
  powerBIData?: IPowerBI
  onChartCreated: Function
  onChartUpdated: Function
  onClose: Function
}

interface GroupData {
  capacityId: string
  id: string
  isOnDedicatedCapacity: boolean
  isReadOnly: boolean
  name: string
  type: string
}

const StyledCreateChart = styled.div`
  background-color: white;

  .card-header {
    align-items: center;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .action-buttons {
    display: flex;
    justify-content: end;
    gap: 12px;
  }

  .form-input-item {
    width: 100%;
    gap: 10px;
  }

  .tenant-content {
    display: flex;
  }

  .form-button {
    width: 200px;
    margin-left: 20px;
  }

  .form-item {
    margin-bottom: 10px;
  }

  .warning-message {
    color: red;
    font-size: 14px;
  }
`

const CreateChart: FC<Props> = (props: Props) => {
  const edit = !!props.chartData
  const [warningMessage, setWarningMessage] = useState<string>('')

  const [chart, setChart] = useState<string>(
    props.chartData ? (props.chartData.tenant_id ? 'powerbi' : props.chartData.chart) : 'powerbi'
  )
  const [query, setQuery] = useState<string>(props.chartData ? props.chartData.query : '')
  const [title, setTitle] = useState<string>(props.chartData ? props.chartData.title : '')

  const [saving, setSaving] = useState<boolean>(false)

  const [groups, setGroups] = useState<GroupData[]>([])
  const [currentGroup, setCurrentGroup] = useState<GroupData>()
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false)

  const [reports, setReports] = useState<any[]>([])
  const [currentReport, setCurrentReport] = useState<any>()

  const [tenants, setTenants] = useState<any[]>([])
  const [tenant_id, setTenant] = useState<string>(
    props.powerBIData?.tenant_id ? props.powerBIData?.tenant_id : ''
  )

  const [editFirstLoad, setEditFirstLoad] = useState(true)

  const {dashboardId} = useParams()

  async function createChart() {
    const res = await API.createChart(chart, query, title, dashboardId as string)
    if (res.success) {
      props.onChartCreated()
    } else {
      setWarningMessage(res.error)
      setSaving(false)
    }
  }

  async function createPowerBI() {
    if (!currentGroup) return
    const res = await API.createPowerBI(
      currentReport.datasetId,
      tenant_id,
      currentGroup.id,
      currentReport.id,
      dashboardId as string
    )
    if (res.success) {
      props.onChartCreated()
    } else {
      setWarningMessage(res.error?.message)
      setSaving(false)
    }
  }

  async function editChart() {
    if (!props.chartData) return
    const res = await API.updateChart(props.chartData.guid, {
      chart,
      query,
      title,
    })
    if (res.success) {
      props.onChartUpdated()
    } else {
      setWarningMessage(res.error)
      setSaving(false)
    }
  }

  async function editPowerBI() {
    if (!currentGroup) return
    const res = await API.updatePowerBI(props.chartData.guid, {
      tenant_id,
      datasets: currentReport.datasetId,
      group_id: currentGroup.id,
      report_id: currentReport.id,
    })
    if (res.success) {
      props.onChartUpdated()
    } else {
      setWarningMessage(res.error?.message)
      setSaving(false)
    }
  }

  async function onClickSave() {
    setSaving(true)
    setWarningMessage('')
    if (edit) {
      chart == 'powerbi' ? editPowerBI() : editChart()
    } else {
      chart == 'powerbi' ? createPowerBI() : createChart()
    }
  }

  async function onClickLoadGroups() {
    setLoadingGroups(true)
    const response = await API.loadGroups(tenant_id)
    const responseData: any[] = response.data
    const group =
      props.chartData?.group_id && editFirstLoad
        ? responseData.find((i) => {
            return i.id == props.chartData?.group_id
          })
        : response.data[0]
    setGroups(responseData)
    setCurrentGroup(group)
    loadReports(group.id)
    setLoadingGroups(false)
  }

  async function loadReports(group_id: string) {
    const response = await API.loadReports(tenant_id, group_id)
    const responseData: any[] = response.data
    setReports(response.data)
    const report =
      props.chartData?.report_id && editFirstLoad
        ? responseData.find((i) => {
            return i.id == props.chartData.report_id
          })
        : response.data[0]
    console.log(report)
    setCurrentReport(report)
    setEditFirstLoad(false)
  }

  if (!tenant_id) {
    API.loadTenants().then((response) => {
      setTenants(response.data)
      setTenant(response.data[0])
    })
  }

  useEffect(() => {
    if (!edit || !editFirstLoad) return
    onClickLoadGroups()
  }, [tenant_id])

  return (
    <StyledCreateChart className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          {props.chartData ? <h3>Editar Gráfico</h3> : <h3>Novo Gráfico</h3>}
        </div>

        <BtnClose onClick={() => props.onClose()} />
      </div>
      <div className='card-body border-top p-9'>
        <select
          className='form-select form-select-lg form-select-solid'
          value={chart}
          onChange={({target: {value}}) => setChart(value)}
        >
          <option value='powerbi'>Power BI</option>
          <option value='bar'>Barras</option>
          <option value='hbar'>Barras Horizontais</option>
          <option value='pie'>Pizza</option>
        </select>
        {chart !== 'powerbi' && (
          <>
            <input
              className='form-control form-control-lg form-control-solid'
              defaultValue={title}
              placeholder={'Titulo'}
              onChange={(e) => setTitle(e.currentTarget.value)}
            ></input>
            <textarea
              className='form-control form-control-lg form-control-solid'
              defaultValue={query}
              placeholder={'SQL Query'}
              onChange={({target: {value}}) => setQuery(value)}
            ></textarea>
          </>
        )}

        {chart == 'powerbi' && (
          <>
            <div className=''>
              <div className='tenant-content'>
                <input
                  className='form-input-item form-control form-control-solid form-item'
                  defaultValue={tenant_id}
                  placeholder={'Tenant ID'}
                  onChange={(e) => setTenant(e.currentTarget.value)}
                ></input>
                {tenant_id.length ? (
                  <button
                    className='btn btn-primary form-button form-item'
                    disabled={loadingGroups}
                    onClick={onClickLoadGroups}
                  >
                    Carregar Grupos
                  </button>
                ) : (
                  ''
                )}
              </div>
              {groups.length ? (
                <select
                  className='form-select form-select-lg form-select-solid form-item'
                  defaultValue={currentGroup?.id}
                  onChange={({target: {value}}) => {
                    const group = groups.find((i) => {
                      return i.id == value
                    })
                    if (!group) return
                    setCurrentGroup(group)
                    setReports([])
                    loadReports(group.id)
                  }}
                >
                  {groups.map((group, index) => (
                    <option key={'option' + index} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              ) : (
                ''
              )}
              {reports.length && !loadingGroups ? (
                <select
                  className='form-select form-select-lg form-select-solid form-item'
                  defaultValue={currentReport?.id}
                  onChange={({target: {value}}) => {
                    setCurrentReport(
                      reports.find((i) => {
                        return i.id == value
                      })
                    )
                  }}
                >
                  {reports.map((report, index) => (
                    <option key={'option' + index} value={report.id}>
                      {report.name}
                    </option>
                  ))}
                </select>
              ) : (
                ''
              )}
            </div>
          </>
        )}
        {warningMessage?.length ? <p className='warning-message'>{warningMessage}</p> : <></>}
      </div>
      <div className='card-footer'>
        <div className='action-buttons'>
          <button
            className='btn btn-sm fw-bold btn-secondary'
            disabled={saving}
            onClick={() => props.onClose()}
          >
            Cancelar
          </button>
          <button
            className='btn btn-sm fw-bold btn-primary'
            disabled={saving}
            onClick={onClickSave}
          >
            Salvar {saving && <div className='spinner-border spinner-border-sm'></div>}
          </button>
        </div>
      </div>
    </StyledCreateChart>
  )
}

export default CreateChart
