import React, {FC, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import styled from '@emotion/styled'
import * as API from '../../api'
import PowerBIFilterModal from './PowerBIFilterModal'

const Style = styled.div`
  .filters {
    gap: 5px;
  }
`

interface Props {
  action: any
  setAction: any
  filter: any
  setFilter: any
}

const PowerBIFilter: FC<Props> = (props: Props) => {
  const [filters, setFilters] = useState<any[]>([])
  const [selectedFilter, setSelectedFilter] = useState<any>(null)
  const [currentFilter, setCurrentFilter] = useState<any>(null)
  const [filterModal, showFilterModal] = useState<boolean>(false)

  const [updateLoading, setUpdateLoading] = useState<boolean>(false)
  const [removeLoading, setRemoveLoading] = useState<boolean>(false)

  const getFilters = async () => {
    const result = await API.getPowerBIFilters()
    setFilters(result.data)
  }

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(() => {
    if (!props.action) return
    if (props.action.type === 'update-filter') {
      setCurrentFilter(props.action.data)
    }
  }, [props.action])

  const updateFilter = async () => {
    try {
      setUpdateLoading(true)
      const json = JSON.parse(props.filter)
      await API.updatePowerBIFilter({
        id_powerbi_filter: json.id_powerbi_filter,
        filters: props.action.data,
      })
      getFilters()
      setUpdateLoading(false)
    } catch (e) {
      setUpdateLoading(false)
    }
  }

  const removeFilter = async () => {
    try {
      setRemoveLoading(true)
      const json = JSON.parse(props.filter)
      await API.removePowerBIFilter({id_powerbi_filter: json.id_powerbi_filter})
      getFilters()
      setRemoveLoading(false)
    } catch (e) {
      setRemoveLoading(false)
    }
  }

  return (
    <Style>
      <Modal
        id='modal_message'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={filterModal}
      >
        <PowerBIFilterModal
          filters={currentFilter}
          onClose={(success: boolean) => {
            showFilterModal(false)
            if (success) getFilters()
          }}
        />
      </Modal>
      <div className='notice d-flex justify-content-between bg-light-primary rounded border-primary border p-3 mb-3'>
        <div className='form-check form-switch justify-content-between form-check-custom form-check-solid w-100'>
          {filters?.length === 0 ? (
            <div className='d-flex justify-content-center'>
              <div className='justify-content-center'>
                <label className='form-check-label p-4'>Nenhum filtro salvo no momento!</label>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-lg btn-success bg-success'
                  onClick={() => {
                    showFilterModal(true)
                  }}
                >
                  Salvar filtro atual
                </button>
              </div>
            </div>
          ) : (
            <div className='filters form-check-custom w-100'>
              <label className='form-check-label' htmlFor='flexSwitchDefault'>
                Filtros:
              </label>
              <select
                className='form-select form-select-lg form-select-solid form-item'
                defaultValue={0}
                value={props.filter}
                onChange={({target: {value}}) => {
                  setSelectedFilter(value)
                  props.setFilter(value)
                }}
              >
                <option key={'none'} value={'none'}>
                  Nenhum filtro salvo selecionado
                </option>
                {filters.map((filter, index) => (
                  <option key={'option' + index} value={JSON.stringify(filter)}>
                    {filter.name}
                  </option>
                ))}
              </select>
              {props.filter && props.filter !== 'none' ? (
                <>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary bg-primary'
                    onClick={() => {
                      updateFilter()
                    }}
                  >
                    {!updateLoading && <span className='indicator-label'>Atualizar</span>}
                    {updateLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-danger bg-danger'
                    onClick={() => {
                      removeFilter()
                    }}
                  >
                    {!removeLoading && <span className='indicator-label'>Remover</span>}
                    {removeLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </>
              ) : (
                <></>
              )}
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-success bg-success'
                onClick={() => {
                  showFilterModal(true)
                }}
              >
                Criar
              </button>
            </div>
          )}
        </div>
        <div className='space'></div>
      </div>
    </Style>
  )
}

export default PowerBIFilter
