import React, {Suspense} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {useEffect} from 'react'
import * as API from './api'

// Initialize Feature Flags
import './data-layer/featureflags'

export const MyContext = React.createContext(null)

declare global {
  interface Window {
    gtag: any
  }
}

const App = () => {
  const location = useLocation()

  useEffect(() => {}, [location.pathname])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
