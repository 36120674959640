import {FC, useState, useEffect} from 'react'
import BtnClose from '../../../components/BtnClose'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import ReportFilter from './../ReportFilter'
import {useDistributors, useRegions, useIndustries} from '../../../../app/modules/auth'
import {FilterContainer} from './../shared/FilterStyles'
import LoadingSpinner from './../shared/LoadingSpinner'
import * as API from '../../../api'

const PoliticaPrecoFilter: FC<any> = (props) => {
  const distributors = useDistributors()
  const industries = useIndustries()

  const [grupos, setGrupos] = useState<any[]>([])
  const [marcas, setMarcas] = useState<any[]>([])
  const [familias, setFamilias] = useState<any[]>([])
  const [tipoPrecos, setTipoPrecos] = useState<any[]>([])
  const [integracoes, setIntegracoes] = useState<any[]>([])
  const [politicaPrecos, setPoliticaPrecos] = useState<any[]>([])

  const [loading, setLoading] = useState(false)
  const [selectedDistributors, setSelectedDistributors] = useState<any[]>([])
  const [selectedIndustries, setSelectedIndustries] = useState<any[]>([])

  const [selectedGrupos, setSelectedGrupos] = useState<any[]>([])
  const [selectedMarcas, setSelectedMarcas] = useState<any[]>([])
  const [selectedFamilia, setSelectedFamilia] = useState<any[]>([])
  const [selectedTipoPreco, setSelectedTipoPreco] = useState<any[]>([])
  const [selectedIntegracao, setSelectedIntegracao] = useState<any[]>([])
  const [selectedPoliticaPreco, setSelectedPoliticaPreco] = useState<any[]>([])

  const [isBlockList, setIsBlockList] = useState<string>('')

  const [status, setStatus] = useState<string>(props.type == 'preco' ? 'preco' : 'estoque')
  const [statusProduto, setStatusProduto] = useState<string>('')
  const [cnpj, setCNPJ] = useState<string>('')
  const [idSKU, setIdSKU] = useState<string>('')
  const [bu, setBU] = useState<string>('')
  const [grupo, setGrupo] = useState<string>('')
  const [familiaProduto, setFamiliaProduto] = useState<string>('')
  const [marca, setMarca] = useState<string>('')
  const [politicaPreco, setPoliticaPreco] = useState<string>('')
  const [dataNFIntegracao, setDataNFIntegracao] = useState<string>('')
  const [filterByDate, setFilterByDate] = useState<string>('')
  const [tipoPreco, setTipoPreco] = useState<string>('')
  const [prazoPagamento, setPrazoPagamento] = useState<string>('')
  const [estoque, setEstoque] = useState<string>('')
  const [integracao, setIntegracao] = useState<string>('')
  const [filter, setFilter] = useState<any>(null)
  const [dropdownFilters, setDropdownFilters] = useState<any>(null)
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
  )

  const minDate = new Date(new Date(new Date().setMonth(new Date().getMonth() - 18)))
  const maxDate = new Date()

  useEffect(() => {
    setLoading(false)
    loadDropdownFilters()
  }, [])

  async function loadDropdownFilters() {
    const response = await API.getUltimoPrecoEstoqueFilters()
    setDropdownFilters(response.data)
    setPoliticaPrecos(
      response.data.politicaPreco.map((item: any) => ({
        value: item,
        label: item,
      }))
    )
    setGrupos(
      response.data.grupo.map((item: any) => ({
        value: item,
        label: item,
      }))
    )
    setFamilias(
      response.data.familia.map((item: any) => ({
        value: item,
        label: item,
      }))
    )
    setMarcas(
      response.data.marca.map((item: any) => ({
        value: item,
        label: item,
      }))
    )
    setTipoPrecos(
      response.data.tipoPreco.map((item: any) => ({
        value: item,
        label: item,
      }))
    )
    setIntegracoes(
      response.data.integracao.map((item: any) => ({
        value: item,
        label: item,
      }))
    )
  }

  useEffect(() => {
    clear()
    if (filter || props.data) {
      const data = filter?.data || props.data
      if (data.dtstart)
        setStartDate(new Date(new Date(data.dtstart).setDate(new Date(data.dtstart).getDate() + 1)))
      if (data.status) setStatus(data.status)
      if (data.idSKU) setIdSKU(data.idSKU)
      if (data.bu) setBU(data.bu)
      if (data.cnpj) setCNPJ(data.cnpj)

      if (data.industries) setSelectedIndustries(data.industries)
      if (data.distributors) setSelectedDistributors(data.distributors)
      if (data.politicaPreco) setPoliticaPreco(data.politicaPreco)
      if (data.filterByDate) setFilterByDate(data.filterByDate)
      if (data.statusProduto) setStatusProduto(data.statusProduto)

      if (data.familiaProduto) setSelectedFamilia(data.familiaProduto)
      if (data.marca) setSelectedMarcas(data.marca)
      if (data.grupo) setSelectedGrupos(data.grupo)
      if (data.tipoPreco) setSelectedTipoPreco(data.tipoPreco)
      if (data.integracao) setSelectedIntegracao(data.integracao)

      if (data.prazoPagamento) setPrazoPagamento(data.prazoPagamento)
      if (data.estoque) setEstoque(data.estoque)
      if (data.isBlockList) setIsBlockList(data.isBlockList)
    }
  }, [props.data, filter])

  function clear() {
    setStatus(props.type == 'preco' ? 'preco' : 'estoque')
    setSelectedDistributors([])
    setSelectedIndustries([])
    setStartDate(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000))
    setIdSKU('')
    setCNPJ('')
    setPoliticaPreco('')
    setBU('')

    setFilterByDate('')

    setSelectedTipoPreco([])
    setSelectedIntegracao([])
    setSelectedMarcas([])
    setSelectedGrupos([])
    setSelectedFamilia([])

    setFilterByDate('')
    setStatusProduto('')
    setPrazoPagamento('')
  }

  function getData() {
    return {
      status,
      distributors: selectedDistributors,
      industries: selectedIndustries,
      dtstart: getStringDate(startDate),
      idSKU,
      bu,
      cnpj,
      familiaProduto: selectedFamilia,
      marca: selectedMarcas,
      grupo: selectedGrupos,
      politicaPreco,
      filterByDate,
      tipoPreco: selectedTipoPreco,
      prazoPagamento,
      estoque,
      integracao: selectedIntegracao,
      statusProduto,
      isBlockList,
    }
  }

  function getStringDate(date: Date) {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const dayFormatted = day < 10 ? '0' + day : day.toString()
    const monthFormatted = month < 10 ? '0' + month : month.toString()

    return `${year}-${monthFormatted}-${dayFormatted}`
  }

  return (
    <FilterContainer>
      <div className='modal-container'>
        <div className='modal-header'>
          <h2 className='modal-title'>Filtros para o relatório</h2>
          <BtnClose onClick={() => props.onClose()} />
        </div>

        <ReportFilter name={props.type} filter={getData()} setFilter={setFilter} />

        <div className='form-section'>
          <div className='form-grid'>
            <div className='form-group full-width'>
              <label className='form-label'>Indústria</label>
              <Select
                isMulti
                isDisabled={loading}
                options={industries}
                value={selectedIndustries}
                placeholder='Filtrar por Indústria'
                closeMenuOnSelect={false}
                onChange={(selected: any) => setSelectedIndustries(selected)}
                className='select-container'
                classNamePrefix='select'
              />
            </div>

            <div className='form-group full-width'>
              <label className='form-label'>Distribuidor</label>
              <Select
                isMulti
                isDisabled={loading}
                options={distributors}
                value={selectedDistributors}
                placeholder='Filtrar por Distribuidor'
                closeMenuOnSelect={false}
                onChange={(selected: any) => setSelectedDistributors(selected)}
                className='select-container'
                classNamePrefix='select'
              />
            </div>
          </div>

          <div className='section-divider' />

          <div className='form-grid'>
            <div className='form-group'>
              <label className='form-label'>SKU</label>
              <input
                className='form-control'
                type='text'
                disabled={loading}
                value={idSKU}
                onChange={(e) => setIdSKU(e.target.value)}
                placeholder='Digite o SKU'
              />
            </div>

            <div className='form-group'>
              <label className='form-label'>Unidade de Negócio</label>
              <input
                className='form-control'
                type='text'
                disabled={loading}
                value={bu}
                onChange={(e) => setBU(e.target.value)}
                placeholder='Digite a unidade de negócio'
              />
            </div>

            <div className='form-group'>
              <label className='form-label'>CNPJ</label>
              <input
                className='form-control'
                type='text'
                disabled={loading}
                value={cnpj}
                onChange={(e) => setCNPJ(e.target.value)}
                placeholder='Digite o CNPJ'
              />
            </div>

            <div className='form-group'>
              <label className='form-label'>Marca</label>
              <Select
                isMulti
                isDisabled={loading}
                options={marcas}
                value={selectedMarcas}
                placeholder='Filtrar por Marca'
                closeMenuOnSelect={false}
                onChange={(selected: any) => setSelectedMarcas(selected)}
                className='select-container'
                classNamePrefix='select'
              />
            </div>
          </div>

          <div className='form-grid'>
            <div className='form-group'>
              <label className='form-label'>Política de Preço</label>
              <input
                className='form-control'
                type='text'
                disabled={loading}
                value={politicaPreco}
                onChange={(e) => setPoliticaPreco(e.target.value)}
                placeholder='Digite a política de preço'
              />
            </div>

            <div className='form-group'>
              <label className='form-label'>Tipo de Preço</label>
              <Select
                isMulti
                isDisabled={loading}
                options={tipoPrecos}
                value={selectedTipoPreco}
                placeholder='Tipo de Preço'
                closeMenuOnSelect={false}
                onChange={(selected: any) => setSelectedTipoPreco(selected)}
                className='select-container'
                classNamePrefix='select'
              />
            </div>

            <div className='form-group'>
              <label className='form-label'>Prazo de Pagamento</label>
              <input
                className='form-control'
                type='text'
                disabled={loading}
                value={prazoPagamento}
                onChange={(e) => setPrazoPagamento(e.target.value)}
                placeholder='Digite o prazo de pagamento'
              />
            </div>

            <div className='form-group full-width'>
              <label className='form-label'>FL BlockList</label>
              <div className='radio-group'>
                <div className='radio-item'>
                  <input
                    type='radio'
                    id='status-todos'
                    name='flblocklist'
                    value=''
                    checked={isBlockList === ''}
                    onChange={({target: {value}}) => setIsBlockList(value)}
                  />
                  <label htmlFor='status-todos'>Todos</label>
                </div>
                <div className='radio-item'>
                  <input
                    type='radio'
                    id='status-online'
                    name='flblocklist'
                    value='SIM'
                    checked={isBlockList === 'SIM'}
                    onChange={({target: {value}}) => setIsBlockList(value)}
                  />
                  <label htmlFor='status-online'>Sim</label>
                </div>
                <div className='radio-item'>
                  <input
                    type='radio'
                    id='status-offline'
                    name='flblocklist'
                    value='NAO'
                    checked={isBlockList === 'NAO'}
                    onChange={({target: {value}}) => setIsBlockList(value)}
                  />
                  <label htmlFor='status-offline'>Não</label>
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={() => {
              setLoading(true)
              props.onChange(getData())
            }}
            className='filter-btn'
            disabled={loading}
          >
            {loading ? (
              <>
                Aguarde
                <LoadingSpinner />
              </>
            ) : (
              <>
                Aplicar Filtros
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4 6H20M6 12H18M8 18H16'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </>
            )}
          </button>
        </div>
      </div>
    </FilterContainer>
  )
}

export default PoliticaPrecoFilter
