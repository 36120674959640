import React, {useState, useEffect} from 'react'
import {
  Plus,
  FileText,
  BarChart3,
  Users,
  Edit2,
  Trash2,
  ExternalLink,
  Eye,
  Power,
  AlertCircle,
  AlertTriangle,
} from 'lucide-react'
import {Modal} from 'react-bootstrap'
import * as API from '../../api'
import {useNavigate, Link} from 'react-router-dom'
import NPSSurvey from './NPSSurvey'

interface Survey {
  id_survey: string
  title: string
  description: string
  questions: number
  responses: number
  averageScore: number
  status: 'active' | 'inactive' | 'draft'
  created_at: string
}

interface NPSListProps {
  onCreateNew: () => void
  onEdit: (survey: any) => void
  onPreview: () => void
}

interface DeleteModalProps {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  surveyTitle: string
  responseCount: number
}

interface ActiveModalProps {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  surveyTitle: string
}

const DeleteModal = ({show, onHide, onConfirm, surveyTitle, responseCount}: DeleteModalProps) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Confirmar Exclusão</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='space-y-4'>
        <div className='flex items-start gap-4'>
          <div className='p-3 bg-red-100 rounded-full'>
            <AlertTriangle className='w-6 h-6 text-red-600' />
          </div>
          <div>
            <h3 className='text-lg font-semibold text-gray-900'>
              Tem certeza que deseja excluir esta pesquisa?
            </h3>
            <p className='text-gray-600 mt-1'>
              A pesquisa "<span className='font-medium'>{surveyTitle}</span>" será excluída
              permanentemente.
            </p>
            {responseCount > 0 && (
              <p className='text-red-600 mt-2 text-sm'>
                Atenção: Esta pesquisa possui {responseCount} respostas que também serão excluídas.
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button
        onClick={onHide}
        className='px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors'
      >
        Cancelar
      </button>
      <button
        onClick={onConfirm}
        className='px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700
                 transition-colors'
      >
        Excluir Pesquisa
      </button>
    </Modal.Footer>
  </Modal>
)

const ActiveModal = ({show, onHide, onConfirm, surveyTitle}: ActiveModalProps) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Ativar Pesquisa</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='space-y-4'>
        <div className='flex items-start gap-4'>
          <div className='p-3 bg-green-100 rounded-full'>
            <AlertTriangle className='w-6 h-6 text-green-600' />
          </div>
          <div>
            <h3 className='text-lg font-semibold text-gray-900'>
              Tem certeza que deseja ativar esta pesquisa?
            </h3>
            <p className='text-gray-600 mt-1'>
              A pesquisa "<span className='font-medium'>{surveyTitle}</span>" será ativada e irá
              aparecer para todos.
            </p>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button
        onClick={onHide}
        className='px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors'
      >
        Cancelar
      </button>
      <button
        onClick={onConfirm}
        className='px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700
                   transition-colors'
      >
        Ativar Pesquisa
      </button>
    </Modal.Footer>
  </Modal>
)

const SurveyCard = ({
  survey,
  onEdit,
  showActivateButton = false,
  onActivate,
  onDelete,
  onView,
  className = '',
}: {
  survey: Survey
  onEdit: (survey: any) => void
  showActivateButton?: boolean
  onActivate?: (survey: Survey) => void
  onDelete: (survey: Survey) => void
  onView: (survey: Survey) => void
  className?: string
}) => {
  const getStatusColor = (status: Survey['status']) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-700 border-green-200'
      case 'inactive':
        return 'bg-yellow-100 text-yellow-700 border-yellow-200'
      default:
        return 'bg-gray-100 text-gray-700 border-gray-200'
    }
  }

  const getStatusText = (status: Survey['status']) => {
    switch (status) {
      case 'active':
        return 'Ativa'
      case 'inactive':
        return 'Inativa'
      default:
        return 'Rascunho'
    }
  }

  return (
    <div
      className={`bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 p-6 ${className}`}
    >
      <div className='flex justify-between items-start'>
        <div className='flex-1'>
          <div className='flex items-center gap-3'>
            <h2 className='text-xl font-semibold text-gray-900'>{survey.title}</h2>

            <span
              className={`px-3 py-1 rounded-full text-sm font-medium border ${getStatusColor(
                survey.status
              )}`}
            >
              {getStatusText(survey.status)}
            </span>
          </div>
          <p className='text-gray-700 mt-1'>{survey.description}</p>
          <p className='text-gray-500 mt-1'>
            Criado em {new Date(survey.created_at).toLocaleDateString('pt-BR')}
          </p>
        </div>

        <div className='flex gap-2'>
          <button
            onClick={() => (onActivate ? onActivate(survey) : null)}
            className={`p-2 hover:bg-${
              showActivateButton ? 'green' : 'red'
            }-50 rounded-lg transition-colors group`}
            title={showActivateButton ? 'Ativar pesquisa' : 'Inativar pesquisa'}
          >
            <Power className={`w-5 h-5 text-${showActivateButton ? 'green' : 'red'}-600`} />
          </button>

          <button
            onClick={() => onEdit(survey)}
            className='p-2 hover:bg-blue-50 rounded-lg transition-colors group'
            title='Editar'
          >
            <Edit2 className='w-5 h-5 text-blue-600' />
          </button>
          <button
            onClick={() => onDelete(survey)}
            className='p-2 hover:bg-red-50 rounded-lg transition-colors group'
            title='Excluir'
          >
            <Trash2 className='w-5 h-5 text-red-600' />
          </button>
          {survey.questions > 0 && (
            <button
              onClick={() => onView(survey)}
              className='p-2 hover:bg-gray-50 rounded-lg transition-colors group'
              title='Abrir pesquisa'
            >
              <Eye className='w-5 h-5 text-gray-600' />
            </button>
          )}
        </div>
      </div>

      <div className='grid grid-cols-3 gap-6 mt-6'>
        <div className='flex items-center gap-3'>
          <div className='p-3 bg-purple-100 rounded-lg'>
            <FileText className='w-5 h-5 text-purple-600' />
          </div>
          <div>
            <p className='text-sm text-gray-600'>Questões</p>
            <p className='text-lg font-semibold text-gray-900'>{survey.questions}</p>
          </div>
        </div>

        <div className='flex items-center gap-3'>
          <div className='p-3 bg-blue-100 rounded-lg'>
            <Users className='w-5 h-5 text-blue-600' />
          </div>
          <div>
            <p className='text-sm text-gray-600'>Respostas</p>
            <p className='text-lg font-semibold text-gray-900'>{survey.responses || 0}</p>
          </div>
        </div>

        {/*
        <div className="flex items-center gap-3">
          <div className="p-3 bg-green-100 rounded-lg">
            <BarChart3 className="w-5 h-5 text-green-600" />
          </div>
          <div>
            <p className="text-sm text-gray-600">Média NPS</p>
            <p className="text-lg font-semibold text-gray-900">{survey.averageScore}</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default function NPSList() {
  const [selectedSurvey, setSelectedSurvey] = useState<any>(null)
  const [showSurvey, setShowSurvey] = useState<boolean>(false)
  const [activeSurvey, setActiveSurvey] = useState<any>(null)
  const [inactiveSurveys, setInactiveSurveys] = useState<any[]>([])

  const navigate = useNavigate()

  const onCreateNew = () => {}

  const onEdit = (survey: any) => {
    navigate(`edit/${survey.id_survey}`)
  }
  const onPreview = (survey: any) => {
    setShowSurvey(true)
  }

  const [deleteModal, setDeleteModal] = useState<{
    show: boolean
    survey: Survey | null
  }>({
    show: false,
    survey: null,
  })

  const [activeModal, setActiveModal] = useState<{
    show: boolean
    survey: Survey | null
  }>({
    show: false,
    survey: null,
  })

  const handleActivate = (survey: Survey) => {
    setActiveModal({show: true, survey})
  }

  const handleInactive = async (survey: Survey) => {
    await API.updateSurvey('inactive', survey.id_survey)
    await loadSurveys()
  }

  const handleDelete = (survey: Survey) => {
    setDeleteModal({show: true, survey})
  }

  const handleView = (survey: Survey) => {
    setSelectedSurvey(survey)
  }

  const confirmActive = async () => {
    if (activeModal.survey) {
      await API.updateSurvey('active', activeModal.survey.id_survey)
      setActiveModal({show: false, survey: null})
      await loadSurveys()
    }
  }

  const confirmDelete = async () => {
    if (deleteModal.survey) {
      const response = await API.removeSurvey(deleteModal.survey.id_survey)
      await loadSurveys()
      setDeleteModal({show: false, survey: null})
    }
  }

  const loadSurveys = async () => {
    const response = await API.getSurveysList()
    setActiveSurvey(response.data.find((s: any) => s.status === 'active'))
    setInactiveSurveys(response.data.filter((s: any) => s.status === 'inactive'))
  }

  const onSurveyClose = () => {
    setSelectedSurvey(null)
  }

  useEffect(() => {
    loadSurveys()
  }, [])

  return (
    <div className='min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4'>
      <div className='max-w-6xl mx-auto'>
        <div className='flex justify-between items-center mb-8'>
          <div>
            <h1 className='text-3xl font-bold text-gray-900'>Pesquisas NPS</h1>
            <p className='text-gray-600 mt-2'>Gerencie suas pesquisas de satisfação</p>
          </div>
          <div className='flex gap-3'>
            <Link to={'/nps/create'}>
              <button
                onClick={onCreateNew}
                className='flex items-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg
                       hover:bg-blue-700 transition-colors shadow-sm'
              >
                <Plus className='w-5 h-5' />
                Nova Pesquisa
              </button>
            </Link>
          </div>
        </div>

        {/* Active Survey Section */}
        <div className='relative mb-12 bg-gradient-to-br from-green-50 to-white p-8 rounded-2xl border border-green-100'>
          <div className='flex items-center gap-3 mb-6'>
            <div className='w-3 h-3 bg-green-500 rounded-full animate-pulse'></div>
            <h2 className='text-xl font-semibold text-gray-900'>Pesquisa Ativa</h2>
          </div>

          {activeSurvey ? (
            <SurveyCard
              survey={activeSurvey}
              onEdit={onEdit}
              onDelete={handleDelete}
              onActivate={handleInactive}
              onView={handleView}
              className='border border-green-100'
            />
          ) : (
            <div className='bg-white/80 border-2 border-dashed border-green-200 rounded-xl p-8 text-center'>
              <AlertCircle className='w-12 h-12 text-green-500 mx-auto mb-4' />
              <p className='text-gray-600'>
                Nenhuma pesquisa ativa no momento. Ative uma pesquisa para começar a coletar
                respostas.
              </p>
            </div>
          )}
        </div>

        {/* Inactive Surveys Section */}
        <div className='mb-12 bg-gradient-to-br from-yellow-50 to-white p-8 rounded-2xl border border-yellow-100'>
          <div className='flex items-center justify-between mb-6'>
            <div className='flex items-center gap-3'>
              <h2 className='text-xl font-semibold text-gray-900'>Pesquisas Inativas</h2>
              <span className='px-3 py-1 rounded-full text-sm bg-yellow-100 text-yellow-700 border border-yellow-200'>
                {inactiveSurveys.length} pesquisas
              </span>
            </div>
          </div>

          <div className='grid gap-4'>
            {inactiveSurveys.map((survey) => (
              <SurveyCard
                key={survey.id_survey}
                survey={survey}
                onEdit={onEdit}
                onDelete={handleDelete}
                showActivateButton={true}
                onActivate={handleActivate}
                onView={handleView}
                className='border border-yellow-100'
              />
            ))}

            {inactiveSurveys.length === 0 && (
              <div className='bg-white/80 border-2 border-dashed border-yellow-200 rounded-xl p-8 text-center'>
                <p className='text-gray-500'>Nenhuma pesquisa inativa no momento.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        show={deleteModal.show}
        onHide={() => setDeleteModal({show: false, survey: null})}
        onConfirm={confirmDelete}
        surveyTitle={deleteModal.survey?.title || ''}
        responseCount={deleteModal.survey?.responses || 0}
      />
      <ActiveModal
        show={activeModal.show}
        onHide={() => setActiveModal({show: false, survey: null})}
        onConfirm={confirmActive}
        surveyTitle={activeModal.survey?.title || ''}
      />
      <Modal
        id='survey-modal'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
        show={selectedSurvey != null}
        onHide={onSurveyClose}
        style={{borderRadius: '15px'}}
      >
        <NPSSurvey id_survey={selectedSurvey?.id_survey} onClose={onSurveyClose}></NPSSurvey>
      </Modal>
    </div>
  )
}
