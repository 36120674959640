import React, {FC, useEffect, useState} from 'react'
import styled from '@emotion/styled'
import {KTSVG} from '../../../_metronic/helpers'
import * as API from '../../api'
import {isSuperAdmin} from '../../modules/auth/core/Auth'
import {AddMenuItemModal} from './../../../_metronic/partials/modals/add-menu-item/AddMenuItemModal'
import {Modal} from 'react-bootstrap'
import BtnTrash from '../../components/BtnTrash'
import BtnEdit from '../../components/BtnEdit'

type EditMenuItemModalProps = {
  show: boolean
  handleClose: () => void
  menuItem: any
  onSave: (item: MenuItem) => void
}

export const EditMenuItemModal: React.FC<EditMenuItemModalProps> = ({
  show,
  handleClose,
  menuItem,
  onSave,
}) => {
  const [itemName, setItemName] = useState('')

  const handleSubmit = () => {
    if (menuItem) {
      menuItem.name = itemName
      onSave(menuItem)
    }
  }

  useEffect(() => {
    if (menuItem) {
      setItemName(menuItem.name)
    } else {
      setItemName('')
    }
  }, [menuItem])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar item do menu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group'>
          <label>Título:</label>
          <input
            type='text'
            className='form-control'
            value={itemName}
            onChange={(e) => {
              setItemName(e.target.value)
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          Cancelar
        </button>
        <button type='button' className='btn btn-primary' onClick={handleSubmit}>
          Salvar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

type DeleteConfirmModalProps = {
  show: boolean
  handleClose: () => void
  handleDelete: () => void
}

export const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  show,
  handleClose,
  handleDelete,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Excluir item</Modal.Title>
      </Modal.Header>
      <Modal.Body>Você tem certeza que deseja deletar esse item?</Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          Cancelar
        </button>
        <button type='button' className='btn btn-danger' onClick={handleDelete}>
          Deletar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

type MenuItem = {
  guid: string
  name: string
  type: 'category' | 'product' | 'subproduct'
  isOpen?: boolean
  children?: MenuItem[]
}

const StyledForm = styled.div`
  .category {
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: context-menu;
  }

  .product {
    padding: 5px;
  }

  .subproduct {
    padding-top: 5px;
    margin-left: 40px;
  }

  .btn-add-subproduct {
    margin-top: 10px;
  }

  .nav-item {
    cursor: pointer;
  }

  svg {
    max-width: 20px;
    opacity: 0.5;
  }
`

const GeneralMenu: FC = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [editingItem, setEditingItem] = useState<MenuItem | null>(null)
  const [activeTab, setActiveTab] = useState('categorys')

  const [showEditMenuItemModal, setShowEditMenuItemModal] = useState(false)
  const [showAddMenuItemModal, setShowAddMenuItemModal] = useState(false)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const toggleItem = (item: MenuItem) => {
    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((menuitem) =>
        menuitem.guid === item.guid ? {...menuitem, isOpen: !menuitem.isOpen} : menuitem
      )
    )
  }

  const handleDeleteItem = async () => {
    console.log(
      editingItem?.type,
      editingItem?.type == 'category',
      editingItem?.type == 'product',
      editingItem?.type == 'subproduct'
    )
    if (editingItem?.type == 'category') {
      await API.removeCategory(editingItem.guid)
    } else if (editingItem?.type == 'product') {
      await API.removeProduct(editingItem.guid)
    } else if (editingItem?.type == 'subproduct') {
      await API.removeSubProduct(editingItem.guid)
    }
    setShowDeleteConfirmModal(false)
    loadData()
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    API.loadGeneralMenu().then((response) => {
      const menuItems: MenuItem[] = response.data.map((category: any) => ({
        guid: category.guid,
        name: category.admin_name ? category.admin_name : category.name,
        adminName: category.admin_name,
        type: 'category',
        isOpen: true,
        children: category.products.map((product: any) => ({
          guid: product.guid,
          name: product.name,
          type: 'product',
          children: product.subproducts.map((subproduct: any) => ({
            guid: subproduct.guid,
            name: subproduct.name,
            type: 'subproduct',
          })),
        })),
      }))
      setMenuItems(menuItems)
    })
  }

  const handleEditMenuItem = (item: MenuItem) => {
    setEditingItem(item)
    setShowEditMenuItemModal(true)
  }

  const handleSaveMenuItem = (item: MenuItem) => {
    if (item.type === 'category') {
      API.editCategory(item.guid, item.name)
    } else if (editingItem?.type === 'product') {
      API.editProduct(item.guid, item.name)
    } else if (editingItem?.type === 'subproduct') {
      API.editSubProduct(item.guid, item.name)
    }
    setShowEditMenuItemModal(false)
  }

  const onMenuChanged = () => {
    loadData()
  }

  const renderMenuItem = (item: any): JSX.Element => {
    switch (item.type) {
      case 'category':
        return (
          <div key={item.guid} className='category px-2'>
            <div
              className='d-flex align-items-center'
              onClick={(evt) => {
                if (
                  evt.target.toString() === '[object HTMLHeadingElement]' ||
                  evt.target.toString() == '[object HTMLDivElement]'
                )
                  toggleItem(item)
              }}
            >
              <h3 className='flex-grow-1 m-4'>
                {item.adminName ? `${item.adminName} (${item.name})` : item.name}
              </h3>
              <div className='d-flex justify-content-end flex-shrink-0 me-2'>
                <button
                  onClick={() => handleEditMenuItem(item)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                >
                  <BtnEdit />
                </button>
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                  onClick={() => {
                    setEditingItem(item)
                    setShowDeleteConfirmModal(true)
                  }}
                >
                  <BtnTrash />
                </button>
              </div>
            </div>
            {item.isOpen &&
              item.children?.map((child: any) => (
                <div key={child.guid}>{renderMenuItem(child)}</div>
              ))}
          </div>
        )
      case 'product':
        return (
          <div key={item.guid} className='product'>
            <div className='d-flex align-items-center'>
              <label className='form-control form-control-sm form-control-solid'>{item.name}</label>
              <div className='d-flex justify-content-end flex-shrink-0 ms-2'>
                <button
                  onClick={() => handleEditMenuItem(item)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <BtnEdit />
                </button>
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                  onClick={() => {
                    setEditingItem(item)
                    setShowDeleteConfirmModal(true)
                  }}
                >
                  <BtnTrash />
                </button>
              </div>
            </div>
            {item.children?.map((child: any) => (
              <div key={child.guid}>{renderMenuItem(child)}</div>
            ))}
          </div>
        )
      case 'subproduct':
        return (
          <div key={item.guid} className='subproduct'>
            <div className='d-flex align-items-center'>
              <label className='form-control form-control-sm form-control-solid'>{item.name}</label>
              <div className='d-flex justify-content-end flex-shrink-0 ms-2'>
                <button
                  onClick={() => handleEditMenuItem(item)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  <BtnEdit />
                </button>
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                  onClick={() => {
                    setEditingItem(item)
                    setShowDeleteConfirmModal(true)
                  }}
                >
                  <BtnTrash />
                </button>
              </div>
            </div>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <StyledForm>
      <EditMenuItemModal
        show={showEditMenuItemModal}
        handleClose={() => setShowEditMenuItemModal(!showEditMenuItemModal)}
        menuItem={editingItem}
        onSave={handleSaveMenuItem}
      />
      {!!isSuperAdmin() && (
        <>
          <AddMenuItemModal
            show={showAddMenuItemModal}
            handleClose={() => {
              setShowAddMenuItemModal(!showAddMenuItemModal)
            }}
            onMenuChanged={onMenuChanged}
          />
          <div className='btn-add-container'>
            <button
              id='kt_adm_add_category'
              type='button'
              className='btn btn-sm btn-secondary w-100'
              onClick={() => {
                setShowAddMenuItemModal(true)
              }}
            >
              {<span className='indicator-label'>Editar menu</span>}
            </button>
          </div>
        </>
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-body'>
          <div className='tab-content me-5'>
            <div
              className={`tab-pane fade show ${activeTab === 'categorys' ? 'active' : ''}`}
              id='categorys'
            >
              {menuItems.map((menuItem) => (
                <div key={menuItem.guid}>{renderMenuItem(menuItem)}</div>
              ))}
            </div>
          </div>
        </div>
        <DeleteConfirmModal
          show={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          handleDelete={handleDeleteItem}
        />
      </div>
    </StyledForm>
  )
}

export default GeneralMenu
