import React, {FC, useEffect, useRef, useState} from 'react'
import * as API from '../../api'

type Props = {
  onChange: Function
}

const SupportFilter: FC<Props> = (props) => {
  const [statusList, setStatusList] = useState<any[]>([])
  const [currentStatus, setCurrentStatus] = useState<any>('')
  const [subjectList, setSubjectList] = useState<any[]>([])
  const [currentSubject, setCurrentSubject] = useState<any>('')
  const [companyList, setCompanyList] = useState<any[]>([])
  const [currentCompany, setCurrentCompany] = useState<string>('')

  const loadStatusList = async () => {
    const response = await API.listSupportStatus()
    setStatusList(response.data)
  }

  const loadSubjectList = async () => {
    const response = await API.listSupportSubject()
    setSubjectList(response.data)
  }

  const loadCompanyList = async () => {
    const response: any = await API.listCompanys()
    response.data.sort((a: any, b: any) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    setCompanyList(response.data)
  }

  useEffect(() => {
    loadStatusList()
    loadSubjectList()
    loadCompanyList()
  }, [])

  useEffect(() => {
    props.onChange({status: currentStatus, subject: currentSubject, company: currentCompany})
  }, [currentSubject, currentStatus, currentCompany])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filtrar por:</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-4'>
          <label className='form-label fw-bold'>Status:</label>
          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Selecione um status'
              data-allow-clear='true'
              defaultValue={''}
              onChange={({target: {value}}) => {
                setCurrentStatus(value)
              }}
            >
              <option value=''>Todos</option>
              {statusList.map((status: any) => {
                return (
                  <option key={status.id_ticket_status} value={status.name}>
                    {status.status}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className='mb-4'>
          <label className='form-label fw-bold'>Assunto:</label>
          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Selecione um assunto'
              data-allow-clear='true'
              defaultValue={''}
              onChange={({target: {value}}) => {
                setCurrentSubject(value)
              }}
            >
              <option value=''>Todos</option>
              {subjectList.map((subject: any) => {
                return (
                  <option
                    key={subject.id_ticket_subject}
                    value={subject.description_ticket_subject}
                  >
                    {subject.description_ticket_subject}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className='mb-4'>
          <label className='form-label fw-bold'>Empresa:</label>
          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Selecione um assunto'
              data-allow-clear='true'
              defaultValue={''}
              onChange={({target: {value}}) => {
                setCurrentCompany(value)
              }}
            >
              <option value=''>Todas</option>
              {companyList.map((company: any) => {
                return (
                  <option key={company.guid} value={company.guid}>
                    {company.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportFilter
