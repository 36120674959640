import React from 'react'
import styled from '@emotion/styled'

const StyledButton = styled.div`
  &:hover {
    i,
    .svg-icon {
      opacity: 0.3;
    }
  }
`

interface Props {
  onClick?: Function
}

const BtnExitFullscreen: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton>
      <div onClick={() => props.onClick && props.onClick()}>
        <div className='btn btn-sm btn-icon btn-active-color-primary'>
          <span className='svg-icon svg-icon-1'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              viewBox='0 0 16 16'
            >
              <path d='M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z' />
            </svg>
          </span>
        </div>
      </div>
    </StyledButton>
  )
}

export default BtnExitFullscreen
