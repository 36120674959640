import { ColorType, Shade } from "../types/ColorType";

type TextProps = {
    color?: ColorType;
    shade?: Shade;
    size?: string;
    children: React.ReactNode;
}

export const Text = ({children, color, shade, size='12px'}:TextProps) => {
    const colorName = `text-${color ?? 'gray'}-${shade ?? '900'}`;
    return <p className={`${color ? colorName : ''}`} style={{fontSize: size}}>{children}</p>
}
