import React from 'react'
import styled from '@emotion/styled'

const StyledButton = styled.div``

interface Props {
  onClick?: Function
}

const IconPlus: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton>
      <div>
        <div className=''>
          <span className='svg-icon svg-icon-1'>
            <svg
              width='800px'
              height='800px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4 9.5C5.38071 9.5 6.5 10.6193 6.5 12C6.5 13.3807 5.38071 14.5 4 14.5C2.61929 14.5 1.5 13.3807 1.5 12C1.5 10.6193 2.61929 9.5 4 9.5Z'
                fill='#000000'
              />
              <path
                d='M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z'
                fill='#000000'
              />
              <path
                d='M22.5 12C22.5 10.6193 21.3807 9.5 20 9.5C18.6193 9.5 17.5 10.6193 17.5 12C17.5 13.3807 18.6193 14.5 20 14.5C21.3807 14.5 22.5 13.3807 22.5 12Z'
                fill='#000000'
              />
            </svg>
          </span>
        </div>
      </div>
    </StyledButton>
  )
}

export default IconPlus
