import styled from '@emotion/styled'
import Folder from '../../components/Folder'
import {PageTitle} from '../../../_metronic/layout/core'
import MultiSelect from '../../components/MultiSelect'
import DatePicker from 'react-datepicker'
import {useParams, useNavigate} from 'react-router-dom'

import 'react-datepicker/dist/react-datepicker.css'
import React, {FC, useEffect, useState} from 'react'
import * as API from '../../api'

const AlertCreationWrapper = styled.div`
  position: relative;

  .form-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }

  .inline-item {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    label,
    input {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  .form-input {
    position: relative;
    width: 100%;
  }

  .campaign-selection-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: space-between;
  }

  .selection-actions {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-grow: 0;
    width: 80px;
  }

  .campaign-list {
    position: relative;
    flex-grow: 1;
    width: 100%;
    border: 1px solid var(--kt-input-border-color);
    border-radius: 0.475rem;
  }

  .react-datepicker__input-container input {
    border: 1px solid var(--kt-input-border-color);
    border-radius: 0.475rem;
  }

  .alert-option {
    position: relative;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .folder-content-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .folder-column {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 290px;
  }

  .right-button {
    position: absolute;
    right: 0;
  }

  select {
    option {
      text-wrap: wrap;
    }

    option:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  .selection-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100px;
    flex-wrap: wrap;

    .form-check {
      margin-bottom: 10px;
      margin-right: 30px;

      label {
        display: flex;
        align-items: center;
      }

      input {
        margin-right: 5px;
      }
    }
  }

  .monitor-alert-box {
    gap: 25px;
  }
`

interface Props {
  data?: any
}

const AlertCreation: FC<Props> = (props: Props) => {
  const {alertId} = useParams()

  const [regions, setRegions] = useState<string[]>([])
  const [distributors, setDistributors] = useState<any[]>([])
  const [industries, setIndustries] = useState<any[]>([])
  const [selectedDistributors, setSelectedDistributors] = useState<any[]>([])
  const [selectedIndustries, setSelectedIndustries] = useState<any[]>([])
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])

  const [notifications, setNotifications] = useState<any[]>([
    {label: 'Insight Experience', value: 'notification', force: true},
    {label: 'E-mail', value: 'email'},
  ])
  const [selectedNotifications, setSelectedNotifications] = useState<any[]>([])

  const [loading, setLoading] = useState(false)
  const [initialDataReady, setInitialDataReady] = useState(false)
  const [loadedData, setLoadedData] = useState<any>({})

  const [promos, setPromos] = useState<any[]>([])
  const [selectedPromo, setSelectedPromo] = useState<any>()

  const [selectedSavedPromo, setSelectedSavedPromo] = useState<any>()
  const [savedPromos, setSavedPromos] = useState<any[]>([])

  const [name, setName] = useState<string>('')
  const [selectedAlert, setSelectedAlert] = useState<any>('promo')
  const [selloutActived, setSelloutActived] = useState<boolean>(false)
  const [verbaActived, setVerbaActived] = useState<boolean>(true)
  const [totalPedidosActived, setTotalPedidosActived] = useState<boolean>(false)
  const [totalLojasActived, setTotalLojasActived] = useState<boolean>(false)

  const [regiaoCentroNorte, setRegiaoCentroNorte] = useState<boolean>(alertId ? false : true)
  const [regiaoLeste, setRegiaoLeste] = useState<boolean>(alertId ? false : true)
  const [regiaoNordeste, setRegiaoNordeste] = useState<boolean>(alertId ? false : true)
  const [regiaoSaoPaulo, setRegiaoSaoPaulo] = useState<boolean>(alertId ? false : true)
  const [regiaoSul, setRegiaoSul] = useState<boolean>(alertId ? false : true)

  const [sellout, setSellout] = useState<number>(0)
  const [verba, setVerba] = useState<number>(0)
  const [pedidos, setPedidos] = useState<number>(0)
  const [lojas, setLojas] = useState<number>(0)

  const [filteredPromos, setFilteredPromos] = useState<any>([])

  const [searchTerm, setSearchTerm] = useState<string>('')

  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  )

  const REGIONS_ENABLED: boolean = false
  const DISTRIBUTORS_ENABLED: boolean = false

  const navigate = useNavigate()

  const getPromos = async () => {
    const response = await API.getMonitorPromos()
    const data = response.data.map((item: any) => {
      return {
        IDRegraPromocao: item[0], //IDRegraPromocao
        Nome_Promocao: item[1],
      }
    })
    setPromos(data)
    setFilteredPromos(data)
  }

  const getIndustries = async () => {
    const response = await API.getIndustries()
    const data = response.data.map((item: any) => {
      return {
        value: item[0],
        label: item[1],
      }
    })
    const sorted = data.sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
    setIndustries(sorted)
  }

  const handleCheck = (region: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRegions([...selectedRegions, region])
    } else {
      setSelectedRegions(selectedRegions.filter((item) => item !== region))
    }
  }

  const handleNotificationCheck = (notification: any, isChecked: boolean) => {
    if (isChecked) {
      setSelectedNotifications([...selectedNotifications, notification.value])
    } else {
      setSelectedNotifications(selectedNotifications.filter((item) => item !== notification.value))
    }
  }

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  const addPromoItem = () => {
    const length: any = promos?.length
    for (let a = 0; a < length; a++) {
      if (promos[a].IDRegraPromocao == selectedPromo) {
        setSavedPromos([...savedPromos, promos[a]])
        promos.splice(a, 1)
        setPromos(promos)
        setSelectedPromo(null)
        return
      }
    }
    if (length === 1) {
      setSavedPromos([...savedPromos, promos[0]])
      setPromos([])
    }
    setSelectedPromo(null)
  }

  const removePromoItem = () => {
    const length: any = savedPromos?.length
    for (let a = 0; a < length; a++) {
      if (savedPromos[a].IDRegraPromocao == selectedSavedPromo) {
        setPromos([...promos, savedPromos[a]])
        savedPromos.splice(a, 1)
        setSavedPromos(savedPromos)
        setSelectedSavedPromo(null)
        return
      }
    }
    if (length === 1) {
      setPromos([...promos, savedPromos[0]])
      setSavedPromos([])
    }
    setSelectedSavedPromo(null)
  }

  const getSelectedMetrics = () => {
    return [{type: 'verba', value: verba}, {sellout: 0}, {lojas: 0}, {pedidos: 0}]
    const data = []
    if (selloutActived) data.push({type: 'sellout', value: sellout})
    if (verbaActived) data.push({type: 'verba', value: verba})
    if (totalLojasActived) data.push({type: 'lojas', value: lojas})
    if (totalPedidosActived) data.push({type: 'pedidos', value: pedidos})
    return data
  }

  const getDistributorsData = () => {
    const data: any = []
    selectedDistributors.forEach((distributor: any) => {
      data.push({
        SKIndustria: distributor.SKIndustria,
        IDDistribuidor: distributor.IDDistribuidor,
      })
    })
    return data
  }

  useEffect(() => {
    if (!industries.length) return
    getPromos()
  }, [industries])

  const getIndustriesData = () => {
    const data: any = []
    selectedIndustries.forEach((industrie: any) => {
      data.push({
        id: industrie.value,
        label: industrie.label,
      })
    })
    return data
  }

  const getData = () => {
    return {
      promos: savedPromos,
      regions: selectedRegions,
      metrics: getSelectedMetrics(),
      distributors: getDistributorsData(),
      industries: getIndustriesData(),
      notifications: selectedNotifications,
      type: selectedAlert,
      start: startDate,
      end: endDate,
      name,
    }
  }

  const getSelectedAlert = async () => {
    if (!alertId) return
    try {
      const response = await API.getAlert(alertId)
      const data = JSON.parse(response.data.data)
      setLoadedData(data)
      setName(data.name)
      setStartDate(new Date(data.start))
      setEndDate(new Date(data.end))
      setSelectedAlert(data.type)
      if (data.industries) {
        setSelectedIndustries(
          data.industries.map((industry: any) => {
            return {
              value: industry.id,
              label: industry.label,
            }
          })
        )
      }
      setSavedPromos(data.promos)
      const promosTemp: any = []
      promos.forEach((p) => {
        let has = false
        data.promos.map((promo: any) => {
          if (p.IDRegraPromocao == promo.IDRegraPromocao) {
            has = true
          }
        })
        if (!has) promosTemp.push(p)
      })
      setPromos(promosTemp)

      data.metrics.map((metric: any) => {
        if (metric.type == 'sellout') {
          setSelloutActived(true)
          setSellout(metric.value)
        }
        if (metric.type == 'verba') {
          setVerbaActived(true)
          setVerba(metric.value)
        }
        if (metric.type == 'pedidos') {
          setTotalPedidosActived(true)
          setPedidos(metric.value)
        }
        if (metric.type == 'lojas') {
          setTotalLojasActived(true)
          setLojas(metric.value)
        }
      })
      setSelectedNotifications(data.notifications)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!loadedData || !loadedData.regions) return
    setSelectedRegions(loadedData.regions)
  }, [loadedData.regions])

  useEffect(() => {
    const result = promos.filter((promo) => {
      const skRegraPromocao = promo.IDRegraPromocao.toString()
      const nomePromocao = promo.Nome_Promocao.toLowerCase()
      return `${skRegraPromocao} ${nomePromocao}`.includes(searchTerm.toLowerCase())
    })
    setFilteredPromos(result)
  }, [searchTerm])

  useEffect(() => {
    if (!loadedData || !loadedData.distributors) return
    const data: any = []
    distributors.forEach((distributor) => {
      const result = loadedData.distributors.find(
        (i: any) =>
          i.IDDistribuidor == distributor.IDDistribuidor && i.SKIndustria == distributor.SKIndustria
      )
      if (result) data.push(distributor)
    })
    setSelectedDistributors(data)
  }, [loadedData.distributors])

  const generateAlert = async () => {
    const data = getData()
    setLoading(true)
    try {
      if (alertId) {
        trackEvent('Editar alerta')
        await API.editAlert(alertId, data)
      } else {
        trackEvent('Criar alerta')
        await API.addNewMonitorAlert(data)
      }
      setLoading(false)
      navigate('/alerts/list')
    } catch (e) {
      setLoading(false)
    }
  }

  const getRegions = async () => {
    const response = await API.getAlertRegions()
    const regions: any = []
    response.data.forEach((item: any) => {
      if (item.Regiao) regions.push(item.Regiao)
    })
    setRegions(regions)
  }

  const getDistributors = async () => {
    const response = await API.getAlertDistributors()
    const data: any = []
    response.data.forEach((item: any) => {
      item.value = `${item.SKIndustria}_${item.IDDistribuidor}`
      item.label = `${item.NomeIndustria} - ${item.NomeCurtoDistribuidor}`
      data.push(item)
    })
    setDistributors(data)
  }

  const checkDisabledButton = () => {
    const data = getData()
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (!data.name || !data.name.length) return true
    if (!data.metrics.length) return true
    if (!data.promos.length) return true
    if (!data.industries.length) return true
    // if (!data.notifications.length) return true;
    if (DISTRIBUTORS_ENABLED && !data.distributors.length) return true
    if (REGIONS_ENABLED && !data.regions.length) return true

    const endDateTemp = new Date(endDate.getTime())
    endDateTemp.setHours(0, 0, 0, 0)
    if (endDateTemp.getTime() < today.getTime()) return true
    return false
  }

  useEffect(() => {
    //getRegions();
    //getDistributors();
    getIndustries()
    //getPromos();
  }, [])

  useEffect(() => {
    if (!initialDataReady && promos.length) {
      getSelectedAlert()
      setInitialDataReady(true)
    }
  }, [promos])

  const trackEvent = (title: string): any => {
    window.gtag('event', 'click', {
      event_category: 'Criação de alerta',
      event_label: `${title}`,
    })
  }

  return (
    <AlertCreationWrapper>
      <PageTitle>Gerar novo alerta</PageTitle>
      <div className='card card-custom gutter-b p-10'>
        <div className='d-flex monitor-alert-box'>
          <div className='form-item'>
            <label htmlFor='' className='form-label'>
              Nome do alerta
            </label>
            <input
              type='text'
              defaultValue={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              className='form-input form-control'
            />
          </div>
        </div>
        <div className='d-flex monitor-alert-box'>
          <div className='form-item'>
            <label htmlFor='' className='form-label'>
              Buscar campanha pelo nome
            </label>
            <input
              className='form-input form-control'
              type='text'
              placeholder='Buscar campanhas...'
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
        </div>
        <div className='form-item'>
          <label htmlFor='' className='form-label'>
            Selecione quais campanhas deseja monitorar
          </label>
          <div className='campaign-selection-wrapper'>
            <select
              size={2}
              onChange={(e: any) => {
                setSelectedPromo(e.target.value)
              }}
              className='campaign-list'
            >
              {filteredPromos.map((promo: any) => (
                <option
                  key={'left_' + promo.IDRegraPromocao + '_' + promo.Nome_Promocao}
                  value={promo.IDRegraPromocao}
                >
                  {promo.IDRegraPromocao} {promo.Nome_Promocao}
                </option>
              ))}
            </select>
            <div className='selection-actions'>
              <a href='#' className='button' onClick={addPromoItem}>
                &gt;&gt;
              </a>
              <a href='#' className='button' onClick={removePromoItem}>
                &lt;&lt;
              </a>
            </div>
            <select
              size={2}
              onChange={(e: any) => {
                setSelectedSavedPromo(e.target.value)
              }}
              className='campaign-list'
            >
              {savedPromos?.map((promo: any) => (
                <option
                  key={'right_' + promo.IDRegraPromocao + '_' + promo.Nome_Promocao}
                  value={promo.IDRegraPromocao}
                >
                  {promo.IDRegraPromocao} {promo.Nome_Promocao}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="d-flex inline-item">
                    <label>Listar campanha por:</label> <label htmlFor="optDescription" className="form-label">Descrição</label><input name="optListBy" type="radio" id="optDescription" className="campaign-type form-check-input" /><label htmlFor="optMechanic" className="form-label">Mecânica</label><input name="optListBy" type="radio" id="optMechanic" className="campaign-type form-check-input" />
                </div> */}
        <div className='d-flex monitor-alert-box'>
          {/* <Folder isOpen={true} toggleEnabled={false} title="Tipo de alerta">
                        <div className="selection-container">
                            <div className="form-check form-check-custom form-check-solid">
                                <label className=""><input type="radio" name="alert-type" value='promo' checked={ selectedAlert === 'promo' } onChange={() => { setSelectedAlert('promo') }} className="form-check-input" />Promoções</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid">
                                <label className=""><input type="radio" name="alert-type" value='price' checked={ selectedAlert === 'price' } onChange={() => { setSelectedAlert('price') }} className="form-check-input" />Preço</label>
                            </div>
                        </div>
                    </Folder> */}
          <Folder isOpen={true} toggleEnabled={false} title='Métricas'>
            <div className='selection-container'>
              <div className='form-check form-check-custom form-check-solid'>
                <label>
                  <input
                    type='checkbox'
                    checked={verbaActived}
                    disabled
                    onChange={(e) => {
                      setVerbaActived(e.target.checked)
                    }}
                    className='form-check-input'
                  />
                  Verba consumida
                </label>
              </div>
              {/* 
                            <div className="form-check form-check-custom form-check-solid"><label><input type="checkbox" checked={selloutActived} onChange={(e) => {
                                setSelloutActived(e.target.checked);
                            }} className="form-check-input" />Sellout captado</label></div>
                            
                            <div className="form-check form-check-custom form-check-solid"><label><input type="checkbox" checked={totalPedidosActived}  onChange={(e) => {
                                setTotalPedidosActived(e.target.checked);
                            }} className="form-check-input" />Total de pedidos</label></div>
                            <div className="form-check form-check-custom form-check-solid"><label><input type="checkbox" checked={totalLojasActived}  onChange={(e) => {
                                setTotalLojasActived(e.target.checked);
                            }} className="form-check-input" />Total de lojas</label></div>
                        */}
            </div>
          </Folder>
          {REGIONS_ENABLED ? (
            <Folder isOpen={true} toggleEnabled={false} title='Regiões'>
              <div className='selection-container'>
                {regions.map((regiao) => (
                  <div className='form-check form-check-custom form-check-solid' key={regiao}>
                    <label>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        checked={selectedRegions.includes(regiao)}
                        onChange={(e: any) => handleCheck(regiao, e.target.checked)}
                      />
                      {regiao}
                    </label>
                  </div>
                ))}
              </div>
            </Folder>
          ) : null}
          {DISTRIBUTORS_ENABLED ? (
            <Folder isOpen={true} toggleEnabled={false} title='Distribuidores'>
              <div className='selection-container'>
                <MultiSelect
                  options={distributors}
                  isSelectAll={true}
                  isMulti
                  closeMenuOnSelect={false}
                  menuPlacement={'bottom'}
                  value={selectedDistributors}
                  onChange={(selected: []) => {
                    setSelectedDistributors(selected || [])
                  }}
                />
              </div>
            </Folder>
          ) : null}
          <Folder isOpen={true} toggleEnabled={false} title='Notificações'>
            <div className='selection-container'>
              {notifications.map((notification) => (
                <div
                  className='form-check form-check-custom form-check-solid'
                  key={notification.value}
                >
                  <label>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      disabled={notification.force}
                      checked={
                        selectedNotifications.includes(notification.value) || notification.force
                      }
                      onChange={(e: any) => handleNotificationCheck(notification, e.target.checked)}
                    />
                    {notification.label}
                  </label>
                </div>
              ))}
            </div>
          </Folder>
          <Folder isOpen={true} toggleEnabled={false} title='Indústrias'>
            <div className='folder-content-wrapper'>
              <div className='folder-column'>
                <div className='form-item'>
                  <MultiSelect
                    options={industries}
                    value={selectedIndustries}
                    isSelectAll={true}
                    menuPlacement={'bottom'}
                    onChange={(selected: []) => setSelectedIndustries(selected || [])}
                  />
                </div>
              </div>
            </div>
          </Folder>
        </div>
        {selloutActived || verbaActived || totalPedidosActived || totalLojasActived ? (
          <Folder isOpen={true} toggleEnabled={false} title='Alertar ao atingir determinado valor'>
            <div className='monitor-alert-box'>
              {/*{ selloutActived ?
                        <div className="folder-column mb-4">
                        <label htmlFor="" className="form-label">Sellout captado R$</label>
                        <div className="d-flex">
                            <span className="input-group-text">R$</span>
                            <input type="number" defaultValue={sellout} onChange={(e:any) => setSellout(e.target.value)} className="form-input form-control"/>
                        </div>
                    </div> : null } */}
              {verbaActived ? (
                <div className='folder-column mb-4'>
                  <label htmlFor='' className='form-label'>
                    Verba captada R$
                  </label>
                  <div className='d-flex'>
                    <span className='input-group-text'>R$</span>
                    <input
                      type='number'
                      value={verba}
                      onChange={(e: any) => setVerba(e.target.value)}
                      className='form-input form-control'
                    />
                  </div>
                </div>
              ) : null}
              {/*
                        { totalLojasActived ?
                        <div className="folder-column mb-4">
                            <label htmlFor="" className="form-label">Total de lojas</label>
                            <input type="number" defaultValue={lojas} onChange={(e:any) => setLojas(e.target.value)} className="form-input form-control" />
                        </div> : null }
                        { totalPedidosActived ?
                        <div className="folder-column mb-4">
                            <label htmlFor="" className="form-label">Total de pedidos</label>
                            <input type="number" defaultValue={pedidos} onChange={(e:any) => setPedidos(e.target.value)}  className="form-input form-control" />
                        </div> : null }
                        */}
              <div className='d-flex'>
                <div className='folder-column w-50 m-2'>
                  <label htmlFor='' className='form-label'>
                    Monitorar a partir da data
                  </label>
                  <DatePicker
                    dateFormat={'dd/MM/yyyy'}
                    selected={startDate}
                    onChange={(date: any) => {
                      setStartDate(date)
                    }}
                    className='form-input form-control'
                  />
                </div>
                <div className='folder-column w-50 m-2'>
                  <label htmlFor='' className='form-label'>
                    Monitorar até a data
                  </label>
                  <DatePicker
                    dateFormat={'dd/MM/yyyy'}
                    selected={endDate}
                    onChange={(date: any) => {
                      setEndDate(date)
                    }}
                    className='form-input form-control'
                  />
                </div>
              </div>
              {/*
                        <div className="folder-column">
                            <label htmlFor="" className="form-label">Data início</label>
                            <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => { setStartDate(date) }} className="form-input form-control" />
                        </div>
                        <div className="folder-column">
                            <label htmlFor="" className="form-label">Data fim</label>
                            <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={(date) => { setEndDate(date) }} className="form-input form-control" />
                        </div>
                        */}
            </div>
          </Folder>
        ) : null}

        {/* 
                <Folder isOpen={true} toggleEnabled={false} title="Método de envio">
                    <div className="folder-content-wrapper">
                        <div className="folder-column">
                            <div className="form-item">
                                <label htmlFor="" className="form-label">Modo de envio</label>
                                <MultiSelect
                                    options={[
                                        { value: 'notification', label: 'Notificação' },
                                        { value: 'email', label: 'E-mail' },
                                        { value: 'whatsapp', label: 'WhatsApp' },
                                        { value: 'sms', label: 'SMS' }
                                    ]}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                    onChange={ (selected:[]) => setSelectedSendMethods(selected || []) }
                                />
                            </div>
                            <div className="form-item">
                                <label htmlFor="" className="form-label">Usuários</label>
                                <MultiSelect
                                    options={[
                                        { value: 0, label: 'Antônio Albuquerque' },
                                        { value: 1, label: 'Bernardo Cunha' },
                                        { value: 2, label: 'Carla Antunes' }
                                    ]}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                    onChange={ (selected:[]) => setSelectedUsers(selected || []) }
                                />
                            </div>
                        </div>
                        <div className="folder-column">
                            <div className="form-item">
                                <label htmlFor="" className="form-label">Nome do alerta</label><input type="text" className="form-input form-control" />
                            </div>
                        </div>
                    </div>
                </Folder>
                */}

        <div className='form-item'>
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-50 mt-5'
              disabled={checkDisabledButton()}
              onClick={() => {
                generateAlert()
              }}
            >
              {!loading && (
                <span className='indicator-label'>
                  {alertId ? 'Editar alerta' : 'Gerar novo alerta'}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Aguarde por favor...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        {/* <div className="form-check form-check-custom form-check-solid">
                        <label><input type="checkbox" className="form-check-input" />* Aceitar termos e condições</label>
                    </div> */}
      </div>
    </AlertCreationWrapper>
  )
}

export default AlertCreation
